import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, Platform, Pressable } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import PALETTE from '../CFISP_utils/palette';
import { formatPriceInBRL } from '../CFISP_utils/functions';
import { Course } from '../content_types'

type CourseScreenNavigationProp = StackNavigationProp<RootStackParamList>;

interface Props {
    navigation: CourseScreenNavigationProp,
    course: Course
}

export default class BuyMidScreenButtonComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <View
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-around',
                paddingHorizontal: 50
            }}
        >
            <Pressable
                style={{
                    flexDirection: 'row',
                    height: 70,
                    ...Platform.select({
                        "web": {
                            cursor: "pointer"
                        }
                    })
                }}
                onPress={() => {
                    this.props.navigation.navigate("PaymentContract", { course: this.props.course });
                }}
            >
                <View style={{
                    flex: 3,
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    backgroundColor: PALETTE.gray_k,
                    borderBottomLeftRadius: 14,
                    borderTopLeftRadius: 14,
                }}>
                    <Text style={{
                        color: PALETTE.gray_e,
                        fontWeight: 'bold',
                        fontSize: 18
                    }}>{formatPriceInBRL(this.props.course.price)}</Text>
                </View>
                <View style={{
                    flex: 3,
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    backgroundColor: PALETTE.yellow,
                    borderBottomRightRadius: 14,
                    borderTopRightRadius: 14,
                }}>
                    <Text style={{
                        color: PALETTE.gray_e,
                        fontWeight: 'bold',
                        fontSize: 18
                    }}>MATRICULE-SE</Text>
                </View>
            </Pressable>
        </View>
    }
}
