import http from '../http';

type CourseProgress = {
    total: number,
    completed: number
}

class Course {
    id: number;
    code: string;
    title: string;
    description: string;
    image: string;
    video: string;
    pub_date: Date;
    price: Number;
    workload: number;
    owns: boolean;
    progress: CourseProgress;
    isFavorite: boolean;

    constructor(
        id: number,
        code: string,
        title: string,
        description: string,
        image: string,
        video: string,
        pub_date: Date,
        price: Number,
        workload: number,
        owns: boolean,
        progress: CourseProgress,
        isFavorite: boolean
    ) {
        this.id = id;
        this.code = code;
        this.title = title;
        this.description = description;
        this.image = image;
        this.video = video;
        this.pub_date = pub_date;
        this.price = price;
        this.workload = workload;
        this.owns = owns;
        this.progress = progress;
        this.isFavorite = isFavorite;
    }

    favorite(callback: any) {
        http.favoriteCourse(this, () => { this.isFavorite = true; callback() });
    }

    unFavorite(callback: any) {
        http.unFavoriteCourse(this, () => { this.isFavorite = false; callback() });
    }
}

export default Course;
