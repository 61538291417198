import React, { Component } from 'react';
import { StyleSheet, Image, View, Text, Dimensions } from 'react-native';
import { ExpandableList } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import ExpandableListItemComponent from './expandableListItemComponent';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';
import Markdown from "react-native-markdown-display";
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

const win = Dimensions.get('window');

type Props = {
    expandableList: ExpandableList
};

type State = {
    expandedItem: number;
    loading: boolean;
}

class ExpandableListComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { expandedItem: 0, loading: true };
        props.expandableList.consumeItem(0);
    }

    coverImageRatio: number = 1;
    coverImageWidth: number = 1;
    coverImageHeight: number = 1;

    componentDidMount() {
        Image.getSize(
            `${S3_IMAGES}${this.props.expandableList.illustration}`,
            (width, height) => {
                this.setCoverImageWidth(width);
                this.setCoverImageHeight(height);
                this.setCoverImageRatio(win.width/width);
                this.setState({ loading: false, expandedItem: 0 });
            },
            () => {}
        );
    }

    setCoverImageWidth(width: number) {
        this.coverImageWidth = width;
    }

    setCoverImageHeight(height: number) {
        this.coverImageHeight = height;
    }

    setCoverImageRatio(ratio: number) {
        this.coverImageRatio = ratio;
    }

    setExpandedItem(i: number) {
        if (this.state.expandedItem == i) {
            this.setState({ expandedItem: -1 });
        } else {
            this.props.expandableList.consumeItem(i);
            this.setState({ expandedItem: i });
        }
    }

    render() {
        let items = this.props.expandableList.expandables.map((item, index) => {
            return (
                <ExpandableListItemComponent
                    item={item}
                    startExpanded={index == 0}
                    list={this}
                    index={index}
                    key={index}
                ></ExpandableListItemComponent>);
        })

        return (
            <View style={styles.root}>
                { this.props.expandableList.title.length > 0 ?
                    <Text style={styles.title}>
                        { this.props.expandableList.title }
                    </Text>
                : null }
                { this.props.expandableList.text.length > 0 ?
                    <Markdown rules={MarkdownCustomRules} style={{ body: {
                        margin: 18,
                        fontSize: 18
                    }}}>
                        { this.props.expandableList.text }
                    </Markdown>
                : null }
                <View style={styles.ilustrationContainer}>
                    <Image
                        source={{uri: `${S3_IMAGES}${this.props.expandableList.illustration}`}}
                        style={{
                            flex: 1,
                            alignSelf: 'stretch',
                            width: win.width - ilustrationMargin,
                            marginVertical: 0,
                            height: ((this.coverImageHeight - ilustrationMargin*2) * this.coverImageRatio)
                        }}
                        resizeMode={'contain'}
                    ></Image>
                </View>
                {items}
            </View>
        )
    }
}

const ilustrationMargin = 1;

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.lightBlue
    },
    title: {
        fontSize: 22,
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 18,
        fontWeight: 'bold'
    },
    ilustrationContainer: {
        backgroundColor: PALETTE.lightGrey,
        paddingHorizontal: 0,
        marginVertical: 0
    },
    text: {
        margin: 18,
        fontSize: 18
    }
})

export default ExpandableListComponent;
