import React, { Component } from 'react';
import { Text, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import http from '../http';
import { OwnedCoursesList } from '../components';
import PALETTE from '../CFISP_utils/palette';
import stateMachine from '../StateMachine';
import { HeadsetWithCord } from '../illustrations'

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: ProfileScreenNavigationProp
}

interface State {
    isLoading: boolean
}

class OwnedCoursesScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { isLoading: true }

        stateMachine.setOwnedCoursesScreen(this);
    }

    _isMounted: boolean = false;

    componentDidMount() {
        this._isMounted = true;
        http.fetchOwnedCourses(() => {
            this.setState({ ...this.state, isLoading: false });
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (stateMachine.authenticated && stateMachine.ownedCourses?.length > 0) {
            return (
                <View style={{
                    backgroundColor: PALETTE.background,
                    flex: 1,
                }}>
                    { this.state.isLoading ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> :
                        <OwnedCoursesList navigation={this.props.navigation} />
                    }
                </View>
            );
        } else {
            return <View
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: 50,
                    backgroundColor: PALETTE.background
                }}
            >
                <HeadsetWithCord style={{
                    alignSelf: 'center',
                    width: 200,
                    height: 200
                }} />
                <Text style={{
                    textAlign: 'center',
                    fontSize: 24,
                    color: PALETTE.darkBlue
                }}>Comece agora!</Text>
                <Text style={{
                    textAlign: 'center',
                    fontSize: 18,
                    color: PALETTE.gray_c
                }}>Quando você começar sua pós na CFISP, seus cursos ficarão listados aqui.</Text>

                {/* Uncomment button bellow when search screen is back */}
                {/* <TouchableOpacity style={{
                    width: '70%',
                    height: 60,
                    alignSelf: 'center',
                    backgroundColor: PALETTE.gayBlue,
                    borderRadius: 100,
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                    onPress={() => {
                        this.props.navigation.navigate("Search");
                    }}
                    activeOpacity={0.7}
                >
                    <Text style={{
                        textAlign: 'center',
                        color: PALETTE.background,
                        alignSelf: 'center',
                        fontSize: 22
                    }}>Buscar curso</Text>
                </TouchableOpacity> */}
            </View>
        }
    }
};

export default OwnedCoursesScreen;
