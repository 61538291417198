import React, { Component } from 'react';
import {
    NativeModules,
    Dimensions,
    Image,
    LayoutAnimation,
    Text,
    TouchableOpacity,
    StyleSheet,
    View,
} from 'react-native';
import { S3_INFOGRAPHS, S3_IMAGES, S3_VIDEOS } from '../../CFISP_utils/environmentConstants';
import { Comparison } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { ComparisonItemStaticComponent, Show } from './comparisonItemStaticComponent';
import { ComparisonItemPaneComponent } from './comparisonItemPaneComponent';
import { NoIcon } from '../../icons'

const { UIManager } = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);

const win = Dimensions.get('window');

type Props = {
    comparison: Comparison
};

type State = {
    expandedItem: Show[];
    loading: boolean;
    computingHeights: boolean;
}

export default class ComparisonComponent extends Component<Props, State> {
    coverImageRatio: number;
    coverImageWidth: number;
    coverImageHeight: number;

    staticItemsHights: number[] = [];

    constructor(props: Props) {
        super(props)

        let ei: Show[] = [];
        for (let i=0; i<props.comparison.items.length; i++) {
            if (i==0) ei.push(Show.left);
            else ei.push(Show.none);
        }

        this.state = { expandedItem: ei, loading: true, computingHeights: true };

        this.coverImageRatio = 1;
        this.coverImageWidth = 1;
        this.coverImageHeight = 1;
    }

    addStaticItemHeight(height: number) {
        if (this.state.computingHeights) {
            let total = 0;

            for (let i=0; i<this.staticItemsHights.length; i++) {
                this.staticItemsHights[i] = this.staticItemsHights[i] + height;
            }

            this.staticItemsHights.push(height);
        }

        if (this.staticItemsHights.length == this.props.comparison.items.length) {
            this.setState({ ...this.state, computingHeights: false })
        }
    }

    componentDidMount() {
        Image.getSize(
            `${S3_IMAGES}${this.props.comparison.leftIllustration}`,
            (width, height) => {
                this.setCoverImageWidth(width);
                this.setCoverImageHeight(height);
                this.setCoverImageRatio(win.width/width);
                this.setState({ ...this.state, loading: false });
            },
            () => {}
        );
    }

    setCoverImageWidth(width: number) {
        this.coverImageWidth = width;
    }

    setCoverImageHeight(height: number) {
        this.coverImageHeight = height;
    }

    setCoverImageRatio(ratio: number) {
        this.coverImageRatio = ratio;
    }

    setExpandedItem(i: number, show: Show) {
        let newShow: Show[] = [];
        for (let i=0; i<this.props.comparison.items.length; i++) {
            newShow.push(Show.none);
        }

        newShow[i] = show;

        LayoutAnimation.easeInEaseOut();
        this.setState({ ...this.state, expandedItem: newShow });
    }

    renderPanes() {
        return this.props.comparison.items.map((item, index) => {
            return (
                <ComparisonItemPaneComponent
                    item={item}
                    show={this.state.expandedItem[index]}
                    parent={this}
                    index={index}
                    key={index}
                    top={this.staticItemsHights[index]}
                />
            );
        })
    }

    render() {
        this.props.comparison.consume()

        let itemsStatic = this.props.comparison.items.map((item, index) => {
            return (
                <ComparisonItemStaticComponent
                    item={item}
                    show={this.state.expandedItem[index]}
                    parent={this}
                    index={index}
                    key={index}
                />
            );
        })

        
        return (
            <View style={styles.root}>
                <View style={styles.heading}>
                    <View style={styles.headingLeft}>
                        <Text style={styles.title}>{this.props.comparison.leftTitle}</Text>
                    </View>
                    <View style={styles.headingRight}>
                        <Text style={styles.title}>{this.props.comparison.rightTitle}</Text>
                    </View>
                </View>

                <View style={styles.illustrations}>
                    <Image
                        source={{uri: `${S3_IMAGES}${this.props.comparison.leftIllustration}`}}
                        style={[styles.illustrationLeft, {
                            alignSelf: 'stretch',
                            width: win.width,
                            height: (this.coverImageHeight * this.coverImageRatio)}
                        ]}
                        resizeMode="contain"
                    ></Image>
                    <Image
                        source={{uri: `${S3_IMAGES}${this.props.comparison.rightIllustration}`}}
                        style={[styles.illustrationRight, {
                            alignSelf: 'stretch',
                            width: win.width,
                            height: (this.coverImageHeight * this.coverImageRatio)}
                        ]}
                        resizeMode="contain"
                    ></Image>
                </View>
                <View style={{ flex: 1, flexDirection: 'column' }}>
                    {itemsStatic}
                </View>
                
                { this.state.computingHeights
                    ? null
                    : <View style={{  }}>{this.renderPanes()}</View>
                }
            </View>
        )
    }
}

const ilustrationMargin = 20;

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.lightBlue
    },
    heading: {
        flex: 1,
        flexDirection: 'row'
    },
    headingLeft: {
        flex: 1,
        backgroundColor: PALETTE.yellow
    },
    headingRight: {
        flex: 1,
        backgroundColor: PALETTE.darkYellow_b
    },
    illustrations: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: PALETTE.lightBlue,
    },
    illustrationLeft: {
        flex: 1,
        marginHorizontal: 20
    },
    illustrationRight: {
        flex: 1,
        marginHorizontal: 20
    },
    title: {
        fontSize: 22,
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 18
    },
    ilustrationContainer: {
        backgroundColor: PALETTE.lightGrey,
        paddingHorizontal: ilustrationMargin
    },
    text: {
        margin: 18,
        fontSize: 18
    }
});
