import React, { Component } from 'react';
import stateMachine from '../StateMachine';
import http from '../http';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import { Text, View, ScrollView } from 'react-native';
import { EvaluationQuestion, EvaluationQuestionWrongAnswer } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import { CenterButton, EvaluationButton } from '../components';
import { EvaluationOptionState } from '../components/evaluationButton';
import Markdown from 'react-native-markdown-display';

import shuffle from '../CFISP_utils/shuffle';

type EvaluationScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type EvaluationScreenRouteProp = RouteProp<RootStackParamList, 'Evaluation'>;

type QuestionResult = {
    questionId: number,
    success: boolean
}

type Option = {
    id: number,
    text: string,
    correct: boolean
}

export interface Props {
    navigation: EvaluationScreenNavigationProp,
    route: EvaluationScreenRouteProp,
}

interface State {
    questionIndex: number,
    question: EvaluationQuestion,
    selectedOption: number,
    showNoSelectionWarning: boolean,
    showResult: boolean,
    results: QuestionResult[],
    score: number,
    options: Option[],
    endOfExam: boolean,
    firstRenderOfQuestion: boolean
}

export default class EvaluationScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Avaliação',
        headerLeft: undefined
    };

    constructor(props: Props) {
        super(props)

        if (!stateMachine.subject) return;

        this.questions = stateMachine.subject.evaluation;

        let questionIndex = 0;
        let score = 0;
        let results : QuestionResult[] = [];

        for (let i=0; i<stateMachine.subject.evaluation.length; i++) {
            if (stateMachine.subject.evaluation[i].answer == 0) {
                score++;
                results.push({
                    questionId: stateMachine.subject.evaluation[i].id,
                    success: true
                })
            }
        }

        for (let i=0; i<stateMachine.subject.evaluation.length; i++) {
            if (stateMachine.subject.evaluation[i].answer == -1) {
                questionIndex = i;
                break;
            }
        }
        
        this.state = {
            questionIndex: questionIndex,
            question: this.questions[questionIndex],
            selectedOption: -1,
            showNoSelectionWarning: false,
            showResult: false,
            results: results,
            score: score,
            options: [],
            endOfExam: false,
            firstRenderOfQuestion: true
        }
    }

    questions: EvaluationQuestion[] = [];

    componentDidMount() {
        this.props.navigation.setOptions({
            title: 'Avaliação',
            // headerLeft: undefined,
            // headerStyle: { backgroundColor: PALETTE.veryLightGreen, left: undefined }
        })
    }

    sliceQuestion(): [string[], boolean] {
        if (this.state.question.emphasis.length == 0) {
            return [[this.state.question.question], false]
        }

        // Tokenize the emphasis coordinates
        let emphasisTokens = this.state.question.emphasis.split('.').map(str => Number(str));
        
        // Starts with emphasis?
        let startWithEmphasis: boolean = true;
        if (emphasisTokens[0] > 0) startWithEmphasis = false;

        // Slice the question
        let slicedQuestion: string[] = [];
        let j = 0;
        for (let i=0; i<emphasisTokens.length; i++) {
            let nc = emphasisTokens[i] - j;

            if (nc > 0){
                slicedQuestion.push(this.state.question.question.substr(j, nc));
            }
            
            j = j + nc;
        }
        if (j < this.state.question.question.length) slicedQuestion.push(this.state.question.question.substr(j));
        
        // Done!
        return [slicedQuestion, startWithEmphasis];
    }

    renderQuestion(): JSX.Element[] {
        let question = this.sliceQuestion();

        return question[0].map((item, index) => {
            let element = <Markdown key={index} style={ question[1] ? { body: {color: 'blue'} } : { body: {color: 'black'} } }>{item}</Markdown>;
            question[1] = !question[1];

            return element;
        });
    }

    buildOptions() {
        let options: Option[] = [];

        for (let i=0; i<this.state.question.wrongAnswers.length; i++) {
            options.push({
                id: this.state.question.wrongAnswers[i].id,
                text: this.state.question.wrongAnswers[i].option,
                correct: false
            });
        }

        options.push({
            id: 0,
            text: this.state.question.correctAnswer,
            correct: true
        });

        shuffle(options);

        this.setState({
            ...this.state,
            options: options,
            firstRenderOfQuestion: false
        })
    }

    renderOptions(): JSX.Element[] {
        if (this.state.firstRenderOfQuestion) this.buildOptions();

        return this.state.options.map((item, index) => {
            return <EvaluationButton
                key={index}
                text={item.text}
                action={ this.state.showResult
                    ? () => {}
                    : () => this.setState({ ...this.state, selectedOption: index, showNoSelectionWarning: false })
                }
                optionButtonState={
                    this.state.showResult
                    ? index == this.state.selectedOption
                        ? item.correct
                            ? EvaluationOptionState.correct
                            : EvaluationOptionState.wrong
                        : EvaluationOptionState.normal
                    : index == this.state.selectedOption ? EvaluationOptionState.selected : EvaluationOptionState.normal
                }
            />
        })
    }

    next() {
        if (this.state.questionIndex + 1 == this.questions.length) {
            http.endEvaluation(
                this.props.route.params.subject.evaluationAttempts[this.props.route.params.subject.evaluationAttempts.length - 1].id,
                this.props.route.params.subject.evaluationAttempts.length,
                (res) => {
                    stateMachine.updateEvaluation(res.endTimestamp, res.grade, () => {
                        this.setState({ ...this.state, endOfExam: true });
                    })
                }
            );

            return;
        };

        let n = this.state.questionIndex + 1;

        this.setState({
            ...this.state,
            questionIndex: n,
            question: this.questions[n],
            selectedOption: -1,
            showResult: false,
            firstRenderOfQuestion: true
        })
    }

    send() {
        http.saveEvaluationQuestionResult(
            this.props.route.params.subject.evaluationAttempts.length,
            this.state.question.id,
            this.state.options[this.state.selectedOption].id,
            this.state.options[this.state.selectedOption].correct,
            (res) => {
                if (this.state.options[this.state.selectedOption].correct) {
                    stateMachine.answerEvaluationQuestion(this.state.question.id, 0)
                } else {
                    stateMachine.answerEvaluationQuestion(this.state.question.id, 1)
                }

                let score = this.state.score;
                let results = this.state.results;
        
                results.push({
                    questionId: this.state.question.id,
                    success: this.state.options[this.state.selectedOption].correct
                });
        
                if (this.state.options[this.state.selectedOption].correct) {
                    score = score + 1;
                }
        
                this.setState({ ...this.state, score, results, showResult: true })
            }
        )
    }

    render() {
        if (this.questions.length == 0) return <View style={{ padding: 20 }}><Text>ERROR: empty evaluation</Text></View>;

        if (this.state.endOfExam) return <View
            style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: 40 }}
        >
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }} >
                <Text style={{ textAlign: "center", fontSize: 20, alignSelf: 'center' }}>Acertos: {this.state.score}/{this.questions.length}</Text>
            </View>
            { this.state.score >= 7 ? <View><View style={{ }} >
                <Text style={{ textAlign: "center", color: PALETTE.gayBlue, fontSize: 25 }} >PARABÉNS!</Text>
            </View>
            <View style={{ marginTop: 20 }} >
                <Text style={{ textAlign: "center", fontSize: 20 }} >Você concluiu a disciplina</Text>
            </View>
            <View style={{ height: 60, marginTop: 20 }} >
                <CenterButton
                    // text="Próxima disciplina"
                    text="Início"
                    color={PALETTE.gayBlue}
                    action={() => {
                        // TODO: switch when all subjects on course are built
                        // stateMachine.goToNextSubject()
                        this.props.navigation.navigate("Home")
                    }}
                />
            </View></View> : <View><View style={{ }} >
                <Text style={{ textAlign: "center", color: PALETTE.gayBlue, fontSize: 25 }} >Fim da avaliação</Text>
            </View>
            <View style={{ marginTop: 20 }} >
                <Text style={{ textAlign: "center", fontSize: 20 }} >Seu resultado foi insatisfatório. Revise o conteúdo e tente novamente.</Text>
            </View>
            <View style={{ height: 60, marginTop: 20 }} >
                <CenterButton
                    text="Ok"
                    color={PALETTE.gayBlue}
                    action={() => {
                        stateMachine.goToThisSubjectList()
                    }}
                />
            </View></View> }
            <View style={{ marginTop: 20 }} >
                <Text style={{ textAlign: "center" }} >Ver <Text
                    style={{ color: PALETTE.gayBlue }}
                    onPress={() => {
                        stateMachine.goToThisSubjectList()
                    }}
                >lista de lições</Text></Text>
            </View>
        </View>

        return <ScrollView style={{ padding: 30, backgroundColor: PALETTE.background }}>
            <View style={{
                display: "flex",
                flexDirection: 'row',
                alignItems: 'flex-start'
            }}>
                <Text style={{ fontWeight: 'bold', marginRight: 6, marginTop: 9 }}>{ this.state.questionIndex + 1 }.</Text>
                <Text >{ this.renderQuestion() }</Text>
            </View>

            <View>
                { this.renderOptions() }
            </View>

            {
                this.state.showNoSelectionWarning
                ? <View>
                    <Text style={{ textAlign: 'center', color: PALETTE.red, marginTop: 10 }}>
                        Selecione uma das alternativas
                    </Text>
                </View>
                : null
            }

            {
                this.state.showResult
                    ? <View style={{ marginVertical: 40 }}>
                        <CenterButton
                            text="Avançar"
                            color={PALETTE.anotherGreen}
                            action={ () => {
                                this.next()
                            }}
                        />
                    </View>
                    : <View style={{ marginVertical: 40 }}>
                        <CenterButton
                            text="Enviar"
                            color={this.state.selectedOption == -1 ? PALETTE.lightGayBlue : PALETTE.gayBlue }
                            action={ this.state.selectedOption == -1
                                ? () => {
                                    this.setState({ ...this.state, showNoSelectionWarning: true })
                                }
                                : () => this.send() }
                        />
                    </View>
            }

            {/* User isn't allowed to move away from the evaluation */}
            {/* <View>
                <Text style={{ textAlign: 'center' }}>
                    Voltar para
                    <Text
                        style={ {color: PALETTE.gayBlue} }
                        onPress={ () => {
                            this.props.navigation.navigate("Summary", { subject: this.props.route.params.subject });
                        } }
                    > revisão</Text>
                </Text>
            </View> */}
        </ScrollView>
    }
}
