import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, TextInput, Pressable, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import InputMask from 'react-input-mask';
import { CenterButton } from '../../components'
import { isValidCPF, multiplatformAlert } from '../../CFISP_utils/functions';
import paymentAPIAdapter from '../../http/payment'
import { PaymentCard } from '../../payment_types';
import stateMachine from '../../StateMachine';

interface Props {
    parent: any
}

interface State {
    cardholderName: string,
    cardNumber: string,
    formatedCardNumber: string,
    cvv: string,
    exp: string,
    document_id: string,
    formated_document_id: string,
    zipCode: string,
    formatedZipCode: string,
    country: string,
    state: string,
    city: string,
    neighborhood: string,
    address: string,
    habitation_number: number,
    habitation_complement: string,
    complete: boolean,
    waiting: boolean
}

export default class AddCardFormComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cardholderName: "",
            cardNumber: "",
            formatedCardNumber: "",
            cvv: "",
            exp: "",
            document_id: "",
            formated_document_id: "",
            zipCode: "",
            formatedZipCode: "",
            country: "",
            state: "",
            city: "",
            neighborhood: "",
            address: "",
            habitation_number: -1,
            habitation_complement: "",
            complete: false,
            waiting: false
        }
    }

    cardNumberRef: any;
    cvcRef: any;
    documentIdRef: any;
    zipCodeRef: any;
    expRef: any;

    setExpirationDate(event, selectedDate) {
        this.setState({ ...this.state, exp: selectedDate }, this.validateData);
    }

    setComplete(complete: boolean) {
        this.setState({ ...this.state, complete });
    }

    validateData() {
        if (this.state.cardNumber.length < 16) {
            this.setComplete(false);
            return;
        }

        if (this.state.cardholderName.length < 3) {
            this.setComplete(false);
            return;
        }
        
        if (this.state.cvv.length < 3) {
            this.setComplete(false)
            return;
        };

        if (!isValidCPF(this.state.document_id)) {
            this.setComplete(false);
            return;
        }

        if (this.state.zipCode.length < 7) {
            this.setComplete(false);
            return;
        }

        if (this.state.country.length < 2) {
            this.setComplete(false);
            return;
        }

        if (this.state.state.length < 2) {
            this.setComplete(false);
            return;
        }

        if (this.state.city.length < 2) {
            this.setComplete(false);
            return;
        }

        if (this.state.neighborhood.length < 2) {
            this.setComplete(false);
            return;
        }

        if (this.state.address.length < 2) {
            this.setComplete(false);
            return;
        }

        if (this.state.habitation_number === -1) {
            this.setComplete(false);
            return;
        }

        this.setState({ ...this.state, complete: true });
    }

    saveCard() {
        let card: PaymentCard = new PaymentCard(
            this.state.cardholderName,
            "",
            stateMachine.getUserId(),
            this.state.cardNumber.replaceAll(" ", ""),
            this.state.cvv,
            new Date(Number(this.state.exp.substring(3)), Number(this.state.exp.substring(0, 2))-1, 28),
            this.state.document_id,
            this.state.zipCode.replaceAll("-", ""),
            this.state.country,
            this.state.state,
            this.state.city,
            this.state.neighborhood,
            this.state.address,
            this.state.habitation_number,
            this.state.habitation_complement
        )

        this.setState({ ...this.state, waiting: true });
        paymentAPIAdapter.postCard(card, (result: any) => {
            if (result.status === "OK") {
                card.card_token = result.token;
                card.last_numbers = `${card.card_number.slice(12)}`;
                this.props.parent.addCard(card);
                this.props.parent.closeAddCardForm();
            } else {
                // TODO: Implement field by field validation, with proper
                //       error messages, to give better information to the
                //       user about what went wrong. That involves the
                //       interface between main server API and the
                //       payment API.
                multiplatformAlert("Não foi possível salvar este cartão. Por favor, verifique os dados e tente novamente.");
            }
        });
    }

    render() {
        return <View style={{
            backgroundColor: PALETTE.gray_b,
            padding: 10,
            marginBottom: 40,
            marginTop: 20
        }}>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Acrescentar Cartão</Text>
            <Pressable
                onPress={() => { this.props.parent.closeAddCardForm() }}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                }}
            >
                <Text style={{
                    ...Platform.select({
                        web: {
                            cursor: "pointer"
                        }
                    }),
                    fontSize: 20,
                    color: PALETTE.red,
                    alignSelf: 'flex-end'
                }}>Cancelar</Text>
            </Pressable>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Número do cartão</Text>
                <InputMask
                    // style={styles.cardInput} // Breaks the component. TODO: Find out how to add styles to this component.
                    inputRef={ref => { this.cardNumberRef = ref }}
                    onChange={(event) => {
                        this.setState({ ...this.state, cardNumber: event.target.value }, this.validateData)
                    }}
                    mask={"9999 9999 9999 9999"}
                    maskChar=""
                    value={ this.state.cardNumber }
                    placeholder="xxxx xxxx xxxx xxxx"
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>CVC</Text>
                <InputMask
                    // style={styles.cardInput}
                    inputRef={ref => { this.cvcRef = ref }}
                    onChange={(event) => {
                        this.setState({ ...this.state, cvv: event.target.value }, this.validateData)
                    }}
                    mask={"999"}
                    maskChar=""
                    value={ this.state.cvv }
                    placeholder="xxx"
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Validade</Text>
                <InputMask
                    // style={styles.cardInput}
                    inputRef={ref => { this.expRef = ref }}
                    onChange={(event) => {
                        this.setState({ ...this.state, exp: event.target.value }, this.validateData)
                    }}
                    mask={"99/9999"}
                    maskChar=""
                    value={ this.state.exp }
                    placeholder="00/0000"
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Titular do cartão</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(cardholderName) => {
                        this.setState({ ...this.state, cardholderName }, this.validateData)
                    }}
                    value={ this.state.cardholderName }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>CPF</Text>
                <InputMask
                    // style={styles.cardInput}
                    inputRef={ref => { this.documentIdRef = ref }}
                    onChange={(event) => {
                        this.setState({ ...this.state, document_id: event.target.value }, this.validateData)
                    }}
                    mask={"999.999.999-99"}
                    maskChar=""
                    value={ this.state.document_id }
                    placeholder="xxx.xxx.xxx-xx"
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>CEP</Text>
                <InputMask
                    // style={styles.cardInput}
                    inputRef={ref => { this.zipCodeRef = ref }}
                    onChange={(event) => {
                        this.setState({ ...this.state, zipCode: event.target.value }, this.validateData)
                    }}
                    mask={"99999-999"}
                    maskChar=""
                    value={ this.state.zipCode }
                    placeholder="xxxxx-xx"
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>País</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(country) => {
                        country = country.toLowerCase();
                        this.setState({ ...this.state, country }, this.validateData)
                    }}
                    value={ this.state.country }
                    placeholder="--"
                    maxLength={2}
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Estado</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(state) => {
                        this.setState({ ...this.state, state }, this.validateData)
                    }}
                    value={ this.state.state }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Cidade</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(city) => {
                        this.setState({ ...this.state, city }, this.validateData)
                    }}
                    value={ this.state.city }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Bairro</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(neighborhood) => {
                        this.setState({ ...this.state, neighborhood }, this.validateData)
                    }}
                    value={ this.state.neighborhood }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Logradouro</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(address) => {
                        this.setState({ ...this.state, address }, this.validateData)
                    }}
                    value={ this.state.address }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Número</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(habitation_number) => {
                        this.setState({ ...this.state, habitation_number: Number(habitation_number) }, this.validateData)
                    }}
                    value={ this.state.habitation_number === -1 ? "" : `${this.state.habitation_number}` }
                    placeholder="..."
                />
            </View>
            <View style={styles.cardFieldContainer}>
                <Text style={styles.cardFieldLabel}>Complemento</Text>
                <TextInput
                    style={styles.cardInput}
                    onChangeText={(habitation_complement) => {
                        this.setState({ ...this.state, habitation_complement }, this.validateData)
                    }}
                    value={ this.state.habitation_complement }
                    placeholder="..."
                />
            </View>
            {
                this.state.waiting ? <ActivityIndicator animating={true} size="large" color={PALETTE.blue} /> :
                <CenterButton
                    color={this.state.complete ? PALETTE.blue : PALETTE.lightGayBlue}
                    text="Salvar"
                    action={this.state.complete ? () => { this.saveCard() } : () => {}}
                    marginTop={ 20 }
                />
            }
        </View>
    }
}

const styles = StyleSheet.create({
    cardFieldContainer: {
        marginTop: 15,
        padding: 0
    },
    cardInput: {
        margin: 0,
        padding: 0
    },
    cardFieldLabel: {
        margin: 0,
        padding: 0
    }
})
