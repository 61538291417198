import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 48 48"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Circle id="prefix__circle835" r={24} cy={24} cx={24} fill="#007aff" />
      <Path
        id="prefix__path883"
        d="M27.5 14.988a.81.81 0 00-.81.813.81.81 0 00.81.81h1.988V18a.81.81 0 00.813.81.81.81 0 00.81-.81v-2.2a.81.81 0 00-.81-.812H27.5z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#fff"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.622}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path875"
        d="M14.2 27.79a.81.81 0 00-.811.81v2.2a.81.81 0 00.81.811H17a.81.81 0 00.81-.81.81.81 0 00-.81-.813h-1.988V28.6a.81.81 0 00-.813-.81z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#fff"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.622}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path887"
        d="M20.2 21.889a.81.81 0 00-.454.142.81.81 0 00-.023.006c-.699.254-1.232.72-1.51 1.276-.278.555-.324 1.133-.324 1.687a.81.81 0 00.002.03c.099 2.68.099 5.36 0 7.939a.81.81 0 00-.002.031c0 .945.163 1.775.75 2.361.586.587 1.416.75 2.361.75a.81.81 0 00.023 0c3.485-.1 6.969-.1 10.454 0a.81.81 0 00.023 0c.945 0 1.775-.163 2.361-.75.587-.586.75-1.416.75-2.361a.81.81 0 00-.002-.03c-.099-2.68-.099-5.36 0-7.939a.81.81 0 00.002-.031c0-.945-.163-1.775-.75-2.361-.586-.587-1.416-.75-2.361-.75a.81.81 0 00-.021 0c-3.772.099-7.466 0-11.28 0zm11.321 1.62l-.021.003c.755 0 1.076.135 1.215.273.138.139.273.46.273 1.215l.002-.031c-.1 2.621-.1 5.34 0 8.06L32.988 33c0 .755-.135 1.076-.273 1.215-.139.138-.46.273-1.215.273l.023.002c-3.515-.1-7.031-.1-10.546 0l.023-.002c-.755 0-1.076-.135-1.215-.273-.138-.139-.273-.46-.273-1.215l-.002.031c.1-2.621.1-5.34 0-8.06l.002.029c0-.446.053-.768.15-.963.097-.194.214-.328.615-.474a.81.81 0 00.112-.051c3.72.003 7.368.097 11.132-.002z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#363c55"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.622}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path879"
        d="M14.3 14.988a.81.81 0 00-.812.813V18.6a.81.81 0 00.813.81.81.81 0 00.81-.81V16.61H16.5a.81.81 0 00.81-.81.81.81 0 00-.81-.813h-2.2z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#fff"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.622}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
