import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, TextInput, Pressable, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import MonthPicker from 'react-native-month-year-picker';
import TextInputMask from 'react-native-text-input-mask';
import { CenterButton } from '../../components'
import { isValidCPF, formatPriceInBRL } from '../../CFISP_utils/functions';
import paymentAPIAdapter from '../../http/payment'
import { PaymentCard } from '../../payment_types';
import stateMachine from '../../StateMachine';
import { PaymentInstalmentPlan } from '../../components/paymentComponents';

interface Props {
    plans: number[],
    price: Number,
    parent: any
}

interface State {
    waiting: boolean,
    selectedPlan: number
}

export default class PaymentInstalmentPlans extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            waiting: false,
            selectedPlan: 0
        }
    }

    renderItem() {
        return this.props.plans.map((item, index) => {
            return <Pressable key={index} style={ index === this.state.selectedPlan ? [styles.item, styles.active] : [styles.item, styles.normal]} onPress={() => {
                this.setState({ ...this.state, selectedPlan: index })
            }}>
                <PaymentInstalmentPlan selected={index === this.state.selectedPlan} instalments={item} price={this.props.price} />
            </Pressable>
        })
    }

    render() {
        if (this.state.waiting) return <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        return <View style={{
            marginTop: 30
        }}>
            { this.renderItem() }
            <CenterButton text="Continuar" color={PALETTE.blue} action={() => {
                this.props.parent.next(this.props.plans[this.state.selectedPlan]);
            }} />
        </View>
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    }
});
