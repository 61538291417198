import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <Path
        d="M18.5 9H6.3l5.6-5.6L10.5 2l-8 8 8 8 1.4-1.4L6.3 11h12.2V9z"
        fill="#0f0f0f"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
