import React, { Component } from 'react';
import { StyleSheet, ActivityIndicator, View, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import { User } from '../user';
import ProfileDataDisplayItem from './ProfileDataDisplayItem';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';

import { Picker } from '@react-native-picker/picker';

interface Props {
    courses: Course[]
    course: Course
    parent: any
}

interface State {
    course: Course,
}

export default class IntendedCoursePicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { course: this.props.course }
    }

    setCourse(index: any) {
        let c: Course = this.props.courses[0];

        for (let i=0; i< this.props.courses.length; i++) {
            if (this.props.courses[i].id === Number(index)) {
                c = this.props.courses[i];
                break;
            }
        }

        this.setState({ ...this.state, course: c })
        this.props.parent.updateIntendedCourse(c);
    }

    renderCourses() {
        return this.props.courses.map((c, index) => {
            return <Picker.Item key={index} label={`${c.code} | ${c.title}`} value={c.id} />
        })
    }

    render() {
        return  (
            <View>
                <Picker
                    selectedValue={this.state.course.id}
                    style={{height: 50, width: 100}}
                    onValueChange={(itemValue, itemIndex) => {
                        this.setCourse(itemValue);
                    }}
                    >
                    { this.renderCourses() }
                </Picker>
            </View>
        )
    }
}
