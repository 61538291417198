import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, Pressable, TouchableOpacity, Image } from 'react-native';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import { ProgressBar } from '../components';
import { S3_IMAGES } from '../CFISP_utils/environmentConstants';

type Props = {
    navigation: any,
    course: Course
};

interface State {
    loadingImage: boolean
}

class OwnedCourseListItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { loadingImage: true }
    }

    coverImageWidth: number = 1;
    coverImageHeight: number = 1;

    setCoverImageWidth(width: number) {
        this.coverImageWidth = width;
    }

    setCoverImageHeight(height: number) {
        this.coverImageHeight = height;
    }

    componentDidMount() {
        if (this.props.course.image.length == 0) return;

        Image.getSize(
            this.props.course.image,
            (width, height) => {
                this.setCoverImageWidth(width);
                this.setCoverImageHeight(height);
                this.setState({ loadingImage: false });
            },
            () => {}
        );
    }

    openCourse(courseIndex: number) {
        this.props.navigation.navigate('Course', {course: stateMachine.getOwnedCourse(courseIndex)});
    }

    render () {
        let progressIndex: number = 0;
        if (this.props.course.progress.total == 0) progressIndex = 0;
        else this.props.course.progress.completed / this.props.course.progress.total;

        return (
            <TouchableOpacity
                onPress={ () => this.openCourse(this.props.course.id) }
                style={layout.root}
                activeOpacity={0.6}
            >
                <View style={layout.lft}>
                    {
                        this.props.course.image.length == 0
                            ? <View style={styles.noImage}>
                            </View>
                            : <View style={styles.image}>
                                <Image
                                    source={{ uri: `${S3_IMAGES}course-covers/${this.props.course.image}` }}
                                    style={{ height: 110, width: 110 }}
                                    resizeMethod='auto'
                                    resizeMode='cover'
                                />
                            </View>
                    }
                </View>

                <View style={layout.rgt}>
                    
                    <View style={layout.top}>
                        <View style={styles.title}>
                            <Text style={styles.titleText}>{this.props.course.title}</Text>
                            {/* <Text style={styles.titleDescription}>{this.props.course.description}</Text> */}
                        </View>
                    </View>

                    <View style={layout.btm}>
                        <View style={layout.bl}>
                            <Text style={styles.progressText}>{this.props.course.progress.completed} de {this.props.course.progress.total} Disciplinas</Text>
                        </View>
                        <View style={layout.br}>
                            <ProgressBar
                                progress={progressIndex}
                                height={10}
                                bgc={PALETTE.lightGrey}
                                fillColor={PALETTE.yellow}
                                emptyColor={PALETTE.almostWhite}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }
}

const layout = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.lightGrey,
        height: 150,
        marginBottom: 20,
        flexDirection: 'row',
        padding: 20
    },
    lft: {
        flex: 1,
    },
    rgt: {
        flex: 2,
    },
    top: {
        flex: 3,
    },
    btm: {
        flex: 1,
        flexDirection: 'row',
    },
    bl: {
        flex: 2,
        justifyContent: 'flex-end',
    },
    br: {
        flex: 1,
        justifyContent: 'flex-end',
    },
})

const styles = StyleSheet.create({
    root: {
        marginBottom: 20,
        padding: 10,
    },
    image: {},
    noImage: {
        backgroundColor: PALETTE.gayBlue,
        width: 110,
        height: 110
    },
    
    title: {},
    titleText: {
        fontWeight: 'bold',
        fontSize: 16
    },
    description: {},
    titleDescription: {},
    progressText: {
        color: PALETTE.gray_l
    }
})

export default OwnedCourseListItem;
