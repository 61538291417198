enum MaritalStatus {
    SINGLE,
    MARRIED,
    DIVORCED,
    WIDOW,
    SEPARATED
}

class User {
    constructor(email, userID) {
        this.email = email;
        this.id = userID;
    }

    address: string = "";
    cityOfBirth: string = "";
    dateOfBirth: Date | null = null;
    email: string;
    firstName: string = "";
    fullName: string = "";
    graduation: string = "";
    graduationCity: string = "";
    graduationInstitution: string = "";
    graduationYear: string = "";
    idCPF: string = "";
    idRG: string = "";
    intendedCourseID: number | null = null;
    lastName: string = "";
    maritalStatus: MaritalStatus = 0;
    phone: string = "";
    postalCode: string = "";
    id: number;

    canEnlist() : boolean {
        if (this.address.length === 0) return false;
        if (this.cityOfBirth.length === 0) return false;
        if (!this.dateOfBirth) return false;
        if (this.firstName.length === 0) return false;
        if (this.fullName.length === 0) return false;
        if (this.graduation.length === 0) return false;
        if (this.graduationCity.length === 0) return false;
        if (this.graduationInstitution.length === 0) return false;
        if (this.graduationYear.length === 0) return false;
        if (this.idCPF.length === 0) return false;
        if (this.idRG.length === 0) return false;
        if (!this.intendedCourseID) return false;
        if (this.lastName.length === 0) return false;
        if (this.phone.length === 0) return false;
        if (this.postalCode.length === 0) return false;

        return true;
    }
}

export { User, MaritalStatus }
