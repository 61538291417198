import React, { Component } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
import stateMachine from '../StateMachine';
import PALETTE from '../CFISP_utils/palette';
import { ChevronRight, GreenTickedCheckCircle } from '../icons';
import { Subject } from '../content_types';

type Props = {
    navigation: any,
    subject: Subject,
    isLast: boolean
};

export default class SubjectListItem extends Component<Props> {
    openSubject() {
        if (this.props.subject.published) {
            stateMachine.setSubject(this.props.subject);
            this.props.navigation.navigate('Subject', { subject: this.props.subject });
        }
    }

    isSubjectConcluded() {
        let nOfQuestions = this.props.subject.evaluation.length;
        let nOfCorrectlyAnsweredQuestions = 0;

        for (let i=0; i<nOfQuestions; i++) {
            if (this.props.subject.evaluation[i].answer == 0) {
                nOfCorrectlyAnsweredQuestions++;
            }
        }

        if (nOfCorrectlyAnsweredQuestions >= 7) {
            return true;
        } else {
            return false;
        }
    }

    render () {
        return <Pressable
            onPress={e => {
                this.openSubject();
            }}
            style={this.props.isLast ? {} : {
                borderStyle: 'solid',
                borderBottomColor: PALETTE.lightGrey,
                borderBottomWidth: 1,
                ...Platform.select({
                    web: {
                        cursor: this.props.subject.published ? "pointer" : "normal"
                    }
                })
            }}
        >
            <View style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 10,
                opacity: this.props.subject.published ? 1 : 0.3
            }}>
                { this.isSubjectConcluded()
                    ? <GreenTickedCheckCircle style={{ width: 30, height: 30 }} />
                    : <View style={{
                        width: 10,
                        height: 10,
                        backgroundColor: PALETTE.gray_o,
                        borderRadius: 5,
                        marginHorizontal: 10,
                    }} />
                }
                <Text style={{
                    flex: 1,
                    marginLeft: 12,
                    fontSize: 16,
                    color: PALETTE.gray_e
                }}>{this.props.subject.title}</Text>
                <ChevronRight style={{ width: 30, height: 30 }} />
            </View>
        </Pressable>;
    }
}
