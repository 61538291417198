import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, Pressable, Platform } from 'react-native';
import CourseHeading from '../../components/courseHeading';
import { formatPriceInBRL } from '../../CFISP_utils/functions'
import PALETTE from '../../CFISP_utils/palette';
import { PaymentCard } from '../../payment_types';
import { PaymentProductOverview, PaymentInstalmentPlans } from '../../components/paymentComponents';
import stateMachine from '../../StateMachine';
import { User } from '../../user';
import { Course } from '../../content_types';
import Markdown from 'react-native-markdown-display';
import { SERVER_URL } from '../../CFISP_utils/environmentConstants';
import { dummyContract } from '../../CFISP_utils/dummyContract';

type PaymentInstalmentPlansScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type PaymentInstalmentPlansScreenRouteProp = RouteProp<RootStackParamList, 'PaymentInstalmentPlans'>;

interface Props {
    navigation: PaymentInstalmentPlansScreenNavigationProp,
    route: PaymentInstalmentPlansScreenRouteProp
}

interface State {
    waiting: boolean,
    instalments: number
}

export default class PaymentInstalmentPlansScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Parcelamento',
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            waiting: false,
            instalments: 1
        }
    }

    next(instalments: number) {
        this.props.navigation.navigate("PaymentFiscalData", {
            course: this.props.route.params.course,
            paymentMode: this.props.route.params.paymentMode,
            instalments
        })
    }

    render () {
        if (this.state.waiting) return (
            <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        );

        return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <PaymentProductOverview title={this.props.route.params.course.title} price={this.props.route.params.course.price}/>
                    <View>
                        <Text>Em quantas vezes você gostaria de pagar?</Text>
                    </View>
                    <PaymentInstalmentPlans plans={[ 1, 2, 3, 4, 5, 6 ]} price={this.props.route.params.course.price} parent={this} />
                </ScrollView>
            </SafeAreaView>
        );
    }
}
