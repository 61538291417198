import Interaction from './interaction';

class Association extends Interaction {
    title: string
    typeName: string = "Association";

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string,
        title: string
    ) {
        super(id, pub_date, consumed, points, explanation);
        this.title = title;
    }
}

export default Association;
