import Association from './association';
import TextAssociationLine from './textAssociationLine';

class TextAssociation extends Association {
    typeName: string = "TextAssociation";
    instructions: string;
    leftLines: TextAssociationLine[];
    rightLines: TextAssociationLine[];

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string,
        title: string,
        instructions: string,
        leftLines: TextAssociationLine[],
        rightLines: TextAssociationLine[]
    ) {
        super(id, pub_date, consumed, points, explanation, title);
        this.instructions = instructions;
        this.leftLines = leftLines;
        this.rightLines = rightLines;
    }
}

export default TextAssociation;
