import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, Pressable, Platform } from 'react-native';
import CourseHeading from '../../components/courseHeading';
import { formatPriceInBRL, multiplatformPrompt } from '../../CFISP_utils/functions'
import PALETTE from '../../CFISP_utils/palette';
import paymentAPIAdapter from '../../http/payment';
import { PaymentCard } from '../../payment_types';
import { AddCardFormComponent, CreditCardList, PaymentProductOverview } from '../../components/paymentComponents';
import { CenterButton } from '../../components';
import stateMachine from '../../StateMachine';

type PaymentModeScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type PaymentModeScreenRouteProp = RouteProp<RootStackParamList, 'PaymentMode'>;

interface Props {
    navigation: PaymentModeScreenNavigationProp,
    route: PaymentModeScreenRouteProp
}

interface State {
    isLoading: boolean,
    showAddCreditCardForm: boolean,
    defaultCard: PaymentCard | null,
    otherCards: PaymentCard[],
    waiting: boolean,
    paymentMode: string
}

export default class PaymentModeScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Modo de Pagamento',
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            isLoading: true,
            showAddCreditCardForm: false,
            defaultCard: null,
            otherCards: [],
            waiting: false,
            paymentMode: "BLT"
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        // Get user cards
        paymentAPIAdapter.getCards(resArray => {
            let paymentMode = "BLT";
            let defaultCard: PaymentCard | null = null;
            let otherCards: PaymentCard[] = [];
            for (let i=0; i<resArray.length; i++) {
                let c = new PaymentCard(
                    resArray[i].card_name,
                    resArray[i].description,
                    resArray[i].client_id,
                    `xxxx xxxx xxxx ${resArray[i].cad_last_numbers}`,
                    resArray[i].cvc,
                    new Date(resArray[i].exp),
                    resArray[i].document_id,
                    resArray[i].zipcode,
                    resArray[i].country,
                    resArray[i].state,
                    resArray[i].city,
                    resArray[i].neighborhood,
                    resArray[i].address,
                    resArray[i].habitation_number,
                    resArray[i].habitation_complement
                );

                c.setBrand(resArray[i].brand);
                c.setLastNumber(resArray[i].cad_last_numbers);
                c.setCardToken(resArray[i].token);

                if (i === 0) {
                    defaultCard = c;
                    paymentMode = c.card_token;
                } else {
                    otherCards.push(c);
                }
            }

            if (defaultCard) {
                this.setState({
                    ...this.state,
                    defaultCard,
                    otherCards,
                    paymentMode,
                    isLoading: false
                })
            } else {
                this.setState({ ...this.state, paymentMode, isLoading: false });
            }
        });
    }

    setDefaultCard(card: PaymentCard) {
        if (this.state.paymentMode == "BLT") {
            let pm = card.card_token;
            let cs = this.state.otherCards;
            let c = card;

            cs.splice(cs.indexOf(c), 1);

            this.setState({ ...this.state, otherCards: cs, defaultCard: c, paymentMode: pm });

            return;
        }

        if (!this.state.defaultCard) return;
        
        let c;
        if (this.state.defaultCard)
            c = this.state.defaultCard;
        let cs = this.state.otherCards;

        cs.push(c);
        c = card;
        cs.splice(cs.indexOf(c), 1);

        this.setState({
            ...this.state,
            defaultCard: c,
            otherCards: cs
        })
    }

    addCard(card: PaymentCard) {
        if (this.state.defaultCard) {
            let otherCards = this.state.otherCards
            otherCards.push(this.state.defaultCard);
            this.setState({ ...this.state, otherCards, paymentMode: card.card_token, defaultCard: card });
        } else {
            this.setState({ ...this.state, defaultCard: card, paymentMode: card.card_token });
        }
    }

    removeCard(card: PaymentCard) {
        let otherCards = this.state.otherCards;
        otherCards.splice(otherCards.indexOf(card), 1);
        this.setState({ ...this.state, otherCards });
    }

    closeAddCardForm() {
        this.setState({ ...this.state, showAddCreditCardForm: false });
    }

    next() {        
        if (this.state.paymentMode != "BLT") stateMachine.paymentCard = this.state.defaultCard;
        this.props.navigation.navigate("PaymentInstalmentPlans", { course: this.props.route.params.course, paymentMode: this.state.paymentMode } )
    }

    render () {
        return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <PaymentProductOverview title={this.props.route.params.course.title} price={this.props.route.params.course.price}/>
                    { this.state.defaultCard ? null :
                        <View style={{
                            backgroundColor: PALETTE.yellow,
                            padding: 10,
                            marginVertical: 10,
                            borderRadius: 6,
                        }}>
                            <Pressable
                                onPress={() => {
                                    if (this.state.defaultCard)
                                        this.state.otherCards.push(this.state.defaultCard)
                                    this.setState({ ...this.state, paymentMode: "BLT", defaultCard: null });
                                }}
                            >
                                <Text style={{
                                    ...Platform.select({
                                        web: {
                                            cursor: "pointer"
                                        }
                                    }),
                                    color: PALETTE.forground,
                                    fontSize: 20,
                                    textAlign: "center",
                                }}>Boleto</Text>
                            </Pressable>
                        </View>
                    }
                    <View style={{ marginTop: 20 }}>
                        { this.state.defaultCard ?
                            <View style={{
                                backgroundColor: PALETTE.yellow,
                                padding: 10,
                                marginVertical: 10,
                                borderRadius: 6,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                                <View>
                                    <Text style={{ fontSize: 24 }}>xxxx xxxx xxxx {this.state.defaultCard.last_numbers}</Text>
                                    <Text>{this.state.defaultCard.brand}</Text>
                                </View>
                            </View> : null
                        }
                        { this.state.waiting || this.state.isLoading ? <ActivityIndicator animating={true} size="large" color={PALETTE.blue} /> : null }
                    </View>
                    { this.state.otherCards.length > 0 ? <CreditCardList parent={this} cards={this.state.otherCards} /> : null }
                    { this.state.defaultCard ?
                        <View style={{
                            backgroundColor: PALETTE.gray_b,
                            padding: 10,
                            marginVertical: 10,
                            borderRadius: 6,
                        }}>
                            <Pressable
                                onPress={() => {
                                    if (this.state.defaultCard)
                                        this.state.otherCards.push(this.state.defaultCard)
                                    this.setState({ ...this.state, paymentMode: "BLT", defaultCard: null });
                                }}
                            >
                                <Text style={{
                                    ...Platform.select({
                                        web: {
                                            cursor: "pointer"
                                        }
                                    }),
                                    color: PALETTE.forground,
                                    fontSize: 20,
                                    textAlign: "center",
                                }}>Boleto</Text>
                            </Pressable>
                        </View>
                    : null }
                    { this.state.showAddCreditCardForm ? <AddCardFormComponent parent={this} /> : null }
                    { this.state.showAddCreditCardForm ? null :
                        <Pressable
                            onPress={() => { this.setState({ ...this.state, showAddCreditCardForm: true }); }}
                        >
                            <Text style={{
                                ...Platform.select({
                                    web: {
                                        cursor: "pointer"
                                    }
                                }),
                                color: PALETTE.blue,
                                fontSize: 20,
                                textAlign: "center",
                                marginBottom: 20
                            }}>Cadastrar cartão de crédito</Text>
                        </Pressable>
                    }
                    { !this.state.waiting && !this.state.showAddCreditCardForm ?
                        <CenterButton
                            color={PALETTE.blue}
                            text="Continuar"
                            action={() => {
                                this.next();
                            }}
                        /> : null
                    }
                </ScrollView>
            </SafeAreaView>
        );
    }
}
