import EvaluationQuestionWrongAnswer from './evaluationQuestionWrongAnswer';

export default class EvaluationQuestion {
    id: number;
    question: string;
    emphasis: string;
    correctAnswer: string;
    wrongAnswers: EvaluationQuestionWrongAnswer[];
    answer: number; // -1 : user never answered this question
                    // 0  : user answered correctly
                    // n  : wrong option database id

    constructor(
        id: number,
        question: string,
        emphasis: string,
        correctAnswer: string,
        wrongAnswers: EvaluationQuestionWrongAnswer[],
        answer: number
    ) {
        this.id = id;
        this.question = question;
        this.emphasis = emphasis;
        this.correctAnswer = correctAnswer;
        this.wrongAnswers = wrongAnswers;
        this.answer = answer;
    }
}
