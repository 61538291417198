import React, { Component } from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import { RouteProp } from "@react-navigation/native";
import stateMachine from "../StateMachine";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../types";
import { SERVER_URL } from "../CFISP_utils/environmentConstants";
import { version } from "../../package.json";
import PALETTE from "../CFISP_utils/palette";
import { ProfileDataEditor, ProfileDataDisplay } from "../components";
import { SafeAreaView } from "react-native-safe-area-context";
import { ScrollView } from "react-native-gesture-handler";
import { Course } from "../content_types";
import http from "../http";
import { User } from "../user";

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type ProfileScreenRouteProp = RouteProp<RootStackParamList, "Profile">;

export interface Props {
  navigation: ProfileScreenNavigationProp;
  route: ProfileScreenRouteProp;
}

export interface State {
  authenticated: boolean;
  edit: boolean;
  courses: Course[];
  course: Course | null;
  waitingSave: boolean;
  user: User;
}

class Profile extends Component<Props, State> {
  public static navigationOptions = {
    title: "Perfil",
  };

  constructor(props: Props) {
    super(props);

    let cs = stateMachine.getCourses();

    this.state = {
      authenticated: stateMachine.authenticated,
      edit: false,
      courses: cs,
      course: null,
      waitingSave: false,
      user: this.props.route.params.user,
    };
    this._isMounted = false;
    stateMachine.setProfileScreen(this);
  }

  _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
    stateMachine.addCourseListUpdateSubscriber(this);

    if (
      stateMachine.unansweredFetchCoursesRequests === 0 &&
      stateMachine.courses.length > 0
    ) {
      this.updateCourses();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    stateMachine.removeCourseListUpdateSubscriber(this);
  }

  updateCourses() {
    let courses = stateMachine.getCourses();

    let course = courses[0];
    if (
      this.props.route.params.user.intendedCourseID &&
      this.props.route.params.user.intendedCourseID > 0
    ) {
      for (let i = 0; i < courses.length; i++) {
        if (
          courses[i] &&
          courses[i].id === this.props.route.params.user.intendedCourseID
        ) {
          course = courses[i];
          break;
        }
      }
    }

    if (this._isMounted) {
      this.setState({ ...this.state, courses, course });
    }
  }

  edit() {
    this.setState({ ...this.state, edit: true });
  }

  help() {
    console.log("help");
  }

  termsAndConditions() {
    console.log("terms and conditions");
  }

  login() {
    stateMachine.goToLogin();
  }

  saveUserData(student) {
    this.setState({ ...this.state, waitingSave: true });
    http.saveUserData(student, (res) => {
      stateMachine.updateUserData(student);
      this.setState({ ...this.state, waitingSave: false, edit: false });
    });
  }

  render() {
    if (!this.state.authenticated) {
      return (
        <View style={styles.root}>
          <View style={styles.picture}>
            <Image
              style={styles.icon}
              source={require("../../assets/images/img-perfil-default.png")}
            />
            <Text
              style={styles.text}
              onPress={() => {
                this.login();
              }}
            >
              Entrar
            </Text>
          </View>
          <View>
            <Text style={styles.debugInfo}>
              v{version} API: {SERVER_URL}
            </Text>
          </View>
        </View>
      );
    }

    // if (!this.state.course) {
    //   return (
    //     <ActivityIndicator
    //       animating={true}
    //       size="large"
    //       color={PALETTE.yellow}
    //     />
    //   );
    // }

    if (this.state.waitingSave) {
      return (
        <ActivityIndicator
          animating={true}
          size="large"
          color={PALETTE.yellow}
        />
      );
    }

    return (
      <SafeAreaView style={styles.root}>
        <ScrollView style={styles.content}>
          <View style={styles.picture}>
            <Image
              style={styles.icon}
              source={require("../../assets/images/img-perfil-default.png")}
            />
          </View>

          <Text style={styles.username}>{stateMachine.username}</Text>
          {/* { this.state.edit ? null : <Text style={styles.link} onPress={() => { this.edit() }}>Editar perfil</Text> }

                    { this.state.edit
                        ? <ProfileDataEditor user={ this.props.route.params.user } course={this.state.course} courses={this.state.courses} parent={this} />
                        : <ProfileDataDisplay intendedCourse={this.state.course} user={ this.state.user } />
                    }

                    { this.state.edit
                        ? <Text style={styles.cancel} onPress={() => {
                            this.setState({...this.state, edit: false });
                        }}>Cancelar</Text>
                        : null
                    } */}

          {/* <Text style={styles.text} onPress={() => { this.help() }}>Precisa de ajuda?</Text>
                    <View style={styles.separator}></View>
                    <Text style={styles.text} onPress={() => { this.termsAndConditions() }}>Termos e condições</Text>
                    <View style={styles.separator}></View> */}
          <View>
            <Text style={styles.debugInfo}>
              v{version} API: {SERVER_URL}
            </Text>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 20,
  },
  content: {
    flex: 1,
    flexDirection: "column",
  },
  picture: {
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    marginTop: 30,
    marginBottom: 20,
    width: 100,
    height: 100,
  },
  username: {
    fontSize: 28,
    textAlign: "center",
  },
  link: {
    textAlign: "center",
    color: PALETTE.blue,
    fontSize: 20,
    marginBottom: 30,
    marginTop: 15,
  },
  debugInfo: {
    textAlign: "center",
    fontStyle: "italic",
    color: PALETTE.gray_d,
  },
  separator: {
    backgroundColor: PALETTE.gray_k,
    height: 2,
    marginVertical: 10,
  },
  text: {
    color: PALETTE.darkGray,
    fontSize: 15,
    marginVertical: 10,
  },
  input: {
    height: 50,
    borderColor: PALETTE.gray_g,
    borderWidth: 1,
    backgroundColor: PALETTE.background,
    marginBottom: 24,
    borderRadius: 50,
    paddingHorizontal: 24,
    fontSize: 20,
  },
  cancel: {
    fontStyle: "italic",
    color: PALETTE.gray_e,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
});

export default Profile;
