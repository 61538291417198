import React, { Component } from 'react';
import {
    StyleSheet,
    StatusBar,
    Text,
    View,
    ScrollView,
    ActivityIndicator,
    TextInput,
    Platform,
    Pressable
} from 'react-native';
import http from '../http';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import PALETTE from '../CFISP_utils/palette';
import { multiplatformAlert, multiplatformPrompt } from '../CFISP_utils/functions';

import { Eye, EyeBarred } from '../icons';
import { RegisterIllustration } from '../illustrations';

const iconSize = 35

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: ProfileScreenNavigationProp,
}

interface State {
    email: string,
    password: string,
    passwordConfirmation: string,
    hidePassword: boolean,
    hidePasswordConfirmation: boolean,
    waiting: boolean
}

class RegisterScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Criar conta'
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            passwordConfirmation: '',
            hidePassword: true,
            hidePasswordConfirmation: true,
            waiting: false
        }

        if (Platform.OS === "android") StatusBar.setBackgroundColor(PALETTE.darkBlue);
        StatusBar.setBarStyle('light-content');
    }

    validateEmail (informedEmail: string) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        return reg.test(informedEmail);
    }

    sendRegisterInformation() {
        this.setState({ ...this.state, waiting: true });
        // prevent empty strings
        if (this.state.email == '') {
            this.setState({ ...this.state, waiting: false });
            multiplatformAlert("E-mail é um campo obrigatório.");
            return;
        }
        if (this.state.password == '') {
            this.setState({ ...this.state, waiting: false });
            multiplatformAlert("Senha é um campo obrigatório.");
            return;
        }
        if (this.state.passwordConfirmation == '') {
            this.setState({ ...this.state, waiting: false });
            multiplatformAlert("Confirmação de senha é um campo obrigatório.");
            return;
        }

        // prevent invalid email
        if (!this.validateEmail(this.state.email)) {
            this.setState({ ...this.state, waiting: false });
            multiplatformAlert("E-mail inválido");
            return;
        }

        // check password confirmation
        if (this.state.password != this.state.passwordConfirmation) {
            this.setState({ ...this.state, waiting: false });
            multiplatformAlert("Confirmação de senha falhou.");
            return;
        }

        http.register(this.state.email, this.state.password, (res) => {
            this.setState({ ...this.state, waiting: false }, () => {
                if (res === 200) {
                    multiplatformPrompt(
                        "Verifique seu email!",
                        `Nós acabamos de enviar um email para ${this.state.email} contendo um link de validação. O remetente é no-reply@cfisp.com.br. Se não estiver na sua caixa de entrada, pode estar na pasta "spam". Se você errou o email ou não tiver acesso ao email ${this.state.email}, cancele e informe um email válido e seu.`,
                        "Recebi o email e já acessei o link!",
                        () => { 
                            http.sendCredentials(this.state.email, this.state.password, () => {
                                this.props.navigation.navigate("Tabs");
                            })
                        },
                        "Informar outro email",
                        () => { this.props.navigation.navigate("Register") }
                    );
                } else if (res === 304) {
                    multiplatformAlert("Este email já está em uso na plataforma CFISP.");
                } else {
                    multiplatformAlert(`Alguma coisa deu errado. Por favor, tente mais tarde. ERRO: ${res}`);
                }
            });
        });
    }

    setEmail(email: string) {
        this.setState({ ...this.state, email })
    }

    setPassword(password: string) {
        this.setState({ ...this.state, password })
    }

    setPasswordConfirmation(passwordConfirmation: string) {
        this.setState({ ...this.state, passwordConfirmation })
    }

    render() {
        return (
            <ScrollView style={styles.root}>
                <View style={styles.imageContainer}>
                    <RegisterIllustration
                        width={233}
                        height={225}
                    />
                </View>
                <View>
                    <TextInput
                        style={styles.input}
                        onChangeText={email => this.setEmail(email)}
                        value={ this.state.email }
                        placeholder={"E-mail"}
                        textContentType="emailAddress"
                        autoCapitalize="none"
                    />
                    <View style={styles.passwordContainer}>
                        <TextInput
                            style={styles.passwordInput}
                            onChangeText={password => this.setPassword(password)}
                            value={ this.state.password }
                            placeholder={"Defina sua senha"}
                            textContentType="password"
                            secureTextEntry={this.state.hidePassword}
                        />
                        <Pressable
                            onPressIn={() => { this.setState({ ...this.state, hidePassword: false }) }}
                            onPressOut={() => { this.setState({ ...this.state, hidePassword: true }) }}
                        >
                            { this.state.hidePassword
                                ? <EyeBarred width={iconSize} height={iconSize} />
                                : <Eye width={iconSize} height={iconSize} />
                            }
                        </Pressable>
                    </View>
                    <View style={styles.passwordContainer}>
                        <TextInput
                            style={styles.passwordInput}
                            onChangeText={passwordConfirmation => this.setPasswordConfirmation(passwordConfirmation)}
                            value={ this.state.passwordConfirmation }
                            placeholder={"Confirme a senha"}
                            textContentType="password"
                            secureTextEntry={this.state.hidePasswordConfirmation}
                        />
                        <Pressable
                            onPressIn={() => {this.setState({ ...this.state, hidePasswordConfirmation: false })}}
                            onPressOut={() => {this.setState({ ...this.state, hidePasswordConfirmation: true })}}
                        >
                            { this.state.hidePasswordConfirmation
                                ? <EyeBarred width={iconSize} height={iconSize} />
                                : <Eye width={iconSize} height={iconSize} />
                            }
                        </Pressable>
                    </View>
                </View>
                <View style={styles.loginLinkContainer}>
                    <Text style={styles.loginLinkText}>Já tem uma conta? <Text style={styles.link} onPress={() => {this.props.navigation.navigate('Login')}}>Entre</Text></Text>
                </View>

                {
                    this.state.waiting ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> :
                    <View style={styles.sendButtonContainer}>
                        <Pressable style={styles.sendButton} onPress={()=>{ this.sendRegisterInformation() }}>
                            <Text style={styles.sendButtonText}>Enviar</Text>
                        </Pressable>
                    </View>
                }
                <View style={styles.legalNotice}>
                    <Text style={styles.legalNoticeText}>Ao criar sua conta, você concorda com os <Text style={styles.legalNoticeLink}>Termos e condições de uso</Text> e a <Text style={styles.legalNoticeLink}>Política de privacidade</Text></Text>
                </View>
            </ScrollView>
        );
    }
};

// styles
const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.background,
        paddingHorizontal: 30
    },
    imageContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 40,
        marginBottom: -20
    },
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    },
    loginLinkContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: -10,
        marginBottom: 30,
    },
    loginLinkText: {
        fontSize: 18  
    },
    link: {
        color: PALETTE.gayBlue,
    },
    sendButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    sendButton: {
        height: 50,
        backgroundColor: PALETTE.lightGreen,
        width: '60%',
        padding: 12,
        borderRadius: 50,
        marginBottom: 20,
        ...Platform.select({
            web: {
                cursor: 'pointer',
                marginBottom: 40,
            }
        }),
    },
    sendButtonText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 20
    },
    legalNotice: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
        marginVertical: 20
    },
    legalNoticeText: {
        textAlign: 'center'
    },
    legalNoticeLink: {
        color: PALETTE.gayBlue
    },
    passwordContainer: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 20,
        fontSize: 20,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    eyeIcon: {
        flex: 1,
        color: PALETTE.gray_g,
        alignItems: 'flex-end',
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    passwordInput: {
        height: 50,
        // backgroundColor: PALETTE.background,
        flex: 9,
        fontSize: 20
    }
});

export default RegisterScreen;
