import React, { Component } from 'react';
import { Image, StyleSheet, Text, Platform, TouchableOpacity, ScrollView, View, Button } from 'react-native';
import {
    getFocusedRouteNameFromRoute,
    ParamListBase,
  } from '@react-navigation/native';
import {
    createBottomTabNavigator,
    BottomTabScreenProps,
} from '@react-navigation/bottom-tabs';
import { StackScreenProps, StackNavigationProp, HeaderStyleInterpolators } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import HomeScreen from '../screens/homeScreen';
import SearchScreen from '../screens/searchScreen';
import OwnedCoursesScreen from '../screens/ownedCoursesScreen';
import FavoritesScreen from '../screens/favoriteScreen';
import ProfileScreen from '../screens/profileScreen';
import PALETTE from '../CFISP_utils/palette';

import {
    NoIcon,
    House,
    HouseActive,
    MagnifyingGlass,
    MagnifyingGlassActive,
    Headset,
    HeadsetActive,
    Heart,
    HeartActive,
    Person,
    PersonActive
} from '../icons';
import { User } from '../user';
import stateMachine from '../StateMachine';

const Tab = createBottomTabNavigator<RootStackParamList>();

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: ProfileScreenNavigationProp,
}

class BottomNavBar extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    chooseIcon(routeName: string, active: boolean) {
        if (active) {
            switch (routeName) {
                case "Home":
                    return <HouseActive width={20} height={20} />;
                
                /* ----- ECOMMERCE FEATURE ----- */
                // case "Search":
                //     return <MagnifyingGlassActive width={20} height={20} />;

                case "OwnedCourses":
                    return <HeadsetActive width={20} height={20} />;
                case "Favorites":
                    return <HeartActive width={20} height={20} />;
                case "Profile":
                    return <PersonActive width={20} height={20} />;
            }
        } else {
            switch (routeName) {
                case "Home":
                    return <House width={20} height={20} />;

                /* ----- ECOMMERCE FEATURE ----- */
                // case "Search":
                //     return <MagnifyingGlass width={20} height={20} />;

                case "OwnedCourses":
                    return <Headset width={20} height={20} />;
                case "Favorites":
                    return <Heart width={20} height={20} />;
                case "Profile":
                    return <Person width={20} height={20} />;
            }
        }
    }

    render () {
        return (
            <Tab.Navigator
                screenOptions={({ route }) => ({
                    tabBarIcon: ({ focused }) => {
                        return this.chooseIcon(route.name, focused);
                    }
                })}

                tabBarOptions={{
                    style: Platform.OS == "android" ? styles.androidTab : styles.iOSTab,
                    labelStyle: styles.label
                }}
            >
                <Tab.Screen name="Home" component={HomeScreen} options={{tabBarLabel: "Início"}} />

                {/* ----- ECOMMERCE FEATURE ----- */}
                {/* <Tab.Screen name="Search" component={SearchScreen} options={{tabBarLabel: "Procurar"}} /> */}

                <Tab.Screen name="OwnedCourses" component={ OwnedCoursesScreen } options={{ tabBarLabel: "Meus Cursos" }} />
                <Tab.Screen name="Favorites" component={ FavoritesScreen } options={{tabBarLabel: "Favoritos"}} />
                <Tab.Screen name="Profile" component={ ProfileScreen } options={{tabBarLabel: "Perfil"}} initialParams={{ user: stateMachine.getUser() }} />
            </Tab.Navigator>
        );
    }
}

const styles = StyleSheet.create({
    iOSTab: {
        height: 100,
        borderTopColor: PALETTE.gray_b,
        borderTopWidth: 1,
        paddingBottom: 30,
        paddingTop: 10
    },
    androidTab: {
        height: 80,
        borderTopColor: PALETTE.gray_b,
        borderTopWidth: 1,
        paddingBottom: 12,
        paddingTop: 10
    },
    label: {
        color: PALETTE.gray_e,
        margin: 0
    },
    icon: {
        width: 24,
        height: 24
    }
});

export default BottomNavBar;
