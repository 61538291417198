import Resource from './resource';

class Video extends Resource {
    typeName: string = "Video";
    yt_id: string;
    title: string;
    description: string;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        yt_id: string,
        title: string,
        description: string
    ) {
        super(id, pub_date, consumed);
        this.yt_id = yt_id;
        this.title = title;
        this.description = description;
    }
}

export default Video;
