import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <Path
        d="M2.5 11h12.2l-5.6 5.6 1.4 1.4 8-8-8-8-1.4 1.4L14.7 9H2.5v2z"
        fill="#0f0f0f"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default SvgComponent
