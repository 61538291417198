import React, { Component } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { SummaryTopic } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import Markdown from 'react-native-markdown-display';

export interface Props {
    topic: SummaryTopic;
    index: number;
    singleTopicSummary: boolean;
}

export default class SummaryTopicComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);

        console.log(props);
    }

    render() {
        return <View style={[{ flex: 1, flexDirection: 'row' }, this.props.index == 0 ? {} : styles.notFirst ]}>
            { this.props.singleTopicSummary ? null :
                <View style={{ flex: 1 }}>
                    <View style={{ backgroundColor: PALETTE.gray_k, width: 22, height: 22, borderRadius: 11, justifyContent: 'space-around' }}>
                        <Text style={{ textAlign: 'center', color: PALETTE.gray_n }}>
                            { this.props.index + 1 }
                        </Text>
                    </View>
                </View>
            }
            <View style={{ flex: 9, marginTop: -10 }}>
                <View>
                    <Markdown style={ markdownStyles }>
                        { this.props.topic.text }
                    </Markdown>
                </View>
            </View>
        </View>
    }
}

const markdownStyles = StyleSheet.create({
    text: {
        fontSize: 16,
        lineHeight: 20,
        color: PALETTE.gray_e
    }
});

const styles = StyleSheet.create({
    notFirst: {
        paddingTop: 20,
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: PALETTE.gray_k
    }
});
