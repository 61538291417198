import React, { Component } from 'react';
import { StyleSheet, View, Text, Alert, Platform, Pressable } from 'react-native';
import stateMachine from '../../StateMachine';
import { TextDragAndDrop } from '../../content_types';
import { CenterButton } from '../../components';
import { FillGaps, Correct, Wrong } from '../../icons';
import PALETTE from '../../CFISP_utils/palette';
import shuffle from '../../CFISP_utils/shuffle';

type Gap = { type: 'gap', text: string, position: number }
type Filling = { type: 'filling', text: string, position: number, chosenPosition: number, state: ButtonState, orderOfChoosing: string }
type Fragment = { type: 'fragment', text: string, position: number }

enum ButtonState { normal, used, correct, wrong }

interface Props {
    textDragAndDrop: TextDragAndDrop;
    parent: any;
};

interface State {
    availableFillings: Filling[];
    gaps: (Gap|Filling)[];
    text: (Gap|Filling|Fragment)[];
    sent: boolean;
    showMustFillAllWarning: boolean;
    counter: number;
};

class TextDragAndDropComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        let text: (Gap|Filling|Fragment)[] = [];
        let availableFillings: Filling[] = [];
        let gaps: (Gap|Filling)[] = [];

        for (let i=0; i<props.textDragAndDrop.fragments.length; i++) {
            text.push({
                type: "fragment",
                text: props.textDragAndDrop.fragments[i].text,
                position: props.textDragAndDrop.fragments[i].position
            });

            if (i < props.textDragAndDrop.draggables.length) {
                text.push({ type: "gap", position: props.textDragAndDrop.draggables[i].position, text: ''});
                
                availableFillings.push({
                    type: 'filling',
                    text: props.textDragAndDrop.draggables[i].text,
                    position: props.textDragAndDrop.draggables[i].position,
                    chosenPosition: -1,
                    state: ButtonState.normal,
                    orderOfChoosing: ""
                })

                gaps.push({
                    type: 'gap',
                    position: props.textDragAndDrop.draggables[i].position,
                    text: ''
                })
            }
        }

        shuffle(availableFillings);

        this.state = { availableFillings, gaps, text, sent: false, showMustFillAllWarning: false, counter: 0 }

        if (this.props.textDragAndDrop.explanation.length == 0) {
            this.props.parent.setExplanationText(this.renderCorrectText());
        } else {
            this.props.parent.setExplanationText(
                `${this.props.textDragAndDrop.explanation}&nbsp;\n\n---\n\n&nbsp;${this.renderCorrectText()}`
            );
        }
    }

    renderCorrectText() {
        let text = "";

        for (let i=0; i<this.props.textDragAndDrop.fragments.length; i++) {
            text = text + this.props.textDragAndDrop.fragments[i].text;
            if (i < this.props.textDragAndDrop.draggables.length)
                text = text + "**" + this.props.textDragAndDrop.draggables[i].text + "**";
        }

        return text;
    }

    send() {
        if (this.state.counter < this.state.availableFillings.length) {
            this.setState({ ...this.state, showMustFillAllWarning: true })
        } else {
            this.setState({ ...this.state, sent: true });
            // this.props.parent.hideBottomSubjectNavBar();
            this.props.textDragAndDrop.consume();

            this.verify();
        }
    }

    verify() {
        for (let i=0; i<this.state.availableFillings.length; i++) {
            if (this.state.availableFillings[i].position == this.state.availableFillings[i].chosenPosition) {
                this.state.availableFillings[i].state = ButtonState.correct;
            } else {
                this.state.availableFillings[i].state = ButtonState.wrong;
            }
        }
    }

    fill(index: number) {
        let gaps = this.state.gaps;
        let availableFillings = this.state.availableFillings;
        let counter = this.state.counter + 1;

        for (let i=0; i<gaps.length; i++) {
            if (gaps[i].type == "gap") {
                gaps[i] = this.state.availableFillings[index];
                break;
            }
        }

        let text: (Gap|Filling|Fragment)[] = [];

        for (let i=0; i<this.props.textDragAndDrop.fragments.length; i++) {
            text.push({
                type: "fragment",
                text: this.props.textDragAndDrop.fragments[i].text,
                position: this.props.textDragAndDrop.fragments[i].position
            });

            if (i < gaps.length) {
                text.push(gaps[i]);
            }
        }

        availableFillings[index].state = ButtonState.used
        availableFillings[index].orderOfChoosing = `${counter}`;
        availableFillings[index].chosenPosition = this.state.counter;
        this.setState({ ...this.state, gaps, text, availableFillings, showMustFillAllWarning: false, counter: counter });
    }

    removeFill(index: number) {
        let gaps = this.state.gaps;
        let availableFillings = this.state.availableFillings;
        let text = this.state.text;

        if (text[index].type != 'filling') return;

        for (let i=0; i<gaps.length; i++) {
            if (gaps[i].type == "filling" && gaps[i].position == text[index].position) {
                gaps[i] = { type: "gap", position: gaps[i].position, text: '' };
                break;
            }
        }

        text = [];

        for (let i=0; i<this.props.textDragAndDrop.fragments.length; i++) {
            text.push({
                type: "fragment",
                text: this.props.textDragAndDrop.fragments[i].text,
                position: this.props.textDragAndDrop.fragments[i].position
            });

            if (i < gaps.length) {
                text.push(gaps[i]);
            }
        }

        this.setState({ gaps, availableFillings, text });
    }

    renderText() {
        return this.state.text.map((item, index) => {
            switch (item.type) {
                case "gap":
                    return <Text key={index} style={{ color: PALETTE.forground }} > _________ </Text>
                case 'fragment':
                    return <Text key={index}>{item.text}</Text>
                case 'filling':
                    return <Text
                        // onPress={() => this.removeFill(index)}
                        style={styles.filledGap}
                        key={index}>{item.text}
                    </Text>
            }
        });
    }

    renderButtons() {
        return this.state.availableFillings.map((item, index) => {
            return <View key={index} style={styles.buttonContainer}>
                <Pressable style={[
                    styles.button,
                    this.decideButtonStyle(item.state)
                ]} onPress={() => {
                    if (item.state == ButtonState.normal) this.fill(index)
                }}>
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        { this.showIcon(item.state) }
                        <Text style={styles.buttonText}>{item.text}</Text>
                        <Text style={[styles.buttonText, {
                            color: PALETTE.background,
                            fontSize: 24,
                            marginVertical: 0,
                            fontWeight: 'bold'
                        }]}>{item.orderOfChoosing}</Text>
                    </View>
                </Pressable>
            </View>
        })
    }

    decideButtonStyle(state: ButtonState) {
        switch (state) {
            case ButtonState.normal:
                return styles.normal;
            case ButtonState.used:
                return styles.used;
            case ButtonState.correct:
                return styles.correct;
            case ButtonState.wrong:
                return styles.wrong;
        }
    }

    showIcon(state: ButtonState) {
        switch (state) {
            case ButtonState.correct:
                return <Correct style={styles.resultIcon} />;
            case ButtonState.wrong:
                return <Wrong style={styles.resultIcon} />;
            default:
                return null;
        }
    }

    render() {
        return (
            <View style={styles.quizView}>
                <View style={styles.icon}>
                    <FillGaps
                        width={62}
                        height={62}
                    />
                </View>
                <Text style={styles.title}>PREENCHER LACUNAS</Text>

                <Text style={styles.authorInstructions}>
                    { this.props.textDragAndDrop.instructions }
                </Text>

                <Text style={styles.text}>
                    { this.renderText() }
                </Text>

                <Text style={styles.instructionText}>Selecione as opções na sequência correta para <Text style={styles.emphasisText}>preencher as lacunas</Text> do texto acima.</Text>

                { this.renderButtons() }

                { this.state.sent ? <View style={{
                    marginTop: 30
                }}>
                    <CenterButton
                        text="Ver gabarito"
                        action={() => {
                            this.props.parent.showExplanationPane();
                        }}
                        color={ PALETTE.gayBlue }
                        paddingVertical={8}
                        paddingHorizontal={60}
                        width="80%"
                    />
                    <CenterButton
                        text="Avançar"
                        action={() => {
                            this.props.parent.goForward();
                        }}
                        color={ PALETTE.gayGreen }
                        paddingVertical={8}
                        paddingHorizontal={60}
                        width="80%"
                        marginTop={16}
                    />
                    <Text style={[styles.text, { marginTop: 16, textAlign: 'center' }]}>
                        Voltar para <Text
                            style={{
                                color: PALETTE.gayBlue
                            }}
                            onPress={() => { stateMachine.goToThisSubjectList() }}
                        >lista de lições</Text>
                    </Text>
                </View> : <View style={{
                    marginTop: 30
                }}>
                    { this.state.showMustFillAllWarning ? <Text style={[{ marginBottom: 5, color: PALETTE.red, textAlign: 'center' }]}>
                        Preencha todas as lacunas
                    </Text> : null }
                    <CenterButton
                        text="Enviar"
                        action={() => { this.send() }}
                        color={ this.state.counter == this.state.availableFillings.length ? PALETTE.gayBlue : PALETTE.lightGayBlue }
                        paddingVertical={8}
                        paddingHorizontal={60}
                    />
                </View> }
            </View>
        )
    }
}

const checkRadius = 45;
const padding = 22;

const styles = StyleSheet.create({
    quizView: {
        backgroundColor: PALETTE.almostWhite,
        margin: 24,
        marginBottom: 24+checkRadius,
        borderRadius: 20,
        padding: padding,
    },
    icon: {
        alignSelf: 'center',
        marginBottom: 10
    },
    title: {
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 10
    },
    text: {
        fontSize: 16,
        marginBottom: 10,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 10,
        marginTop: 10
    },
    buttonText: {
        margin: 16,
        fontSize: 18,
    },
    button: {
        flex: 7,
        marginTop: 10,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        // alignItems: 'flex-start',
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    space: {
        flex: 1
    },
    normal: {
        backgroundColor: PALETTE.background,
        borderColor: PALETTE.lightBlue,
    },
    used: {
        backgroundColor: PALETTE.gray_m,
        borderColor: PALETTE.gray_m
    },
    correct: {
        backgroundColor: PALETTE.lightGayGreen,
        borderColor: PALETTE.anotherGreen
    },
    wrong: {
        backgroundColor: PALETTE.lightRed,
        borderColor: PALETTE.red
    },
    check: {
        width: checkRadius*2,
        height: checkRadius*2,
        position: 'relative',
        bottom: -checkRadius - padding,
        alignSelf: 'center'
    },
    emptyGap: {
        color: PALETTE.lightBlue
    },
    filledGap: {
        color: PALETTE.gayBlue,
        fontWeight: 'bold'
    },
    instructionText: {
        fontSize: 16,
        marginTop: 6,
        fontWeight: 'bold'
    },
    emphasisText: {
        color: PALETTE.gayBlue
    },
    resultIcon: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: -5,
        top: -5
    },
    authorInstructions: {
        marginBottom: 13
    }
})

export default TextDragAndDropComponent;
