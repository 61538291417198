import { SERVER_URL } from './environmentConstants';
import stateMachine from '../StateMachine';

const get = async (path: string) => {
    let response = fetch(`${SERVER_URL}${path}`, {
        // mode: 'no-cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Token ${stateMachine.token}`
        }
    }).then(response => response.json()).catch(error => {
        console.error(error);
    });

    return response;
}

const post = async (path: string, body: any) => {
    let postHeaders = new Headers();
    postHeaders.append("Content-Type", "application/json; charset=utf-8");
    postHeaders.append("Host", "cfisp.com.br");
    if (stateMachine.token && stateMachine.token != '') postHeaders.append("Authorization", `Token ${stateMachine.token}`);
    else console.log("No token on statemachine");

    let response = fetch(`${SERVER_URL}${path}`, {
        method: 'POST',
        headers: postHeaders,
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    }).catch(error => {
        console.log(error);
    });

    return response;
}

export { get, post }
