import React, { Component } from 'react';
import { StyleSheet, View, Text, Image, Pressable } from 'react-native';
import { TimelineItem } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { Plus, Minus } from '../../icons';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

type Props = {
    item: TimelineItem,
    startExpanded: boolean,
    list: any,
    index: number
};

const iconSize = 35;

class TimelineItemComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        let expanded = this.props.list.state.expandedItem == this.props.index;

        return (
            <View>
                <View style={styles.heading}>
                    <View style={styles.dateContainer}>
                        <Text style={styles.date}>{ this.props.item.date }</Text>
                        <View style={styles.circle}></View>
                        <View style={styles.hLine}></View>
                        <View style={styles.vLine}></View>
                    </View>
                    <View style={styles.barContainer}>
                        <Markdown rules={ MarkdownCustomRules } style={{
                            body: { fontSize: 18, marginLeft: 16, fontWeight: 'bold' },
                        }}>
                            { this.props.item.heading } 
                        </Markdown>
                        <Pressable
                            onPress={() => {
                                this.props.list.setExpandedItem(this.props.index);
                            }}
                            style={{
                                flex: 1,
                                flexDirection: 'row-reverse',
                                justifyContent: 'space-between',
                            }}
                        >
                            <View style={{
                                marginRight: 20
                            }} >
                                { expanded
                                    ? <Minus width={iconSize} height={iconSize} />
                                    : <Plus width={iconSize} height={iconSize} />
                                }
                            </View>
                        </Pressable>
                    </View>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.bodyMargin}>
                        {
                            expanded || this.props.index < this.props.list.props.timeline.items.length - 1 ?
                            <View style={styles.vLine}></View> : null
                        }
                    </View>
                    <View style={styles.bodyText}>
                        <Markdown rules={ MarkdownCustomRules } style={ expanded ? { body: {fontSize: 18, margin: 16} } : { body: { margin: 12 } } }>
                            { expanded ? this.props.item.body : "" }
                        </Markdown>
                        {/* <Text style={ expanded ? styles.body : styles.bodyExpanded }>
                            { expanded ? this.props.item.body : "" }
                        </Text> */}
                    </View>
                </View>
            </View>
        )
    }
}

const columnsRation = {
    left: 2,
    right: 5
}

const styles = StyleSheet.create({
    heading: {
        flex: 1,
        flexDirection: 'row'
    },
    dateContainer: {
        flex: columnsRation.left,
        flexDirection: 'row',
        alignItems: 'center'
    },
    date: {
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 18
    },
    circle: {
        width: 12,
        height: 12,
        borderRadius: 100,
        backgroundColor: PALETTE.gayBlue,
        marginLeft: 8
    },
    hLine: {
        height: 2,
        backgroundColor: PALETTE.gayBlue,
        flex: 1
    },
    vLine: {
        width: 2,
        height: '100%',
        backgroundColor: PALETTE.gayBlue,
        position: 'absolute',
        right: 0
    },
    barContainer: {
        backgroundColor: PALETTE.yellow,
        // height: 50,
        flex: columnsRation.right,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    headingText: {
        fontSize: 18,
        margin: 20,
        fontWeight: 'bold'
    },
    icon: {
        width: 60,
        height: 60,
        position: 'absolute',
        left: -20
    },
    actionIcon: {
        position: 'absolute',
        right: 20
    },
    bodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    bodyMargin: {
        flex: columnsRation.left
    },
    bodyText: {
        flex: columnsRation.right
    },
    body: {
        fontSize: 18,
        margin: 16,
    },
    bodyExpanded: {
        margin: 12,
    }
})

export default TimelineItemComponent;
