import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 20 20"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Circle cx={10} cy={10} r={10} id="prefix__circle835" fill="#32cc95" />
      <Path
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        d="M14.182 6.4a1 1 0 00-.662.268l-4.881 4.531-2.12-2.193a1 1 0 00-1.414-.026 1 1 0 00-.025 1.415l2.8 2.9a1 1 0 001.401.037l5.6-5.2a1 1 0 00.053-1.413 1 1 0 00-.752-.319z"
        id="prefix__path854"
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#fff"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
      />
    </Svg>
  )
}

export default SvgComponent
