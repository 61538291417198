import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      height={1024}
      width={732}
      xmlSpace="preserve"
      viewBox="0 0 732 1024"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <G
        transform="matrix(1.18694 0 0 1.1862 -1052.99 -91.433)"
        id="prefix__g877"
      >
        <Path
          d="M1501.917 597.603c-1.029-34.975-23.66-58.635-59.664-66.865-26.746-6.172-54.52-10.286-81.266-14.401-131.672-24.689-132.7-24.689-137.844-156.36-2.057-45.263-9.258-84.353-65.836-84.353-58.635 1.03-68.922 41.148-68.922 89.496 1.029 79.209 0 159.446 0 253.057-18.516-16.46-26.746-23.66-33.946-30.86-38.062-38.062-81.267-58.636-124.471-12.345-39.09 44.233-17.488 84.352 18.516 119.327 63.778 62.75 125.5 126.529 190.307 187.221 63.778 59.664 140.93 73.037 223.225 44.234 81.266-28.803 126.528-88.467 136.815-171.79 7.2-50.406 5.143-103.898 3.086-156.361m-36.004 111.098c2.057 86.41-31.89 150.188-114.184 183.106-81.266 32.918-147.102 5.144-204.709-52.463-56.577-56.578-113.155-112.127-169.733-168.704-20.574-20.574-48.348-43.205-17.487-74.066 32.917-31.889 52.462 4.115 73.036 22.631 26.746 24.689 51.434 50.406 90.525 88.467V378.493c0-28.803-4.115-68.922 36.004-66.865 36.004 2.058 26.745 41.148 26.745 66.865 1.03 142.987 1.03 146.073 145.045 168.704 163.561 25.718 131.672 20.574 134.758 161.504"
          id="prefix__path844"
          fill="#ffd03c"
          fillOpacity={1}
          strokeWidth={10.287}
        />
        <Path
          d="M927.91 339.403c6.172-126.528 98.754-223.225 220.139-224.254 132.7-2.057 231.454 90.525 237.626 222.197 1.03 18.516 1.03 36.004 1.03 54.52 0 9.258 3.085 17.488 14.4 18.516 14.402 1.029 21.603-6.172 22.632-19.545 1.029-13.373 0-27.774 0-41.147-1.029-156.36-113.156-271.573-265.401-272.602-148.13-1.029-264.372 111.098-266.43 262.315 0 22.631-18.516 66.865 17.488 66.865 37.033 0 16.459-44.234 18.516-66.865"
          id="prefix__path846"
          fill="#ffd03c"
          fillOpacity={1}
          strokeWidth={10.287}
        />
        <Path
          d="M1113.074 220.075c52.463-18.516 97.725-5.143 137.844 33.947 38.061 36.004 34.975 82.295 36.004 127.557 0 15.43 3.086 26.746 21.602 24.689 15.43-2.058 13.373-13.373 15.43-24.689 15.43-101.84-51.434-193.393-148.13-201.622-111.099-10.287-183.107 55.549-185.164 167.675 0 7.201-1.029 13.373-1.029 20.574 1.03 14.402-7.2 38.062 14.402 40.119 29.832 3.086 19.545-23.66 21.602-40.119 1.03-11.315 0-22.63 1.03-33.946 6.171-56.578 34.975-95.668 86.409-114.185"
          id="prefix__path848"
          fill="#ffd03c"
          fillOpacity={1}
          strokeWidth={10.287}
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
