import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let bck: string = "black";
    if (props.color) bck = props.color

    return (
        <Svg viewBox="0 0 13.229 26.458" height={100} width={50} {...props}>
            <Path
                d="M0 0v13.23A13.23 13.23 0 0113.215 0H0zm0 13.243v13.215h13.23A13.23 13.23 0 010 13.243z"
                fill={bck}
            />
        </Svg>
    )
}

export default SvgComponent
