import Interaction from './interaction';

class Boolean extends Interaction {
    typeName: string = "Boolean";
    statement: string;
    correct: boolean;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string,
        statement: string,
        correct: boolean
    ) {
        super(id, pub_date, consumed, points, explanation)
        this.statement = statement;
        this.correct = correct;
    }
}

export default Boolean;
