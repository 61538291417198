import React, { Component } from 'react';
import { View } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

interface Props {
    intervals: number;
    location: number;
    isInteraction: boolean;
}

export default class LessonNavigationBar extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={{
                height: 6,
                marginHorizontal: 22,
                marginTop: 16
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <View style={{ flex: this.props.location, height: 3, backgroundColor: PALETTE.darkBlue }}></View>
                    <View style={{
                        flex: 1,
                        height: 6,
                        backgroundColor: this.props.isInteraction ? PALETTE.interestingBlue : PALETTE.yellow
                    }}></View>
                    <View style={{ flex: this.props.intervals - 1 - this.props.location, height: 3, backgroundColor: PALETTE.darkBlue }}></View>
                </View>
            </View>
        )
    }
}
