import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform, Dimensions } from 'react-native';
import { Video as CFISPVideo } from '../../content_types';
import { YOUTUBE_API_KEY, S3_VIDEOS } from '../../CFISP_utils/environmentConstants';
import { Player } from 'video-react';

type Props = {
    video: CFISPVideo
};

class VideoWebComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        if (!this.props.video.consumed) this.props.video.consume();

        return <View
            ><Player>
                <source src={`${S3_VIDEOS}${this.props.video.yt_id}`} />
            </Player>
            <Text style={styles.videoTitle}>{this.props.video.title}</Text>
            <Text style={styles.videoDescription}>{this.props.video.description}</Text>
        </View>
    }
}

const styles = StyleSheet.create({
    text: {
        margin: 20
    },
    video: {
        alignSelf: 'stretch',
        // height: 300
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    videoTitle: {
        textAlign: 'center',
        marginVertical: 12,
        fontWeight: 'bold',
        fontSize: 16
    },
    videoDescription: {
        marginLeft: 12
    },
})

export default VideoWebComponent;
