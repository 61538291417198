import { SERVER_URL } from '../CFISP_utils/environmentConstants';
import stateMachine from '../StateMachine';
import { PaymentCard, PaymentClient } from '../payment_types';
import { Course } from '../content_types';

class PaymentAPIAdapter {
    constructor() {}

    private async get(path: string) {
        let response = await fetch(`${SERVER_URL}${path}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${stateMachine.token}`
            }
        }).then(response => response.json().catch(err => {
            console.log(err)
        })).catch(error => {
            console.log(error);
        });

        return response;
    }

    private async post(path: string, body: any) {
        let postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        if (stateMachine.token && stateMachine.token != '') postHeaders.append("Authorization", `Token ${stateMachine.token}`);
    
        let response = await fetch(`${SERVER_URL}${path}`, {
            method: 'POST',
            headers: postHeaders,
            body: JSON.stringify(body)
        }).then(response => response.json().catch(err => {
            console.log(err)
        })).catch(error => {
            console.log(error);
        });
    
        if (response.type) {
            if (response.type === "CONTRACT") {
                return response;
            } else if (response.type === "INVOICE") {
                return response;
            }
        }

        return JSON.parse(response[0]);
    }

    private async delete(path: string) {
        let deleteHeaders = new Headers();
        deleteHeaders.append("Content-Type", "application/json");
        if (stateMachine.token && stateMachine.token != '') deleteHeaders.append("Authorization", `Token ${stateMachine.token}`);
    
        let response = await fetch(`${SERVER_URL}${path}`, {
            method: 'DELETE',
            headers: deleteHeaders
        }).then(response => response.json().catch(err => {
            console.log(err)
        })).catch(error => {
            console.log(error);
        });
    
        return JSON.parse(response[0]);
    }

    signContract(course: Course, callback: any) {
        this.post("sign-contract/", { course_id: course.id }).then((res) => {
            callback({status: 200, msg: "CONTRACT_SIGNED"});
        }, (reason) => {
            callback({status: 500, msg: "ERROR"});
        });
    }

    getCards(callback: any) {
        this.get('payment/cards/client/').then(res => {
            callback(res);
        })
    }

    postCard(card: PaymentCard, callback: any) {
        console.log(card);

        let adaptedCard = {
            "card_name": card.card_name,
            "description": card.description,
            "client_id": card.client_id,
            "number": card.card_number,
            "cvv": card.cvv,
            "exp": `${card.expires.getFullYear()}-${card.expires.getMonth()+1}-${card.expires.getDate()}`,
            "document_id": card.document_id,
            "zipcode": card.zipcode,
            "country": card.country,
            "state": card.state,
            "city": card.city,
            "neighborhood": card.neighborhood,
            "address": card.address,
            "habitation_complement": card.habitation_complement,
            "habitation_number": card.habitation_number,
        }

        console.log(adaptedCard);

        this.post('payment/cards/', adaptedCard).then(res => {
            if (res.message && res.token) {
                callback({ status: "OK", token: res.token });
            } else {
                callback({ status: "ERROR" });
            }
        })
    }

    deleteCard(token, callback: any) {
        this.delete(`payment/cards/${token}`).then(res => {
            callback(res);
        })
    }

    payWithBLT(course: Course, instalments: number, fiscalData: any, callback) {
        let user = stateMachine.getUser();

        if (!user) return;

        let orderBodyBLT = {
            "products": [
                {
                    "name": course.title,
                    "description": course.code,
                    "product_id": course.id,
                    "value": course.price,
                    "currency": "BRL"
                }
            ],
            "client": {
                "name": fiscalData.name,
                "last_name": fiscalData.lastName,
                "email": user.email,
                "document_id": fiscalData.idCPF,
                "zipcode": fiscalData.postalCode,
                "country": "br",
                "address": fiscalData.address,
                "phone": `+55${user.phone}`,
                "habitation_number": 1,
                "habitation_complement": fiscalData.address,
                "client_type_enum": "PERSON"
            },
            "client_id": user.id
        }

        this.post("payment-create-order/", orderBodyBLT).then(res => {
            let body = {
                "invoiceBody": {
                    "order_token": res.token,
                    "client_id": user?.id,
                    "parcel": instalments,
                    "payment_method": "BANK_SLIP"
                },
                "course_id": course.id,
                "fiscal_data": fiscalData
            }

            this.post("payment-create-invoice/", body).then(res => {
                callback(res)
            })
        });
    }

    payWithCard(course: Course, card: PaymentCard, instalments: number, fiscalData: PaymentClient, callback) {
        let user = stateMachine.getUser();
        if (!user) return;

        let orderBody = {
            "products": [
                {
                    "name": course.title,
                    "description": course.code,
                    "product_id": course.id,
                    "value": course.price,
                    "currency": "BRL"
                }
            ],
            "client": {
                "name": card.card_name,
                "last_name": card.card_name,
                "email": user.email,
                "document_id": card.document_id,
                "zipcode": card.zipcode,
                "country": card.country,
                "address": card.address,
                "phone": `+55${user.phone}`,
                "habitation_number": card.habitation_number,
                "habitation_complement": card.habitation_complement,
                "client_type_enum": "PERSON"
            },
            "client_id": user.id
        }

        this.post("payment-create-order/", orderBody).then(res => {
            let body = {
                "invoiceBody": {
                    "order_token": res.token,
                    "card_token": card.card_token,
                    "client_id": user?.id,
                    "parcel": instalments,
                    "payment_method": "CREDIT_CARD"
                },
                "course_id": course.id,
                "fiscal_data": fiscalData
            }

            this.post("payment-create-invoice/", body).then(res => {
                callback(res)
            })
        });
    }
}

let paymentAPIAdapter = new PaymentAPIAdapter();
export default paymentAPIAdapter;
