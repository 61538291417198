import React, { Component } from 'react';
import stateMachine from '../StateMachine';
import http from '../http';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import { StyleSheet, ActivityIndicator, Text, View, Platform, Pressable, StatusBar } from 'react-native';
import {
    Lesson,
    Paragraph,
    Infograph,
    Video,
    Quiz,
    Boolean,
    TextAssociation,
    TextDragAndDrop,
    ExpandableList,
    Timeline,
    Comparison
} from '../content_types';
import {
    ParagraphComponent,
    InfographComponent,
    VideoResourceComponent,
    QuizComponent,
    BooleanComponent,
    TextAssociationComponent,
    TextDragAndDropComponent,
    ExpandableListComponent,
    TimelineComponent,
    ComparisonComponent
} from '../components/resource';
import { ResourceComponent } from '../components/resource';
import { InteractionExplanationComponent, LessonNavigationBar } from '../components';
import PALETTE from '../CFISP_utils/palette';
import { ArrowLeft, ArrowRight } from '../icons';

type LessonScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type LessonScreenRouteProp = RouteProp<RootStackParamList, 'Lesson'>;

export interface Props {
    navigation: LessonScreenNavigationProp,
    route: LessonScreenRouteProp,
}

interface State {
    isLoading: boolean,
    lesson: Lesson,
    resourceIndex: number,
    lessonIndex: number,
    showBottomSubjectNavBar: boolean,
    showExplanationPane: boolean,
    interactionExplanation: string,
}

class LessonScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Lição',
    };

    constructor(props: Props) {
        super(props)
        
        if (!stateMachine.lesson) return;

        this.state = {
            isLoading: true,
            lesson: stateMachine.lesson,
            resourceIndex: 0,
            lessonIndex: stateMachine.lessons.indexOf(stateMachine.lesson),
            showBottomSubjectNavBar: true,
            showExplanationPane: true,
            interactionExplanation: ""
        }
    }

    interactionExplanationComponentRef: InteractionExplanationComponent | null = null;

    componentDidMount() {
        this.fetchResources();
    }

    fetchResources() {
        http.fetchResources(this.state.lesson, () => {
            this.setState({
                ...this.state,
                isLoading: false,
            }, () => {
                this.styleHeader()
            })
        });
    }

    styleHeader() {
        if (this.state.lesson.isInteraction[this.state.resourceIndex]) {
            this.props.navigation.setOptions({
                title: `Lição ${this.state.lessonIndex + 1}`,
                headerStyle: { backgroundColor: PALETTE.gayBlue },
                headerTintColor: PALETTE.background
            });

            if (Platform.OS == 'android') StatusBar.setBackgroundColor(PALETTE.darkBlue);
            StatusBar.setBarStyle('light-content');
        } else {
            this.props.navigation.setOptions({
                title: `Lição ${this.state.lessonIndex + 1}`,
                headerStyle: { backgroundColor: PALETTE.lightGrey },
                headerTintColor: PALETTE.forground
            });
    
            if (Platform.OS == 'android') StatusBar.setBackgroundColor(PALETTE.background);
            StatusBar.setBarStyle('dark-content');
        }
    }

    nextLesson() {
        if (!stateMachine.lesson) return;

        let nl = stateMachine.nextLesson();
        if (!nl) return;

        this.setState({
            isLoading: true,
            lesson: nl,
            resourceIndex: 0,
            lessonIndex: this.state.lessonIndex + 1,
            showBottomSubjectNavBar: true
        }, this.fetchResources);
    }

    previousLesson() {
        if (this.state.lessonIndex == 0) return;

        stateMachine.setLesson(stateMachine.lessons[this.state.lessonIndex - 1])
        if (!stateMachine.lesson) return;
        this.setState({
            ...this.state,
            lesson: stateMachine.lesson,
            lessonIndex: this.state.lessonIndex - 1,
            showBottomSubjectNavBar: true
        }, () => {
            this.fetchResources();
        });
    }

    isLastLesson() {
        if (this.state.lessonIndex + 1 == stateMachine.lessons.length) return true;
        return false;
    }

    goForward() {
        if (this.state.resourceIndex < this.state.lesson.resources.length - 1) {
            this.setState({
                ...this.state,
                resourceIndex: this.state.resourceIndex + 1,
                showBottomSubjectNavBar: true
            }, () => {
                this.styleHeader();
                this.interactionExplanationComponentRef?.hide();
            });
        } else if (this.state.lesson.resources.length - 1 == this.state.resourceIndex) {
            if (this.isLastLesson()) {
                //stateMachine.goToNextSubject();
                this.nextLesson()
            } else {
                this.nextLesson()
            }
        }
    }

    goBackwards() {
        if (this.state.resourceIndex > 0) {
            this.setState({
                ...this.state,
                resourceIndex: this.state.resourceIndex - 1,
                showBottomSubjectNavBar: true
            }, () => {
                this.styleHeader();
                this.interactionExplanationComponentRef?.hide();
            });
        }
    }

    hideBottomSubjectNavBar() {
        this.setState({ ...this.state, showBottomSubjectNavBar: false })
    }

    setExplanationText(explanation: string) {
        this.setState({ ...this.state, interactionExplanation: explanation })
    }

    showExplanationPane() {
        this.interactionExplanationComponentRef?.show();
    }

    hideExplanationPane() {
        this.interactionExplanationComponentRef?.hide();
    }

    render() {
        if (this.state.isLoading) return <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />;
        if (this.state.lesson.resources.length === 0) return (<View><Text>Lição vazia</Text></View>);

        let resource: any;

        switch (this.state.lesson.resources[this.state.resourceIndex].typeName) {
            case "Paragraph":
                resource = <ParagraphComponent paragraph={this.state.lesson.resources[this.state.resourceIndex] as Paragraph} />;
                break;
            case "Infograph":
                resource = <InfographComponent infograph={this.state.lesson.resources[this.state.resourceIndex] as Infograph}/>;
                break;
            case "Video":
                resource = <VideoResourceComponent video={this.state.lesson.resources[this.state.resourceIndex] as Video} />
                break;
            case "Quiz":
                resource = <QuizComponent quiz={this.state.lesson.resources[this.state.resourceIndex] as Quiz} parent={this} />;
                break;
            case "Boolean":
                resource = <BooleanComponent boolean={this.state.lesson.resources[this.state.resourceIndex] as Boolean} parent={this} />;
                break;
            case "TextAssociation":
                resource = <TextAssociationComponent textAssociation={this.state.lesson.resources[this.state.resourceIndex] as TextAssociation} parent={this} />;
                break;
            case "TextDragAndDrop":
                resource = <TextDragAndDropComponent textDragAndDrop={this.state.lesson.resources[this.state.resourceIndex] as TextDragAndDrop} parent={this} />;
                break;
            case "ExpandableList":
                resource = <ExpandableListComponent expandableList={this.state.lesson.resources[this.state.resourceIndex] as ExpandableList} />;
                break;
            case "Timeline":
                resource = <TimelineComponent timeline={this.state.lesson.resources[this.state.resourceIndex] as Timeline} />;
                break;
            case "Comparison":
                resource = <ComparisonComponent comparison={this.state.lesson.resources[this.state.resourceIndex] as Comparison} />;
                break;
            default:
                resource = <Text>Recurso de tipo desconhecido: {this.state.lesson.resources[this.state.resourceIndex].constructor.name}</Text>
        }

        return <View style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            overflow: 'hidden'
        }}>
            <View style={{
                flex: 1,
                backgroundColor: this.state.lesson.isInteraction[this.state.resourceIndex] ? PALETTE.gayBlue : PALETTE.background
            }}>
                <LessonNavigationBar
                    intervals={this.state.lesson.resources.length}
                    location={this.state.resourceIndex}
                    isInteraction={this.state.lesson.isInteraction[this.state.resourceIndex]}
                />
                <ResourceComponent
                    parent={this}
                    navigation={this.props.navigation}
                    resourceIndex={this.state.resourceIndex}
                    resource={resource}
                    lessonIndex={this.state.lessonIndex}
                    isInteraction={this.state.lesson.isInteraction[this.state.resourceIndex]}
                />
            </View>
            { this.state.showBottomSubjectNavBar
                ? <View style={{
                    height: 80,
                }}>
                    { this.state.resourceIndex < this.state.lesson.resources.length - 1
                        ? <View
                            style={{
                                backgroundColor: PALETTE.lightGrey,
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                ...Platform.select({
                                    web: {
                                        cursor: "pointer"
                                    }
                                })
                            }}>
                                <Pressable
                                    onPress={() => {
                                        if (this.state.resourceIndex > 0) {
                                            this.goBackwards();
                                        } else {
                                            this.previousLesson();
                                        }
                                    }}
                                >
                                    <ArrowLeft style={styles.arrow} />
                                </Pressable>
                                <Text>Ver <Text
                                    style={{
                                        color: PALETTE.gayBlue
                                    }}
                                    onPress={() => {
                                        stateMachine.goToThisSubjectList()
                                    }}
                                >lista de lições</Text></Text>
                            </View>
                            <Pressable
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    ...Platform.select({
                                        web: {
                                            cursor: "pointer"
                                        }
                                    })
                                }}
                                onPress={() => {
                                    this.goForward();
                                }}
                            >
                                <Text>Avançar</Text>
                                <ArrowRight style={styles.arrow} />
                            </Pressable>
                        </View>
                        : <View
                            style={{
                                backgroundColor: PALETTE.lightGrey,
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                ...Platform.select({
                                    web: {
                                        cursor: "pointer"
                                    }
                                })
                            }}>
                                <Pressable
                                    onPress={() => {
                                        if (this.state.resourceIndex > 0) {
                                            this.goBackwards();
                                        } else {
                                            this.previousLesson();
                                        }
                                    }}
                                >
                                    <ArrowLeft style={styles.arrow} />
                                </Pressable>
                            </View>
                            <View>
                                { this.isLastLesson()
                                    ? <Pressable
                                        onPress={() => { stateMachine.goToThisSubjectList() }}
                                        style={{
                                            flex: 1,
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                            paddingRight: 22,
                                            ...Platform.select({
                                                web: {
                                                    cursor: "pointer"
                                                }
                                            })
                                        }}
                                    >
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                ...Platform.select({
                                                    web: {
                                                        cursor: "pointer"
                                                    }
                                                })
                                            }}
                                        >
                                            <Text>Fim da disciplina</Text>
                                        </View>
                                    </Pressable>
                                    : <Pressable
                                        onPress={() => { this.nextLesson() }}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            ...Platform.select({
                                                web: {
                                                    cursor: "pointer"
                                                }
                                            })
                                        }}
                                    >
                                        <Text>Próxima lição</Text>
                                        <ArrowRight style={styles.arrow} />
                                    </Pressable>
                                }
                            </View>
                        </View>
                    }
                </View>
                : null }

                <InteractionExplanationComponent
                    ref={ref => { this.interactionExplanationComponentRef = ref }}
                    parent={this}
                    text={ this.state.interactionExplanation }
                />
        </View>
    }
}

const styles = StyleSheet.create({
    footer: {
        backgroundColor: PALETTE.lightGrey,
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    arrow: {
        margin: 20,
        width: 30,
        height: 30
    }
})

export default LessonScreen;
