import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Circle id="prefix__circle993" r={8} cy={8} cx={12.5} fill="#fc0" />
      <Path
        id="prefix__path1588"
        d="M2.547 10.3v11.4c0 .576.43.942.701 1.077.27.136.493.176.752.176h5v-1.906H4.453V10.3H2.547z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.907}
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path1592"
        d="M20.547 10.7v10.347H15.5v1.906H21c.256 0 .528-.025.836-.201.308-.176.617-.635.617-1.053v-11h-1.906z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.907}
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path1580"
        d="M12.512 2.047a.954.954 0 00-.623.22l-9 7.5a.953.953 0 00-.121 1.344.953.953 0 001.343.121l8.385-6.988 8.389 7.084a.953.953 0 001.344-.113.953.953 0 00-.114-1.344l-9-7.6a.954.954 0 00-.603-.224z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.907}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
      <Path
        id="prefix__path1584"
        d="M9.5 15.146a.954.954 0 00-.953.954V23h1.906v-5.947h4.094V23h1.906v-6.9a.954.954 0 00-.953-.954h-6z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.907}
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
