import React, { Component } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, FlatList, Image } from 'react-native';
import http from '../http';
import { Field, Course } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import CourseListItem from '../components/courseListItem';
import stateMachine from '../StateMachine';

export interface Props {
    parent: any,
    field: Field
}

interface State {
    loading: boolean,
    courses: Course[]
}

class CoursesInFieldScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { loading: true, courses: [] };
    }

    componentDidMount() {
        this.setState({ ...this.state, loading: true });

        http.fetchCoursesInField(this.props.field.id, (courses: Course[]) => {
            this.setState({ loading: false, courses: courses });
        })
    }

    render() {
        if (this.state.loading) return <View style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around'
        }}>
            <ActivityIndicator animating={true} size="large" color={ PALETTE.forground } />
        </View>
        else return (
            <View style={styles.root}>
                <View style={styles.header}>
                    <View style={styles.closeIconContainer} onTouchEnd={() => { this.props.parent.clearField() }}>
                        {/* <Image
                            source={require("../../assets/icons/arrow-back.png")}
                            style={styles.closeIcon}
                        /> */}
                    </View>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>Coursos <Text style={styles.bold}>em {this.props.field.name}</Text></Text>
                    </View>
                </View>
                <View style={styles.body}>
                    <FlatList
                        data={this.state.courses}
                        renderItem={({item}) => <CourseListItem navigation={this.props.parent.props.navigation} course={item}/>}
                        keyExtractor={item => `${item.id}`}
                    />
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
        // backgroundColor: "lightgrey",
    },
    header: {
        // flex: 1,
        flexDirection: "row",
        height: 100,
        // backgroundColor: "green",
        // padding: 10
    },
    body: {
        // backgroundColor: "pink",
        flex: 1
    },
    closeIconContainer: {
        flex: 1,
        // backgroundColor: "yellow",
    },
    closeIcon: {
        color: PALETTE.darkGray,
        // backgroundColor: 'blue',
        marginLeft: 20,
        height: '100%',
        textAlignVertical: 'center'
    },
    titleContainer: {
        flex: 4,
        // backgroundColor: "red"
    },
    title: {
        // backgroundColor: "purple",
        color: PALETTE.darkGray,
        height: '100%',
        textAlignVertical: 'center',
        fontSize: 28
    },
    bold: {
        fontWeight: 'bold'
    }
})

export default CoursesInFieldScreen;
