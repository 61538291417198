import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, TextInput, Pressable, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import MonthPicker from 'react-native-month-year-picker';
import TextInputMask from 'react-native-text-input-mask';
import { CenterButton } from '../../components'
import { isValidCPF, formatPriceInBRL } from '../../CFISP_utils/functions';
import paymentAPIAdapter from '../../http/payment'
import { PaymentCard } from '../../payment_types';
import stateMachine from '../../StateMachine';

interface Props {
    mode: string
}

interface State {
    waiting: boolean
}

export default class PaymentConfirmationMode extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { waiting: false }
    }

    render() {
        if (this.state.waiting) return (
            <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        );

        return (
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_k,
                padding: 10
            }}>
                <Text>{this.props.mode === "BLT" ? "Boleto" :
                    stateMachine.paymentCard ? stateMachine.paymentCard.card_number : "" 
                }</Text>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    }
});
