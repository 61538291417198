import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { Image, View, Text, StyleSheet, SafeAreaView } from 'react-native';
import { RootStackParamList, Tutorial, Slide } from '../types';
import { PanGestureHandler, Directions, State } from 'react-native-gesture-handler';
import TutorialSlide from './tutorialSlide';
import stateMachine from '../StateMachine';

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;
interface Route {
    params: { tutorial: Tutorial }
}

export interface Props {
    navigation: ProfileScreenNavigationProp
    route: Route
}

export interface TutorialScreenState {
    showing: Slide
}

export default class TutorialScreen extends Component<Props, TutorialScreenState> {
    constructor(props: Props) {
        super(props);
        this.state = { showing: props.route.params.tutorial.slides[0] }
    }

    next() {
        let i = this.props.route.params.tutorial.slides.indexOf(this.state.showing);

        if ( i < this.props.route.params.tutorial.slides.length - 1 ) {
            this.setState({ showing: this.props.route.params.tutorial.slides[ i + 1 ] })
        } else {
            // console.log("Sorry, that's the LAST slide.");
            stateMachine.setHaveSeenWelcomeTutorial();
        }
    }

    prev() {
        let i = this.props.route.params.tutorial.slides.indexOf(this.state.showing);

        if ( i > 0) {
            this.setState({ showing: this.props.route.params.tutorial.slides[ i - 1 ] })
        } else {
            // console.log("Sorry, that's the FIRST slide.");
        }
    }

    render() {
        return <TutorialSlide slide={this.state.showing} parent={this}></TutorialSlide>
    }

    exploreAnonymously() {
        stateMachine.setHaveSeenWelcomeTutorial();
    }
    
    goToLogin() {
        stateMachine.setHaveSeenWelcomeTutorial();
        stateMachine.goToLogin();
    }
}

const styles = StyleSheet.create({});
