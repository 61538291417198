
import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform, Pressable } from 'react-native';
import { Option } from '../../content_types/quizOption';
import QuizComponent from './quizComponent';
import PALETTE from '../../CFISP_utils/palette';
import { Correct, Wrong } from '../../icons';

enum ButtonState {
    normal,
    selected,
    correct,
    wrong
}

type OptionButton = {
    option: Option;
    state: ButtonState;
}

interface Props {
    option: OptionButton;
    list: QuizComponent;
};

class QuizOptionComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    chooseStyle() {
        switch (this.props.option.state) {
            case ButtonState.normal:
                return styles.normal;
            case ButtonState.selected:
                return styles.selected;
            case ButtonState.correct:
                return styles.correct;
            case ButtonState.wrong:
                return styles.wrong;
        }
    }

    showIcon(state: ButtonState) {
        switch (state) {
            case ButtonState.correct:
                return <Correct style={styles.resultIcon} />;
            case ButtonState.wrong:
                return <Wrong style={styles.resultIcon} />;
            default:
                return null;
        }
    }

    render() {
        return (
            <Pressable
                style={[styles.itemContainer, this.chooseStyle()]}
                onPress={() => {this.props.list.select(this.props.option)}}>
                <Text style={styles.text}>
                    { this.props.option.option.text }
                </Text>
                { this.showIcon(this.props.option.state) }
            </Pressable>
        )
    }
}

const styles = StyleSheet.create({
    text: {
        margin: 20,
        fontSize: 18
    },
    itemContainer: {
        marginTop: 12,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    button: {
        flex: 7,
        marginTop: 10,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.background,
        borderColor: PALETTE.lightBlue,
    },
    selected: {
        backgroundColor: PALETTE.gray_m,
        borderColor: PALETTE.gray_m
    },
    correct: {
        backgroundColor: PALETTE.lightGayGreen,
        borderColor: PALETTE.anotherGreen
    },
    wrong: {
        backgroundColor: PALETTE.lightRed,
        borderColor: PALETTE.red
    },
    resultIcon: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: -5,
        top: -5
    }
})

export { QuizOptionComponent, ButtonState, OptionButton };
