import React, { Component } from 'react';
import {
    StyleSheet,
    StatusBar,
    Text,
    View,
    ScrollView,
    TextInput,
    Platform,
    Pressable,
    TouchableOpacity,
    Alert,
} from 'react-native';
import http from '../http';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import PALETTE from '../CFISP_utils/palette';

import { NoIcon, Eye, EyeBarred } from '../icons';
import { LoginIllustration } from '../illustrations';

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: ProfileScreenNavigationProp,
}

interface State {
    email: string,
    password: string,
    hidePassword: boolean,
}

const iconSize = 35

class LoginScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Entrar'
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            hidePassword: true,
        }
    }

    login() {
        http.sendCredentials(this.state.email, this.state.password, () => {
            this.props.navigation.navigate("Tabs");
        })
    }

    setEmail(email: string) {
        this.setState({ ...this.state, email })
    }

    setPassword(password: string) {
        this.setState({ ...this.state, password })
    }

    render() {
        if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.darkBlue);
        StatusBar.setBarStyle('light-content');

        return (
            <ScrollView style={styles.root}>
                <View style={styles.imageContainer}>
                    <LoginIllustration
                        width={233}
                        height={225}
                        style={styles.illustration}
                    />
                </View>
                <View style={styles.otherThingsContainer}>
                    <TextInput
                        style={styles.input}
                        onChangeText={email => this.setEmail(email)}
                        value={ this.state.email }
                        placeholder={"Email"}
                        autoCapitalize="none"
                    />
                    <View style={styles.passwordContainer}>
                        <TextInput
                            style={styles.passwordInput}
                            onChangeText={password => this.setPassword(password)}
                            value={ this.state.password }
                            placeholder={"Senha"}
                            textContentType="password"
                            secureTextEntry={this.state.hidePassword}
                        />
                        <Pressable
                            onPressIn={() => {this.setState({ ...this.state, hidePassword: false })}}
                            onPressOut={() => {this.setState({ ...this.state, hidePassword: true })}}
                        >
                            { this.state.hidePassword
                                ? <EyeBarred width={iconSize} height={iconSize} />
                                : <Eye width={iconSize} height={iconSize} />
                            }
                        </Pressable>
                    </View>
                </View>

                <View style={styles.sendButtonContainer}>
                    <TouchableOpacity
                        style={styles.sendButton}
                        onPress={()=>{this.login()}}
                        activeOpacity={0.6}
                    >
                        <Text style={styles.sendButtonText}>Entrar</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.registerLinkContainer}>
                    <Text style={styles.registerLinkText}>Ainda não tem uma conta? <Text style={styles.link} onPress={() => {this.props.navigation.navigate('Register')}}>Cadastre-se</Text></Text>
                </View>
            </ScrollView>
        );
    }
};

// styles
const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.background,
        paddingHorizontal: 30,
        flex: 1,
        flexDirection: 'column',
    },
    imageContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    illustration: {
        width: 233,
        height: 225,
        marginTop: 30,
        marginBottom: -40
    },
    otherThingsContainer: {},
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    },
    registerLinkContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 50
    },
    registerLinkText: {
        fontSize: 18,
        textAlign: 'center'
    },
    link: {
        color: PALETTE.gayBlue,
    },
    sendButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    sendButton: {
        height: 50,
        backgroundColor: PALETTE.lightGreen,
        width: '60%',
        padding: 12,
        borderRadius: 50,
        marginBottom: 20,
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    sendButtonText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 20
    },
    legalNotice: {
        flex: 1,
        flexDirection: 'row'
    },
    legalNoticeText: {
        textAlign: 'center'
    },
    legalNoticeLink: {
        color: PALETTE.gayBlue
    },
    passwordContainer: {
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 20,
        fontSize: 20,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    eyeIcon: {
        flex: 1,
        color: PALETTE.gray_g,
        alignItems: 'flex-end',
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    passwordInput: {
        height: 50,
        backgroundColor: PALETTE.background,
        flex: 9,
        fontSize: 20
    }
});

export default LoginScreen;
