class EvaluationAttempt {
    id: number;
    startTimestamp: string;
    endTimestamp: string;
    grade: number;

    constructor(
        id: number,
        startTimestamp: string,
        endTimestamp: string,
        grade: number,
    ) {
        this.id = id;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.grade = grade;
    }
}

export default EvaluationAttempt;
