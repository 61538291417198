import * as React from "react"
import Svg, { Defs, Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <Svg viewBox="0 0 20.686 20.953" height={20.953} width={20.686} {...props}>
            <Defs></Defs>
            <Path
                d="M.389 8.254v11.398c0 .577.432.943.703 1.078.27.136.493.176.752.176h5V19H2.297V8.254H.389zM18.389 8.652V19h-5.045v1.906h5.5c.256 0 .525-.025.834-.201.308-.176.619-.635.619-1.053v-11h-1.908z"
                fill="#444444"
            />
            <Path
                d="M10.355 0a.954.954 0 00-.623.221l-9 7.5a.953.953 0 00-.121 1.342.953.953 0 001.342.123l8.387-6.989 8.389 7.084a.953.953 0 001.343-.113.953.953 0 00-.113-1.344l-9-7.599A.954.954 0 0010.355 0zM7.344 13.1a.954.954 0 00-.955.953v6.9h1.908v-5.947h4.092v5.947h1.908v-6.9a.954.954 0 00-.953-.953h-6z"
                fill="#444444"
            />
        </Svg>
    )
}

export default SvgComponent
