import React, { Component } from 'react';
import { StyleSheet, Dimensions, View, Text } from 'react-native';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';
import { Timeline } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import TimelineItemComponent from './timelineItemComponent';
import ImageFullWidth from '../ImageFullWidth';

const win = Dimensions.get('window');

type Props = {
    timeline: Timeline
};

type State = {
    expandedItem: number;
    loading: boolean;
}

class TimelineComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { expandedItem: 0, loading: true };
        props.timeline.consumeItem(0);
    }

    setExpandedItem(i: number) {
        if (this.state.expandedItem == i) {
            this.setState({ expandedItem: -1 });
        } else {
            this.props.timeline.consumeItem(i);
            this.setState({ expandedItem: i });
        }
    }

    render() {
        let items = this.props.timeline.items.map((item, index) => {
            return (
                <TimelineItemComponent
                    item={item}
                    startExpanded={index == 0}
                    list={this}
                    index={index}
                    key={index}
                ></TimelineItemComponent>);
        })

        return (
            <View style={styles.root}>
                <Text style={styles.title}>
                    { this.props.timeline.title }
                </Text>
                <View style={styles.ilustrationContainer}>
                    <ImageFullWidth imageURI={`${S3_IMAGES}${this.props.timeline.ilustration}`} />
                </View>
                <Text style={styles.text}>{this.props.timeline.text}</Text>
                {items}
            </View>
        )
    }
}

const ilustrationMargin = 1;

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.lightBlue
    },
    title: {
        fontSize: 22,
        textAlign: 'center',
        marginTop: 16,
        marginBottom: 18
    },
    ilustrationContainer: {
        backgroundColor: PALETTE.lightGrey,
        paddingHorizontal: ilustrationMargin
    },
    text: {
        margin: 18,
        fontSize: 18
    }
})

export default TimelineComponent;
