import React, { Component } from 'react';
import { StyleSheet, View, Text, Pressable, Animated, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import stateMachine from '../StateMachine';
import { CenterButton } from '../components';
import Markdown from 'react-native-markdown-display'
import { Cross } from '../icons';

interface Props {
    parent: any,
    text: string,
}

interface State {
    paneHeight: number,
    paneBottomPosition: Animated.Value
}

export default class ResourceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { paneHeight: 0, paneBottomPosition: new Animated.Value(-900) }
    }

    setPaneHeight: boolean = true;
    h: any;

    public show() {
        this.setPaneHeight = true;
        Animated.timing(
            this.state.paneBottomPosition,
            {
                toValue: 0,
                duration: 200,
                useNativeDriver: false
            }
        ).start();
    }

    hide() {
        this.setPaneHeight = true;
        Animated.timing(
            this.state.paneBottomPosition,
            {
                toValue: -this.state.paneHeight - 300,
                duration: 200,
                useNativeDriver: false
            }
        ).start();
    }

    render() {
        return <Animated.View style={[
            styles.pane,
            { left: 0 },
            { bottom: this.state.paneBottomPosition }
        ]}
            onLayout={(event) => {
                if (this.setPaneHeight) {
                    this.setPaneHeight = false;
                    this.setState({ ...this.state, paneHeight: event.nativeEvent.layout.height })
                }
            }}
        >
            <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }}>
                <Pressable
                    onPress={() => {
                        this.hide();
                    }}
                    style={{
                        padding: 6,
                        ...Platform.select({
                            web: {
                                cursor: "pointer"
                            }
                        })
                    }}
                >
                    <Cross style={{ width: 20, height: 20 }} />
                </Pressable>
            </View>
            <Markdown>
                { this.props.text }
            </Markdown>

            <CenterButton
                text="Avançar"
                action={() => {
                    if (this.props.parent.isLastLesson()) {
                        this.props.parent.nextLesson();
                    } else {
                        this.props.parent.goForward();
                    }
                }}
                color={ PALETTE.gayGreen }
                paddingVertical={8}
                paddingHorizontal={60}
                width="80%"
                marginTop={16}
            />
            <Text style={[styles.text, { marginTop: 16, textAlign: 'center' }]}>
                Voltar para <Text
                    style={{
                        color: PALETTE.gayBlue
                    }}
                    onPress={() => { stateMachine.goToThisSubjectList() }}
                >lista de lições</Text>
            </Text>
        </Animated.View>
    }
}

const styles = StyleSheet.create({
    pane: {
        backgroundColor: PALETTE.background,
        width: "100%",
        position: 'absolute',
        padding: 20,
    },
    text: {
        fontSize: 16,
        marginBottom: 10,
    },
});
