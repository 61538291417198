import React, { Component } from 'react';
import { StyleSheet, View, Text, Button, Alert, Image } from 'react-native';
import stateMachine from '../../StateMachine';
import { Quiz } from '../../content_types';
import { Option } from '../../content_types/quizOption';
import { CenterButton } from '../../components';
import { QuizOptionComponent, ButtonState, OptionButton } from './quizOptionComponent';
import shuffle from '../../CFISP_utils/shuffle';
import PALETTE from '../../CFISP_utils/palette';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

type Props = {
    quiz: Quiz,
    parent: any
};

type State = {
    selected: boolean,
    options: OptionButton[],
    sent: boolean,
    showMustChooseWarning: boolean,
    showExplanationPaneButton: boolean
};

class QuizComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        let opts: OptionButton[] = [];
        opts.push({
            option: { text: props.quiz.correctOption, correct: true, selected: false },
            state: ButtonState.normal
        });

        for (let o of props.quiz.falseOptions) {
            opts.push({
                option: { text: o, correct: false, selected: false },
                state: ButtonState.normal
            })
        }

        shuffle(opts);

        let showExplanationPaneButton: boolean = false;
        this.props.parent.setExplanationText(this.props.quiz.explanation);
        if (this.props.quiz.explanation.length > 0) {
            showExplanationPaneButton = true;
        }

        this.state = { selected: false, options: opts, sent: false, showMustChooseWarning: false, showExplanationPaneButton }
    }

    select(item: OptionButton) {
        let options = this.state.options;

        for (let i=0; i<options.length; i++) {
            options[i].state = ButtonState.normal;
            options[i].option.selected = false;
        }

        options[options.indexOf(item)].state = ButtonState.selected;
        options[options.indexOf(item)].option.selected = true;
        this.setState({ ...this.state, options, selected: true, showMustChooseWarning: false });
    }

    send() {
        if (this.state.selected) {
            let options = this.state.options;

            for (let i=0; i<options.length; i++) {
                if (options[i].option.selected) {
                    this.props.quiz.consume();
                    if (options[i].option.correct) {
                        options[i].state = ButtonState.correct;
                    } else {
                        options[i].state = ButtonState.wrong;
                    }
                }
            }

            this.setState({ ...this.state, options, sent: true });
        } else {
            this.setState({ ...this.state, showMustChooseWarning: true });
        }
    }

    renderOptions() {
        return this.state.options.map((item, index) => {
            return (
                <QuizOptionComponent option={item} list={this} key={index}/>
            )
        })
    }

    render() {
        return (
            <View style={styles.quizView}>
                <View style={styles.outerCircle}>
                    <View style={styles.midCircle}>
                        <View style={styles.innerCircle}></View>
                    </View>
                </View>
                <Text style={styles.title}>QUIZ</Text>

                <Markdown rules={ MarkdownCustomRules } style={{ body: styles.text }}>
                    { this.props.quiz.instructions }
                </Markdown>
                
                { this.renderOptions() }

                { this.state.sent ? <View style={{
                    marginTop: 30
                }}>
                    {
                        this.state.showExplanationPaneButton ? <CenterButton
                            text="Ver gabarito"
                            action={() => {
                                this.props.parent.showExplanationPane();
                            }}
                            color={ PALETTE.gayBlue }
                            paddingVertical={8}
                            paddingHorizontal={60}
                            width="80%"
                        /> : null
                    }
                    <CenterButton
                        text="Avançar"
                        action={() => {
                            this.props.parent.goForward();
                        }}
                        color={ PALETTE.gayGreen }
                        paddingVertical={8}
                        paddingHorizontal={60}
                        width="80%"
                        marginTop={16}
                    />
                    <Text style={[styles.text, { marginTop: 16, textAlign: 'center' }]}>
                        Voltar para <Text
                            style={{
                                color: PALETTE.gayBlue
                            }}
                            onPress={() => { stateMachine.goToThisSubjectList() }}
                        >lista de lições</Text>
                    </Text>
                </View> : <View style={{
                    marginTop: 30
                }}>
                    { this.state.showMustChooseWarning ? <Text style={[{ marginBottom: 5, color: PALETTE.red, textAlign: 'center' }]}>
                        Escolha uma opção
                    </Text> : null }
                    <CenterButton
                        text="Enviar"
                        action={() => { this.send() }}
                        color={ this.state.selected ? PALETTE.gayBlue : PALETTE.lightGayBlue }
                        paddingVertical={8}
                        paddingHorizontal={60}
                    />
                </View> }
            </View>
        )
    }
}

const checkRadius = 45;
const padding = 22;
const iconRadius = 36;

const styles = StyleSheet.create({
    quizView: {
        backgroundColor: PALETTE.almostWhite,
        margin: 24,
        marginBottom: 24+checkRadius,
        borderRadius: 20,
        padding: padding,
    },
    innerCircle: {
        backgroundColor: 'white',
        width: iconRadius/2,
        height: iconRadius/2,
        alignSelf: 'center',
        borderRadius: 100,
        marginTop: iconRadius*1.3/2 - iconRadius/4
    },
    midCircle: {
        backgroundColor: PALETTE.darkBlue,
        width: iconRadius*1.3,
        height: iconRadius*1.3,
        alignSelf: 'center',
        borderRadius: 100,
        marginTop: iconRadius-iconRadius*1.3/2
    },
    outerCircle: {
        backgroundColor: PALETTE.blue,
        width: iconRadius*2,
        height: iconRadius*2,
        alignSelf: 'center',
        borderRadius: 100,
        marginBottom: 10
    },
    title: {
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 10
    },
    text: {
        fontSize: 16,
        marginBottom: 10
    },
    check: {
        width: checkRadius*2,
        height: checkRadius*2,
        position: 'relative',
        bottom: -checkRadius - padding,
        alignSelf: 'center'
    }
})

export default QuizComponent;
