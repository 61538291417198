import React, { Component } from 'react';
import { View, ActivityIndicator } from 'react-native';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';
import SubjectListItem from '../components/subjectListItem';
import http from '../http';
import PALETTE from '../CFISP_utils/palette';

type Props = {
    navigation: any,
    course: Course
}

type State = { isLoading: boolean }

export default class SubjectList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { isLoading: true }
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        http.fetchSubjectsInCourse(
            this.props.course.id,
            () => this.setState({ isLoading: false })
        );
    }

    renderSubjectList() {
        return stateMachine.subjects.map((item, index) => {
            return <SubjectListItem
                key={index}
                navigation={this.props.navigation}
                subject={item}
                isLast={ index == stateMachine.subjects.length - 1 }
            />
        })
    }

    render() {
        return <View style={{ padding: 20 }}>
            <View>
                { this.state.isLoading ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> : this.renderSubjectList() }
            </View>
        </View>;
    }
};
