import { StyleSheet, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

const styles = StyleSheet.create({
    root: {
        // alignItems: 'center',
        // alignSelf: 'stretch'
    },
    header: {
        backgroundColor: PALETTE.yellow,
        paddingBottom: 50,
        padding: 20,
    },
    icon: {
        marginVertical: 20,
        marginHorizontal: 0,
        width: 30,
        height: 30,
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    loginContainer: {
        alignSelf: 'flex-end',
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    loginText: {
        fontSize: 20
    },
    username: {
        fontSize: 50,
    },
    text: {
        fontSize: 20,
        marginTop: 10,
    },
    courses: {
        marginTop: -30,
        paddingTop: 20,
        paddingLeft: 12,
        borderRadius: 40,
        backgroundColor: PALETTE.almostWhite_b
    },
    headerCourses: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    titleCourse: {
        fontSize: 20,
        margin: 10,
    },
    link: {
        marginRight: 20,
        fontSize: 18,
        padding: 10,
        color: '#8b8b8a',
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    buttons: {
        flexDirection: 'row',
        minHeight: 70,
        alignItems: 'stretch',
        alignSelf: 'center',
        borderWidth: 5,
    },
    button: {
        flex: 1,
        paddingVertical: 0,
    },
    separator: {
        textAlign: 'center'
    }
});

export { styles }
