import Resource from './resource';
import ComparisonItem from './comparisonItem';

class Comparison extends Resource {
    typeName: string = "Comparison";
    leftTitle: string;
    rightTitle: string;
    leftIllustration: string;
    rightIllustration: string;
    items: ComparisonItem[];

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        leftTitle: string,
        rightTitle: string,
        leftIllustration: string,
        rightIllustration: string,
    ) {
        super(id, pub_date, consumed);
        this.leftTitle = leftTitle;
        this.rightTitle = rightTitle;
        this.leftIllustration = leftIllustration;
        this.rightIllustration = rightIllustration;
        this.items = [];
    }

    setItems(items: ComparisonItem[]) {
        this.items = items;
    }

    consume() {
        super.consume();
    }
}

export default Comparison;
