import Interaction from './interaction';

class Quiz extends Interaction {
    typeName: string = "Quiz";
    instructions: string;
    correctOption: string;
    falseOptions: string[];

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string,
        instructions: string,
        correctOption: string,
        falseOptions: string[]
    ) {
        super(id, pub_date, consumed, points, explanation)
        this.instructions = instructions
        this.correctOption = correctOption;
        this.falseOptions = falseOptions;
    }
}

export default Quiz;
