import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, Pressable, Platform } from 'react-native';
import CourseHeading from '../../components/courseHeading';
import { formatPriceInBRL, multiplatformAlert } from '../../CFISP_utils/functions'
import PALETTE from '../../CFISP_utils/palette';
import { PaymentCard } from '../../payment_types';
import { AddCardFormComponent } from '../../components/paymentComponents';
import stateMachine from '../../StateMachine';
import { User } from '../../user';
import { Course } from '../../content_types';
import Markdown from 'react-native-markdown-display';
import { SERVER_URL } from '../../CFISP_utils/environmentConstants';
import { dummyContract } from '../../CFISP_utils/dummyContract';
import paymentAPIAdapter from '../../http/payment';

type ContractScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type ContractScreenRouteProp = RouteProp<RootStackParamList, 'PaymentContract'>;

interface Props {
    navigation: ContractScreenNavigationProp,
    route: ContractScreenRouteProp
}

interface State {
    isLoading: boolean,
    showAddCreditCardForm: boolean,
    cards: PaymentCard[],
    canEnlist: boolean,
    waiting: boolean,
    contract: string,
    waitingSignatureConfirmation: boolean
}

export default class PaymentContractScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Contrato',
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            isLoading: true,
            showAddCreditCardForm: false,
            cards: [],
            canEnlist: false,
            waiting: true,
            contract: "",
            waitingSignatureConfirmation: false
        }
    }

    user: User | null = null;
    course: Course | null = null;

    componentDidMount() {
        this.user = stateMachine.getUser();
        this.course = stateMachine.getCourse();

        if (this.user) {
            if (this.user.canEnlist()) {
                if (SERVER_URL != "https://cfisp.com.br/") {
                    this.fillContract(dummyContract);
                } else {
                    fetch("https://cfisp-static-files.s3-sa-east-1.amazonaws.com/contracts/contract_PT.md")
                        .then((response) => response.text())
                        .then((contract) => {
                            this.fillContract(contract)
                        })
                        .catch((error) => console.error(error))
                }
            } else {
                this.setState({ ...this.state, canEnlist: false, waiting: false })
            }
        }
    }

    fillContract(contract: string) {
        if (!this.user) return;
        if (!this.course) return;

        // User data
        contract = contract.replace(/VAR_STUDENT_FULL_NAME/g, this.user.fullName);
        contract = contract.replace(/VAR_STUDENT_CPF/g, this.user.idCPF);
        contract = contract.replace(/VAR_STUDENT_ADDRESS/g, this.user.address);
        
        // Course data
        contract = contract.replace(/VAR_COURSE_NAME/g, this.course.title);
        contract = contract.replace(/VAR_COURSE_CODE/g, this.course.code);

        this.setState({ ...this.state, waiting: false, canEnlist: true, contract })
    }

    render () {
        if (this.state.waiting) return (
            <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        );

        if (this.state.canEnlist) return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <View>
                        <Markdown>
                            { this.state.contract }
                        </Markdown>
                        <Pressable
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "center",
                                marginVertical: 40,
                                ...Platform.select({"web": { cursor: "pointer" }})
                            }}
                            onPress={() => {
                                this.setState({ ...this.state, waitingSignatureConfirmation: true })

                                paymentAPIAdapter.signContract(this.props.route.params.course, res => {
                                    if (res.msg === "CONTRACT_SIGNED") {
                                        this.props.navigation.navigate("PaymentMode", { course: this.props.route.params.course })
                                    } else {
                                        multiplatformAlert("ERROR");
                                        this.props.navigation.navigate("Home")
                                    }
                                })
                            }}
                            disabled={this.state.waitingSignatureConfirmation}
                        >
                            {
                                this.state.waitingSignatureConfirmation ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> :
                                <Text
                                    style={{
                                        backgroundColor: PALETTE.blue,
                                        color: "white",
                                        padding: 20,
                                        borderRadius: 20
                                    }}
                                >
                                    Assinar contrato
                                </Text>
                            }
                        </Pressable>
                    </View>
                </ScrollView>
            </SafeAreaView>
        );

        return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <View>
                        <Text style={{ fontSize: 23, fontWeight: 'bold', marginBottom: 20, textAlign: "center" }}>Cadastro incompleto</Text>
                        <Text style={{ fontSize: 20, marginBottom: 20, textAlign: "center" }}>Entre em contato conosco para completar seu cadastro.</Text>
                        <Text style={{ fontSize: 20, color: PALETTE.blue, textAlign: "center" }}>contato@cfisp.com.br</Text>
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}
