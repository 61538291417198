import React, { Component } from 'react';
import { Image, View, Text, StyleSheet, SafeAreaView } from 'react-native';
import { Slide } from '../types';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { CFISPLogo } from '../icons';
import { CenterButton } from '../components';
//import { Slide0, Slide1, Slide2 } from '../tutorialSlides';
import PALETTE from '../CFISP_utils/palette';
import Slide0 from '../../assets/slides/1-no-seu-bolso.svg';
import Slide1 from '../../assets/slides/2-interatividade.svg';
import Slide2 from '../../assets/slides/3-seu-proprio-caminho.svg';
import Slide3 from '../../assets/slides/4-disciplinas.svg';
import Slide4 from '../../assets/slides/5-meus-cursos.svg';

export interface Props {
    slide: Slide,
    parent: any
}

export default class TutorialSlide extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    onHandlerStateChange = event => {
        if (event.nativeEvent.oldState === State.ACTIVE) {
            if (event.nativeEvent.translationX < 0) this.props.parent.next()
            else this.props.parent.prev()
        }
    };

    render() {
        if (this.props.slide.ilustration == 0) return <PanGestureHandler onHandlerStateChange={this.onHandlerStateChange}>
            <View style={containers.root}>
                <View style={containers.logo}>
                    <CFISPLogo height={90} style={styles.ilustration} />
                </View>
                <View style={containers.title}>
                    <Text style={styles.title}>A faculdade com você no seu bolso</Text>
                </View>
                <View style={containers.text}>
                    <Text style={styles.text}>Aprenda em qualquer lugar e a qualquer hora!</Text>
                </View>
                <View style={containers.illustration}>
                    <Slide0 width={200} height={200} style={styles.ilustration}/>
                </View>
                <View style={containers.dots}>
                    <View style={styles.dotsContainer}>
                        <View style={[styles.dot, styles.activeDot]}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                    </View>
                </View>
                
                <View style={containers.button}>
                    <CenterButton color={PALETTE.blue} text='Conhecer' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.exploreAnonymously();
                    }} />
                </View>
                <View style={containers.button}>
                    <CenterButton color={PALETTE.anotherGreen} text='Entrar' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.goToLogin();
                    }} />
                </View>
            </View>
        </PanGestureHandler>

        if (this.props.slide.ilustration == 1) return <PanGestureHandler onHandlerStateChange={this.onHandlerStateChange}>
            <View style={containers.root}>
                <View style={containers.logo}>
                    <CFISPLogo height={90} style={styles.ilustration} />
                </View>
                <View style={containers.title}>
                    <Text style={styles.title}>Interatividade</Text>
                </View>
                <View style={containers.text}>
                    <Text style={styles.text}>Com nossa metodologia inovadora e exclusiva, estude de maneira interativa, dinâmica e prazerosa.</Text>
                </View>
                <View style={containers.illustration}>
                    <Slide1 width={200} height={200} style={styles.ilustration}/>
                </View>
                <View style={containers.dots}>
                    <View style={styles.dotsContainer}>
                        <View style={styles.dot}></View>
                        <View style={[styles.dot, styles.activeDot]}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                    </View>
                </View>
                
                <View style={containers.button}>
                    <CenterButton color={PALETTE.blue} text='Conhecer' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.exploreAnonymously();
                    }} />
                </View>
                <View style={containers.button}>
                    <CenterButton color={PALETTE.anotherGreen} text='Entrar' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.goToLogin();
                    }} />
                </View>
            </View>
        </PanGestureHandler>
        
        if (this.props.slide.ilustration == 2) return <PanGestureHandler onHandlerStateChange={this.onHandlerStateChange}>
            <View style={containers.root}>
                <View style={containers.logo}>
                    <CFISPLogo height={90} style={styles.ilustration} />
                </View>
                <View style={containers.title}>
                    <Text style={styles.title}>Faça seu próprio caminho</Text>
                </View>
                <View style={containers.text}>
                    <Text style={styles.text}>Explore as lições a partir do começo, do meio ou do fim, como desejar. Cada disciplina atenderá a sua forma preferida de aprender!</Text>
                </View>
                <View style={containers.illustration}>
                    <Slide2 width={150} height={150} style={styles.ilustration}/>
                </View>
                <View style={containers.dots}>
                    <View style={styles.dotsContainer}>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={[styles.dot, styles.activeDot]}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                    </View>
                </View>
                
                <View style={containers.button}>
                    <CenterButton color={PALETTE.blue} text='Conhecer' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.exploreAnonymously();
                    }} />
                </View>
                <View style={containers.button}>
                    <CenterButton color={PALETTE.anotherGreen} text='Entrar' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.goToLogin();
                    }} />
                </View>
            </View>
        </PanGestureHandler>

        if (this.props.slide.ilustration == 3) return <PanGestureHandler onHandlerStateChange={this.onHandlerStateChange}>
            <View style={containers.root}>
                <View style={containers.logo}>
                    <CFISPLogo height={90} style={styles.ilustration} />
                </View>
                <View style={containers.title}>
                    <Text style={styles.title}>Nossas Disciplinas</Text>
                </View>
                <View style={containers.text}>
                    <Text style={styles.text}>Cada disciplina apresenta LIÇÕES, REVISÃO e  AVALIAÇÃO FINAL. Complete todas as fases para subir de nível no seu conhecimento!</Text>
                </View>
                <View style={containers.illustration}>
                    <Slide3 width={150} height={150} style={styles.ilustration}/>
                </View>
                <View style={containers.dots}>
                    <View style={styles.dotsContainer}>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={[styles.dot, styles.activeDot]}></View>
                        <View style={styles.dot}></View>
                    </View>
                </View>
                
                <View style={containers.button}>
                    <CenterButton color={PALETTE.blue} text='Conhecer' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.exploreAnonymously();
                    }} />
                </View>
                <View style={containers.button}>
                    <CenterButton color={PALETTE.anotherGreen} text='Entrar' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.goToLogin();
                    }} />
                </View>
            </View>
        </PanGestureHandler>

        if (this.props.slide.ilustration == 4) return <PanGestureHandler onHandlerStateChange={this.onHandlerStateChange}>
            <View style={containers.root}>
                <View style={containers.logo}>
                    <CFISPLogo height={90} style={styles.ilustration} />
                </View>
                <View style={containers.title}>
                    <Text style={styles.title}>Encontre seus cursos</Text>
                </View>
                <View style={containers.text}>
                    <Text style={styles.text}>Antes de começar, entre com seu login e senha. Assim, seu curso já estará disponível em “Meus Cursos”! Depois, é só entrar e começar a aprender!</Text>
                </View>
                <View style={containers.illustration}>
                    <Slide4 width={150} height={150} style={styles.ilustration}/>
                </View>
                <View style={containers.dots}>
                    <View style={styles.dotsContainer}>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={styles.dot}></View>
                        <View style={[styles.dot, styles.activeDot]}></View>
                    </View>
                </View>
                
                <View style={containers.button}>
                    <CenterButton color={PALETTE.blue} text='Conhecer' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.exploreAnonymously();
                    }} />
                </View>
                <View style={containers.button}>
                    <CenterButton color={PALETTE.anotherGreen} text='Entrar' width="60%" paddingVertical={1} action={() => {
                        this.props.parent.goToLogin();
                    }} />
                </View>
            </View>
        </PanGestureHandler>
    }
}

const containers = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.background,
        flex: 1,
        flexDirection: 'column',
        padding: 20,
        paddingBottom: 40
    },
    logo: {
        flex: 2,
        justifyContent: 'space-around',
        alignContent: 'center'
    },
    title: {
        flex: 1,
        justifyContent: 'space-around',
        alignContent: 'center'
    },
    text: {
        flex: 1,
        justifyContent: 'space-around',
        alignContent: 'center'
    },
    illustration: {
        flex: 4,
        justifyContent: 'space-around',
        alignContent: 'center'
    },
    dots: {
        flex: 1,
        flexDirection: 'column',
    },
    button: {
        flex: 1,
        paddingBottom: 20
    },
});

const styles = StyleSheet.create({
    ilustration: {
        alignSelf: 'center'
    },
    dotsContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignSelf: 'center'
    },
    dot: {
        borderRadius: 8,
        backgroundColor: 'lightgrey',
        width: 15,
        height: 15,
        marginHorizontal: 16,
        alignSelf: 'center'
    },
    activeDot: {
        backgroundColor: 'grey',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
        color: PALETTE.darkBlue,
        textAlign: 'center'
    },
    text: {
        fontSize: 16,
        color: PALETTE.gray_c,
        textAlign: 'center'
    }
});
