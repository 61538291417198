import React, { Component } from 'react';
import { View } from 'react-native';

import LeftCover from './leftCover';
import RightCover from './rightCover';

interface Props {
    progress: number,
    height: number,
    fillColor: string,
    emptyColor: string,
    bgc: string
}

export default class ProgressBar extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {        
        let p = `${this.props.progress * 100}%`

        return <View>
            <View style={{
                backgroundColor: this.props.emptyColor,
                height: this.props.height
            }}>
                <View style={{
                    width: p,
                    backgroundColor: this.props.fillColor,
                    height: this.props.height
                }}>
                </View>
            </View>

            <View style={{ position: 'absolute', right: 0 }}>
                <RightCover width={this.props.height/2} height={this.props.height} color={this.props.bgc} />
            </View>
            <View style={{ position: 'absolute', left: 0 }}>
                <LeftCover width={this.props.height/2} height={this.props.height} color={this.props.bgc}/>
            </View>
        </View>
    }
}
