import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, TextInput, Pressable, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import MonthPicker from 'react-native-month-year-picker';
import TextInputMask from 'react-native-text-input-mask';
import { CenterButton } from '../../components'
import { isValidCPF } from '../../CFISP_utils/functions';
import paymentAPIAdapter from '../../http/payment'
import { PaymentCard } from '../../payment_types';
import stateMachine from '../../StateMachine';

interface Props {
    cards: PaymentCard[],
    parent: any
}

interface State {
    waiting: boolean
}

export default class CreditCardList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            waiting: false
        }
    }

    renderItem() {
        return this.props.cards.map((item, index) => {
            return <View key={index} style={{
                backgroundColor: PALETTE.gray_b,
                padding: 10,
                marginVertical: 10,
                borderRadius: 6,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <View>
                    <Text style={{ fontSize: 24 }}>xxxx xxxx xxxx {item.last_numbers}</Text>
                    <Text>{item.brand}</Text>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Pressable
                        onPress={() => {
                            this.props.parent.setDefaultCard(item);
                        }}
                    >
                        <Text style={{
                            textAlign: "right",
                            color: PALETTE.blue,
                            ...Platform.select({
                                web: {
                                    cursor: "pointer"
                                }
                            })
                        }}>Pagar com este</Text>
                    </Pressable>
                    <Pressable
                        onPress={() => {
                            this.setState({ ...this.state, waiting: true });
                            paymentAPIAdapter.deleteCard(item.card_token, (res) => {
                                this.props.parent.removeCard(item);
                            })
                        }}
                    >
                        <Text style={{
                            textAlign: "right",
                            color: PALETTE.red,
                            ...Platform.select({
                                web: {
                                    cursor: "pointer"
                                }
                            })
                        }}>Remover</Text>
                    </Pressable>
                </View>
            </View>
        })
    }

    render() {
        if (this.state.waiting) return <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        return <View style={{
            marginTop: 30
        }}>
            <Text style={{ fontStyle: "italic", fontSize: 18, color: PALETTE.gray_l, textAlign: "center" }}>Outros métodos disponíveis</Text>
            { this.renderItem() }
        </View>
    }
}

const styles = StyleSheet.create({
    
});
