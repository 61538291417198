import EvaluationQuestion from './evaluationQuestion';
import Summary from './summary';
import EvaluationAttempt from './evaluationAttempt';

class Subject {
    id: number;
    title: string;
    description: string;
    image: string;
    syllabus: string;
    author: string;
    pub_date: Date;
    progress: number;
    summaries: Summary[];
    evaluation: EvaluationQuestion[];
    published: boolean;
    evaluationAttempts: EvaluationAttempt[];

    constructor(
        id: number,
        title: string,
        description: string,
        image: string,
        syllabus: string,
        author: string,
        pub_date: Date,
        progress: number,
        summaries: Summary[],
        evaluation: EvaluationQuestion[],
        published: boolean,
        evaluationAttempts: EvaluationAttempt[]
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.image = image;
        this.syllabus = syllabus;
        this.author = author;
        this.pub_date = pub_date;
        this.progress = progress;
        this.summaries = summaries;
        this.evaluation = evaluation;
        this.published = published;
        this.evaluationAttempts = evaluationAttempts
    }
}

export default Subject;
