import React, { Component } from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import InputMask from 'react-input-mask';

interface Props {
    initialDate: string,
    parent: any
}

interface State {
    date: string,
    show: boolean,
    mode: "time" | "date" | "datetime" | "countdown" | undefined
}

export default class DatePicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            date: this.props.initialDate,
            show: false,
            mode: "date"
        }
    }

    setShow(show: boolean) {
        this.setState({ ...this.state, show });
    }

    setMode(mode: "time" | "date" | "datetime" | "countdown" | undefined) {
        this.setState({ ...this.state, mode });
    }

    setDate(date: string) {
        this.setState({ ...this.state, date });
    }

    onChange (event, selectedDate) {
        const currentDate = selectedDate || this.state.date;
        this.setShow(Platform.OS === 'ios');
        this.setDate(currentDate);
    };

    showMode (currentMode: "time" | "date" | "datetime" | "countdown" | undefined) {
        this.setState({ ...this.state, show: true, mode: currentMode });
    };

    showDatepicker () {
        this.showMode("date");
    };

    showTimepicker() {
        this.showMode("time");
    };

    validateData() {

    }

    expRef: any;

    render() {
        return  (
            <View style={styles.root}>
                <input
                    type="date"
                    id="start"
                    name="trip-start"
                    value={this.state.date}
                    onChange={(event) => {
                        this.setState({ ...this.state, date: event.target.value })
                        this.props.parent.updateDateOfBirth(event.target.value)
                    }}
                ></input>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    root: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    labelContainer: {
        flex: 1
    },
    label: {
        textAlign: 'right',
        marginRight: 7
    },
    valueContainer: {
        flex: 1
    },
    value: {
        fontWeight: 'bold'
    },
});
