import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput, Platform } from 'react-native';
// import CheckBox from '@react-native-community/checkbox';
import PALETTE from '../../CFISP_utils/palette';
import { CenterButton } from '../../components'
import { deepCopy } from '../../CFISP_utils/functions';
import { PaymentClient } from '../../payment_types';
import stateMachine from '../../StateMachine';
import { Course } from '../../content_types';
import { PaymentDisplayFiscalPerson } from '../../components/paymentComponents';

interface Props {
    parent: any
}

interface State {
    useMyData: boolean,
    fiscalPerson: PaymentClient
}

export default class PaymentFiscalDataForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let u = stateMachine.getUser();
        this.course = stateMachine.getCourse();

        if (!u) return;

        let fiscalPerson : PaymentClient = deepCopy(u);
        fiscalPerson.name = u.fullName;
        fiscalPerson.document_id = u.idCPF;

        this.user = deepCopy(fiscalPerson);

        this.state = {
            useMyData: true,
            fiscalPerson
        }
    }

    user: PaymentClient | null = null;
    course: Course | null = null;

    render() {
        return <View style={{
            marginTop: 30
        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <View style={{
                    marginRight: 7
                }}>
                    
                    <input type="checkbox" value="useMyData" checked={this.state.useMyData} onChange={() => {
                        this.setState({ ...this.state, useMyData: !this.state.useMyData })
                    }} />
                </View>
                <View>
                    <Text>Usar meus dados de cadastro na nota fiscal</Text>
                </View>
            </View>

            { this.state.useMyData ?
                <PaymentDisplayFiscalPerson fiscalPerson={this.state.fiscalPerson} />
            :
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_k,
                padding: 10
            }}>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Nome:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={value => {
                                this.state.fiscalPerson.name = value;
                                this.setState({ ...this.state });
                            }}
                            value={ this.state.fiscalPerson.name }
                            placeholder={"Nome completo"}
                            textContentType="name"
                            autoCapitalize="words"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            CPF:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={cpf => {
                                this.state.fiscalPerson.document_id = cpf;
                                this.setState({ ...this.state });
                            }}
                            value={ this.state.fiscalPerson.document_id }
                            placeholder={"xxx.xxx.xxx-xx"}
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Endereço:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={value => {
                                this.state.fiscalPerson.address = value;
                                this.setState({ ...this.state });
                            }}
                            value={ this.state.fiscalPerson.address }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Fone:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={value => {
                                this.state.fiscalPerson.phone = value;
                                this.setState({ ...this.state });
                            }}
                            value={ this.state.fiscalPerson.phone }
                            textContentType="telephoneNumber"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Email:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={value => {
                                this.state.fiscalPerson.email = value;
                                this.setState({ ...this.state });
                            }}
                            value={ this.state.fiscalPerson.email }
                            textContentType="emailAddress"
                        />
                    </View>
                </View>
            </View>
            }

            <CenterButton text="Continuar" color={PALETTE.blue} action={() => {
                this.props.parent.next(this.state.fiscalPerson);
            }} />
        </View>
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    },
    fiscalDataDisplayItem: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fiscalDataDisplayLabelContainer: {
        flex: 1
    },
    fiscalDataDisplayLabelText: {
        textAlign: 'right',
        marginRight: 7
    },
    fiscalDataDisplayValueContainer: {
        flex: 3
    },
    fiscalDataDisplayValueText: {
        fontWeight: 'bold'
    },
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    },
});
