import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

interface Props {
    label: string
    value: string
}

export default class ProfileDataDisplayItem extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return  (
            <View style={styles.root}>
                <View style={styles.labelContainer}>
                    <Text style={styles.label}>
                        {this.props.label}
                    </Text>
                </View>
                <View style={styles.valueContainer}>
                    <Text style={styles.value}>{this.props.value}</Text>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    root: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    labelContainer: {
        flex: 1
    },
    label: {
        textAlign: 'right',
        marginRight: 7
    },
    valueContainer: {
        flex: 1
    },
    value: {
        fontWeight: 'bold'
    },
});
