import Resource from './resource';

class Paragraph extends Resource {
    text: string;
    typeName: string = "Paragraph";

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        text: string
    ) {
        super(id, pub_date, consumed);
        this.text = text
    }
}

export default Paragraph;
