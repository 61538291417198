import React, { Component } from 'react';
import { StyleSheet, View, Text, Image, Pressable, Platform } from 'react-native';
import { ExpandableListItem } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { Plus, Minus } from '../../icons';
import Markdown from "react-native-markdown-display";
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';

type Props = {
    item: ExpandableListItem,
    startExpanded: boolean,
    list: any,
    index: number
};

const iconSize = 35;

class ExpandableListItemComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        let expanded = this.props.list.state.expandedItem == this.props.index;

        return (
            <View>
                <View style={styles.bar}>
                    <Image source={{uri: `${S3_IMAGES}${this.props.item.icon}`}} style={styles.icon}></Image>
                    <Text style={styles.heading}>
                        { this.props.item.heading }
                    </Text>
                    <Pressable
                        style={styles.actionIcon}
                        onPress={() => {
                            this.props.list.setExpandedItem(this.props.index);
                            return true;
                        }}
                    >
                        { expanded
                            ? <Minus width={iconSize} height={iconSize} />
                            : <Plus width={iconSize} height={iconSize} />
                        }
                    </Pressable>
                </View>
                <Markdown rules={MarkdownCustomRules} style={expanded ? {body: {
                    fontSize: 18,
                    margin: 16,
                    marginLeft: 40
                }} : {body: {
                    margin: 12
                }}}>
                    { expanded ? this.props.item.body : "" }
                </Markdown>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    bar: {
        backgroundColor: PALETTE.yellow,
        height: 50,
        marginLeft: 40,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 10
    },
    heading: {
        marginLeft: 40
    },
    icon: {
        width: 60,
        height: 60,
        position: 'absolute',
        left: -20,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    actionIcon: {
        fontSize: 30,
        position: 'absolute',
        right: 20
    },
    body: {
        fontSize: 18,
        margin: 16,
        marginLeft: 40
    },
    bodyExpanded: {
        margin: 12,
    }
})

export default ExpandableListItemComponent;
