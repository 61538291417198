import React, { Component } from 'react';
import { Text, View } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import { formatPriceInBRL } from '../../CFISP_utils/functions'

interface Props {
    title: string,
    price: Number
}

export default class PaymentProductOverview extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_i,
                padding: 10
            }}>
                <View>
                    <Text style={{ fontSize: 20 }}>Curso: {this.props.title}</Text>
                </View>
                <View>
                    <Text style={{ fontSize: 20 }}>Valor: {formatPriceInBRL(this.props.price)}</Text>
                </View>
            </View>
        )
    }
}
