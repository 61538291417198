import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        d="M12 6.5c-3.6 0-6.7 2.3-8 5.5 1.3 3.2 4.4 5.5 8 5.5s6.7-2.3 8-5.5c-1.3-3.2-4.4-5.5-8-5.5zm0 9.1c-2 0-3.6-1.6-3.6-3.6S10 8.4 12 8.4s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6zm0-5.8c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2z"
        fill="#cdcdcd"
      />
    </Svg>
  )
}

export default SvgComponent
