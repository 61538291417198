import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import { RouteProp } from '@react-navigation/native';
import Clipboard from '@react-native-community/clipboard';
import { RootStackParamList } from '../../types';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, Pressable, Platform } from 'react-native';
import CourseHeading from '../../components/courseHeading';
import { formatPriceInBRL, multiplatformPrompt } from '../../CFISP_utils/functions'
import PALETTE from '../../CFISP_utils/palette';
import { PaymentCard } from '../../payment_types';
import { PaymentDisplayFiscalPerson, PaymentProductOverview, PaymentConfirmationInstalmentPlan, PaymentConfirmationMode } from '../../components/paymentComponents';
import stateMachine from '../../StateMachine';
import { SERVER_URL } from '../../CFISP_utils/environmentConstants';
import { CenterButton } from '../../components';
import paymentAPIAdapter from '../../http/payment';

type PaymentConfirmationScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type PaymentConfirmationScreenRouteProp = RouteProp<RootStackParamList, 'PaymentConfirmation'>;

interface Props {
    navigation: PaymentConfirmationScreenNavigationProp,
    route: PaymentConfirmationScreenRouteProp
}

interface State {
    waiting: boolean,
    showBankSlipData: boolean,
    bankSlipBarcodeNumber: string,
    bankSlipURL: string
}

export default class PaymentConfirmationScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Confirmação',
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            waiting: false,
            showBankSlipData: false,
            bankSlipBarcodeNumber: "",
            bankSlipURL: ""
        }
    }

    pay() {
        this.setState({ ...this.state, waiting: true });
        
        if (this.props.route.params.paymentMode === "BLT") {
            paymentAPIAdapter.payWithBLT(this.props.route.params.course, this.props.route.params.instalments, this.props.route.params.fiscalData, (res) => {
                this.setState({ ...this.state, waiting: false });

                if (res.msg === "ERROR") {
                    multiplatformPrompt("ERRO", "Houve algum problema com o pagamento. Verifique os dados e tente novamente.", "Ok", () => { this.props.navigation.navigate("Home"); })
                } else if (res.msg === "BANK_SLIP_CREATED") {
                    this.setState({
                        ...this.state,
                        waiting: false,
                        showBankSlipData: true,
                        bankSlipBarcodeNumber: res.barcode,
                        bankSlipURL: res.url
                    });
                }
            });
        } else {
            if (!stateMachine.paymentCard) return;

            paymentAPIAdapter.payWithCard(this.props.route.params.course, stateMachine.paymentCard, this.props.route.params.instalments, this.props.route.params.fiscalData, (res) => {
                this.setState({ ...this.state, waiting: false });

                if (res.msg === "ERROR") {
                    multiplatformPrompt("ERRO", "Houve algum problema com o pagamento. Verifique os dados e tente novamente.", "Ok", () => { this.props.navigation.navigate("Home"); })
                } else if (res.msg === "SUCCESS") {
                    multiplatformPrompt("Sucesso!", "Pagamento concluído.", "Ok", () => { this.props.navigation.navigate("OwnedCourses"); })
                }
            })
        }
    }

    render () {
        if (this.state.waiting) return (
            <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        );

        if (this.state.showBankSlipData) return (
            <SafeAreaView>
                <ScrollView>
                    <Text style={styles.bankSlipText}>Boleto criado com sucesso! Seu curso ficará disponível após a verificação do pagamento.</Text>
                    <CenterButton
                        action={() => {
                            if(Platform.OS == 'web'){
                                window.open(this.state.bankSlipURL, '_blank');
                            } else {
                                Linking.openURL(this.state.bankSlipURL);
                            }
                        }}
                        text="Boleto"
                        color={PALETTE.blue}
                    />
                    <Text style={styles.bankSlipText}>Número do código de barras do boleto: {this.state.bankSlipBarcodeNumber}</Text>
                    <CenterButton
                        action={() => {
                            if(Platform.OS == 'web'){
                                navigator.clipboard.writeText(this.state.bankSlipBarcodeNumber)
                            } else {
                                Clipboard.setString(this.state.bankSlipBarcodeNumber);
                            }
                        }}
                        text="Copiar para área de transferência"
                        color={PALETTE.gayGreen}
                    />
                    <Text style={{
                        textAlign: "center",
                        color: PALETTE.blue,
                        fontStyle: "italic",
                        fontSize: 16,
                        marginVertical: 40,
                        ...Platform.select({
                            "web": {
                                cursor: "pointer"
                            }
                        })
                    }} onPress={() => {
                        this.props.navigation.navigate("Home");
                    }}>Início</Text>
                </ScrollView>
            </SafeAreaView>
        );

        return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <PaymentProductOverview title={this.props.route.params.course.title} price={this.props.route.params.course.price}/>

                    <Text style={styles.title}>Forma de pagamento</Text>
                    <PaymentConfirmationMode mode={this.props.route.params.paymentMode} />
                    <Pressable onPress={() => {
                        this.props.navigation.navigate("PaymentMode", { course: this.props.route.params.course });
                    }}>
                        <Text style={styles.action}>Alterar forma de pagamento</Text>
                    </Pressable>

                    <Text style={styles.title}>Parcelamento</Text>
                    <PaymentConfirmationInstalmentPlan instalments={this.props.route.params.instalments} price={this.props.route.params.course.price} />
                    <Pressable onPress={() => {
                        this.props.navigation.navigate("PaymentInstalmentPlans", {
                            course: this.props.route.params.course,
                            paymentMode: this.props.route.params.paymentMode
                        });
                    }}>
                        <Text style={styles.action}>Alterar parcelamento</Text>
                    </Pressable>
                    
                    <Text style={styles.title}>Dados fiscais</Text>
                    <PaymentDisplayFiscalPerson fiscalPerson={this.props.route.params.fiscalData} />
                    <Pressable onPress={() => {
                        this.props.navigation.navigate("PaymentFiscalData", {
                            paymentMode: this.props.route.params.paymentMode,
                            course: this.props.route.params.course,
                            instalments: this.props.route.params.instalments
                        })
                    }}>
                        <Text style={styles.action}>Alterar dados fiscais</Text>
                    </Pressable>

                    <View style={{ marginBottom: 50 }}>
                        <CenterButton text="Pagar" color={ PALETTE.blue } action={() => {
                            this.pay();    
                        }} />
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    title: {
        textAlign: 'left',
        marginBottom: -8,
        fontSize: 17,
        fontWeight: 'bold',
        marginTop: 17
    },
    action: {
        textAlign: 'right',
        marginBottom: 20,
        marginTop: -7,
        fontStyle: 'italic',
        color: PALETTE.blue,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    bankSlipText: {
        fontSize: 20,
        textAlign: "center",
        color: PALETTE.gray_e,
        marginVertical: 40
    }
});
