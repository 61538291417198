import React, { Component } from 'react';
import {
    StyleSheet,
    View,
    Text
} from 'react-native';
import { TextAssociation } from '../../content_types';
import shuffle from '../../CFISP_utils/shuffle';
import PALETTE from '../../CFISP_utils/palette';
import { TextAssociationShortLineComponent, ButtonState, ShortLine } from './textAssociationShortLineComponent';
import { CenterButton } from '../../components';
import stateMachine from '../../StateMachine';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

type LongLine = {
    index: number;
    text: string;
    userChoice: number;
}

type Props = {
    parent: any;
    textAssociation: TextAssociation;
};

type State = {
    counter: number;
    longLines: LongLine[];
    shortLines: ShortLine[];

    sent: boolean,
    showAssociateAllWarning: boolean,
    showExplanationPaneButton: boolean
};

class TextAssociationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        let longLines: LongLine[] = [];
        this.props.textAssociation.leftLines.forEach((item, index) => {
            longLines.push({ index, text: item.text, userChoice: -1 })
        })

        let shortLines: ShortLine[] = [];
        this.props.textAssociation.rightLines.forEach((item, index)=> {
            shortLines.push({ index, text: item.text, state: ButtonState.normal, userChoice: -1 })
        })

        shuffle(shortLines);

        this.state = {
            counter: 0,
            longLines,
            shortLines,
            sent: false,
            showAssociateAllWarning: false,
            showExplanationPaneButton: false
        }

        if (this.props.textAssociation.explanation.length > 0) {
            this.props.parent.setExplanationText("---");
        }
    }

    send() {
        if (this.state.counter < this.state.longLines.length) {
            this.setState({ ...this.state, showAssociateAllWarning: true })
            return;
        }

        // As linhas comentadas nesse método podem ajudar
        // a implementar o registro de erros e acertos
        // do usuário.
        // let nOfCorrect = 0;

        let shortLines = this.state.shortLines;
        shortLines.forEach((line, index) => {
            if (line.index === line.userChoice) {
                line.state = ButtonState.correct;
                // nOfCorrect++;
            } else line.state = ButtonState.wrong;
        })

        // if (nOfCorrect === shortLines.length) {
        //     this.props.textAssociation.consume();
        // }

        this.setState({ ...this.state, sent: true, shortLines });
    }

    renderButtons() {
        return this.state.shortLines.map((item, index) => {
            return (
                <TextAssociationShortLineComponent
                    key={index}
                    index={index}
                    shortLine={item}
                    textAssociation={this.props.textAssociation}
                    parent={this}
                />
            )
        })
    }

    choose(currentPosition, index) {
        let longLines = this.state.longLines;
        let shortLines = this.state.shortLines;
        let counter = this.state.counter;

        longLines[counter].userChoice = index;
        shortLines[currentPosition].userChoice = counter;
        shortLines[currentPosition].state = ButtonState.selected;
        counter++;

        this.setState({ ...this.state, longLines, shortLines, counter, showAssociateAllWarning: false });
    }

    longLinesRefs: any[] = [];
    renderLongLines() {
        return this.state.longLines.map((item, index) => {
            return (
                <View
                    key={index}
                    style={ styles.longLine }
                    ref={(ref) => { this.longLinesRefs[index] = ref }}
                >
                    <View style={[{width: 36,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        borderRadius: 15},
                        this.state.sent ? item.index === item.userChoice
                            ? { backgroundColor: PALETTE.anotherGreen }
                            : { backgroundColor: PALETTE.red }
                        : {
                            backgroundColor: PALETTE.blue
                        }]}>
                        <Text style={{
                            margin: 2,
                            textAlign: 'center',
                            color: PALETTE.background
                        }}>{index + 1}</Text>
                    </View>
                    <Markdown rules={MarkdownCustomRules}>
                        {item.text}
                    </Markdown>
                </View>
            )
        })
    }

    render() {
        return (
            <View style={styles.quizView}>
                <View style={styles.outerCircle}>
                    <View style={styles.whiteRectangle}></View>
                    <View style={styles.wireRectangle}></View>
                </View>
                <Text style={styles.title}>RELACIONAR</Text>
                <Text style={styles.bold}>Leia os trechos abaixo:</Text>

                <Text style={styles.text}>
                    { this.props.textAssociation.instructions }
                </Text>
                
                { this.renderLongLines() }

                <Text style={styles.bold}>Agora seleciona a sequência correta para <Text style={{ color: PALETTE.blue }}>relacioná-los</Text> às alternativas a seguir:</Text>

                { this.renderButtons() }
                
                { this.state.sent ? <View style={{
                    marginTop: 30
                }}>
                    {
                        this.state.sent && this.props.textAssociation.explanation.length > 0 ? <CenterButton
                            text="Ver gabarito"
                            action={() => {
                                this.props.parent.setExplanationText(this.props.textAssociation.explanation);
                                this.props.parent.showExplanationPane();
                            }}
                            color={ PALETTE.gayBlue }
                            paddingVertical={8}
                            paddingHorizontal={60}
                            width="80%"
                        /> : null
                    }
                    <CenterButton
                        text="Avançar"
                        action={() => {
                            this.props.parent.goForward();
                        }}
                        color={ PALETTE.gayGreen }
                        paddingVertical={8}
                        paddingHorizontal={60}
                        width="80%"
                        marginTop={16}
                    />
                    <Text style={[styles.text, { marginTop: 16, textAlign: 'center' }]}>
                        Voltar para <Text
                            style={{
                                color: PALETTE.gayBlue
                            }}
                            onPress={() => { stateMachine.goToThisSubjectList() }}
                        >lista de lições</Text>
                    </Text>
                </View> : <View style={{
                    marginTop: 30
                }}>
                    { this.state.showAssociateAllWarning ? <Text style={[{ marginBottom: 5, color: PALETTE.red, textAlign: 'center' }]}>
                        Selecione todas as alternativas para formar a sequência
                    </Text> : null }
                    <CenterButton
                        text="Enviar"
                        action={() => { this.send() }}
                        color={ this.state.counter === this.state.longLines.length ? PALETTE.gayBlue : PALETTE.lightGayBlue }
                        paddingVertical={8}
                        paddingHorizontal={60}
                    />
                </View> }
            </View>
        )
    }
}

const checkRadius = 45;
const padding = 22;
const iconRadius = 36;

const styles = StyleSheet.create({
    quizView: {
        backgroundColor: PALETTE.almostWhite,
        margin: 24,
        marginBottom: 24+checkRadius,
        borderRadius: 20,
        padding: padding,
    },
    whiteRectangle: {
        backgroundColor: 'white',
        width: iconRadius*0.7,
        height: iconRadius*0.6,
        borderRadius: 4,
        position: 'absolute',
        marginTop: iconRadius*0.6,
        marginLeft: iconRadius*0.4
    },
    wireRectangle: {
        borderColor: PALETTE.darkBlue,
        width: iconRadius*0.7,
        height: iconRadius*0.6,
        position: 'absolute',
        borderRadius: 4,
        borderWidth: 2,
        marginTop: iconRadius*0.9,
        marginLeft: iconRadius*0.75
    },
    outerCircle: {
        backgroundColor: PALETTE.blue,
        width: iconRadius*2,
        height: iconRadius*2,
        alignSelf: 'center',
        borderRadius: 100,
        marginBottom: 10
    },
    title: {
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 10
    },
    text: {
        fontSize: 16,
        marginBottom: 10
    },
    check: {
        width: checkRadius*2,
        height: checkRadius*2,
        position: 'relative',
        bottom: -checkRadius - padding,
        alignSelf: 'center'
    },
    bold: {
        fontWeight: "bold"
    },
    textEmphasis: {
        color: PALETTE.gayBlue
    },
    associetedText: {
        color: 'white'
    },
    shortLine: {
        backgroundColor: PALETTE.lightBlue,
        borderRadius: 24,
        padding: 18,
        marginBottom: 13,
        alignItems: 'center'
    },
    associatedLine: {
        backgroundColor: PALETTE.gayBlue,
        borderRadius: 18,
        padding: 12,
        marginBottom: 10
    },
    longLine: {
        backgroundColor: PALETTE.gray_i,
        borderRadius: 24,
        padding: 18,
        marginBottom: 13,
    },
    associatedLongLine: {
        backgroundColor: PALETTE.gray_i,
        borderRadius: 24,
        padding: 18,
        marginBottom: 13,
    },
    longLineActive: {
        backgroundColor: PALETTE.gray_h,
        borderRadius: 24,
        padding: 18,
        marginBottom: 13,
    }
})

export default TextAssociationComponent;
