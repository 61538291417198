import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 48 48"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Path
        d="M48 24a24 24 0 01-24 24A24 24 0 010 24 24 24 0 0124 0a24 24 0 0124 24"
        id="prefix__circle835"
        fill="#007aff"
      />
      <Path
        id="prefix__path837"
        d="M48 24c0 13.3-10.7 24-24 24V24 0c13.3 0 24 10.7 24 24z"
        fill="#363c55"
      />
      <Path
        id="prefix__path843"
        d="M13.3 26.2l2.1-6.9h2.1l-3.2 9.2h-2l-3.2-9.2h2.1z"
        fill="#FFFFFF"
      />
      <Path
        id="prefix__path849"
        d="M37.7 24.8h-3.6v3.8h-1.9v-9.2h6v1.5h-4.1v2.4h3.6z"
        fill="#FFFFFF"
      />
    </Svg>
  )
}

export default SvgComponent
