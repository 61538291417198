import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 24 19"
      y={0}
      x={0}
      id="greenTickedCheckCircle"
      {...props}
    >
      <Path
        id="prefix__path857"
        d="M12 .01C6.77.01 2.51 4.27 2.51 9.5s4.26 9.49 9.49 9.49c5.11 0 9.229-4.085 9.42-9.148l.002-.68C21.233 4.096 17.11.01 12 .01zm0 1.78a7.698 7.698 0 017.71 7.71A7.698 7.698 0 0112 17.21 7.698 7.698 0 014.29 9.5 7.698 7.698 0 0112 1.79z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        opacity={1}
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#55e0b5"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.78}
        strokeLinecap="round"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
      />
      <Path
        id="prefix__path852"
        d="M15.71 6.293a.908.908 0 00-.638.252l-4.146 3.973L9.055 8.57a.908.908 0 00-1.284-.025.908.908 0 00-.025 1.285l2.5 2.6a.908.908 0 001.281.025l4.801-4.6a.908.908 0 00.027-1.283.908.908 0 00-.644-.279z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#55e0b5"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.816}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
