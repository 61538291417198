import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform, Dimensions } from 'react-native';
import { S3_VIDEOS, S3_IMAGES } from '../CFISP_utils/environmentConstants';
import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";

type Props = {
    video: string;
    thumbnail: string;
};

export default class VideoComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <Player
                playsInline
                poster={`${S3_IMAGES}${this.props.thumbnail}`}
                src={`${S3_VIDEOS}${this.props.video}`}
            />
        )
    }
}

const styles = StyleSheet.create({
    text: {
        margin: 20
    },
    video: {
        alignSelf: 'stretch',
        // height: 300
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    videoTitle: {
        textAlign: 'center',
        marginVertical: 12,
        fontWeight: 'bold',
        fontSize: 16
    },
    videoDescription: {
        marginLeft: 12
    },
});
