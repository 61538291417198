import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, Image } from 'react-native';
import stateMachine from '../StateMachine';
import CourseCard from '../components/courseCard';

type Props = {
    navigation: any
}

class OwnedCoursesHorizontalList extends Component<Props> {
    openCourse(courseIndex: number) {
        this.props.navigation.navigate('Course', {course: stateMachine.getOwnedCourse(courseIndex)});
    }

    render () {
        return (
            <View style={styles.root}>
                <FlatList
                    data={stateMachine.ownedCourses}
                    horizontal={true}
                    keyExtractor={({ code }, index) => code}
                    renderItem={({ item }) => (
                        <CourseCard navigation={this.props.navigation} course={item} />
                    )}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        padding: 12
    }
})

export default OwnedCoursesHorizontalList;
