import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path
        d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
        fill="none"
      />
      <Path
        d="M12 8.5c2 0 3.6 1.6 3.6 3.6 0 .5-.1.9-.3 1.3l2.1 2.1c1.1-.9 2-2.1 2.5-3.5-1.3-3.2-4.4-5.5-8-5.5-1 0-2 .2-2.9.5l1.6 1.6c.5 0 .9-.1 1.4-.1zm-7.3-2l1.7 1.7.3.3c-1.2.9-2.1 2.2-2.7 3.6 1.3 3.2 4.4 5.5 8 5.5 1.1 0 2.2-.2 3.2-.6l.3.3 2.1 2.1.9-.9L5.6 5.6l-.9.9zm4 4l1.1 1.1c0 .2-.1.3-.1.5 0 1.2 1 2.2 2.2 2.2.2 0 .3 0 .5-.1l1.1 1.1c-.5.2-1 .4-1.6.4-2 0-3.6-1.6-3.6-3.6.1-.5.2-1.1.4-1.6zm3.2-.5l2.3 2.3v-.1c0-1.2-1-2.2-2.2-2.2h-.1z"
        fill="#cdcdcd"
      />
    </Svg>
  )
}

export default SvgComponent
