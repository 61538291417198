import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, Platform, Pressable } from 'react-native';
import stateMachine from '../StateMachine';
import PALETTE from '../CFISP_utils/palette';
import { Heart, HeartFull } from '../icons';
import http from '../http';

interface Props {}

interface State {
    favorite: boolean
}

class FavoriteButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { favorite: stateMachine.isSelectedCourseFavorite() }
    }

    render () {
        return (
            <Pressable
                style={{
                    height: 20,
                    width: 20,
                    marginRight: 10
                }}
                onPress={() => {
                    let course = stateMachine.getCourse();
                    if (course != null) {
                        if (course.isFavorite) {
                            http.unFavoriteCourse(course, () => {
                                if (course) course.isFavorite = false;
                                this.setState({ favorite : false });
                                stateMachine.fetchFavoriteCourses();
                            })
                        } else {
                            http.favoriteCourse(course, () => {
                                if (course) course.isFavorite = true;
                                this.setState({ favorite : true });
                                stateMachine.fetchFavoriteCourses();
                            })
                        }
                    }
                }}
            >
                { this.state.favorite ? <HeartFull width={20} height={20} color={PALETTE.darkGray} /> : <Heart width={20} height={20} /> }
            </Pressable>
        );
    }
}

const styles = StyleSheet.create({
    
})

export default FavoriteButton;
