import React from 'react';
import { Alert, View } from 'react-native';
import { SERVER_URL } from '../CFISP_utils/environmentConstants';
import stateMachine from '../StateMachine';
import login from '../screens/loginScreen'
import {
    Field,
    Course,
    Subject,
    Lesson,
    Paragraph,
    Infograph,
    Video,
    Quiz,
    Boolean,
    TextAssociation,
    TextDragAndDrop,
    ExpandableList,
    ExpandableListItem,
    Timeline,
    TimelineItem,
    Comparison,
    ComparisonItem,
    EvaluationQuestion,
    EvaluationQuestionWrongAnswer,
    Summary,
    SummaryTopic,
    EvaluationAttempt
} from '../content_types';

import TextAssociationLine from '../content_types/textAssociationLine';
import { Draggable, Fragment } from '../content_types/textDragAndDrop';
import { LoginScreen } from '../screens';

class Http {
    constructor() {
        // var requestOptions = {
        //     method: 'GET',
        // };
    }

    private async get(path: string) {
        let response = await fetch(`${SERVER_URL}${path}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${stateMachine.token}`
            }
        }).then(response => response.json()).catch(error => {
            console.error(error);
        });
    
        return response;
    }

    private async post(path: string, body: any) {
        let postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        // postHeaders.append("Host", "cfisp.com.br");
        if (stateMachine.token && stateMachine.token != '') postHeaders.append("Authorization", `Token ${stateMachine.token}`);
    
        let response = await fetch(`${SERVER_URL}${path}`, {
            method: 'POST',
            headers: postHeaders,
            body: JSON.stringify(body)
        }).then(response => response.json()).catch(error => {
            console.error(error);
        });
    
        return response;
    }

    private async patch(path: string, body: any) {
        let postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        // postHeaders.append("Host", "cfisp.com.br");
        if (stateMachine.token && stateMachine.token != '') postHeaders.append("Authorization", `Token ${stateMachine.token}`);
    
        let response = await fetch(`${SERVER_URL}${path}`, {
            method: 'PATCH',
            headers: postHeaders,
            body: JSON.stringify(body)
        }).then(response => response.json()).catch(error => {
            console.error(error);
        });
    
        return response;
    }

    private async delete (path: string) {
        let postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        // postHeaders.append("Host", "cfisp.com.br");
        if (stateMachine.token && stateMachine.token != '') postHeaders.append("Authorization", `Token ${stateMachine.token}`);
    
        let response = await fetch(`${SERVER_URL}${path}`, {
            method: 'DELETE',
            headers: postHeaders
        }).then(response => response.json()).catch(error => {
            console.error(error);
        });
    
        return response;
    }

    sendCredentials = (username: string, password: string, callback: any) => {
        this.post('login/', { username, password, accessLevel: "student" }).then(res => {
            if (res) {
                if (res.token && res.token.length == 40) {
                    
                    // persist token
                    stateMachine.storeToken(res.token, username).then(
                        (value: void) => {
                            stateMachine.authenticate(res.token, username);
                            stateMachine.setUserId(res.user_id);
                            callback();
                        },
                        (reason: any) => {
                            console.error(reason);
                        },
                    );
                } else if (res.error) {
                    console.log(res.error);
                    alert("Usuário e/ou senha inválidos");
                }
            } else {
                console.error("Response is undefined, null or false")
            }
        })
    };

    register(email: string, password: string, callback: any) {
        let registerHeader = new Headers();
        registerHeader.append("Content-Type", "multipart/form-data");

        fetch(`${SERVER_URL}signup/?email=${email}&pw=${password}`, {
            method: 'GET',
            headers: registerHeader,
        }).then(raw => {
            callback(raw.status)
        }, reason => {
            console.log(reason);
            callback('FAILED_TO_FETCH');
        });
    }

    fetchFields(callback: any) {
        if (stateMachine.fields.length > 0) return;

        fetch(`${SERVER_URL}content/fields/`, {
            // mode: 'no-cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            response.json().then((parsedResponse => {
                stateMachine.fields = [];
                for (let i=0; i<parsedResponse.results.length; i++) {
                    stateMachine.addField(new Field(parsedResponse.results[i].id, parsedResponse.results[i].name));
                }

                callback();
            }));
        }).catch(error => {
            console.error(error);
        });
    }

    fetchCoursesInField(fieldId: number, callback: any) {
        stateMachine.unansweredFetchCoursesRequests++;
        fetch(`${SERVER_URL}content/courses_in_field/?field=${fieldId}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${stateMachine.token}`
            }
        }).then(response => {
            if (response.status == 200) {
                response.json()
                    .then((parsedResponse => {
                        let courses: Course[] = [];

                        for (let i=0; i<parsedResponse.results.length; i++) {
                            let c = new Course(
                                parsedResponse.results[i].id,
                                parsedResponse.results[i].code,
                                parsedResponse.results[i].title,
                                parsedResponse.results[i].description,
                                parsedResponse.results[i].image,
                                parsedResponse.results[i].video,
                                parsedResponse.results[i].pub_date,
                                parsedResponse.results[i].price,
                                parsedResponse.results[i].workload,
                                parsedResponse.results[i].owns,
                                parsedResponse.results[i].progress,
                                parsedResponse.results[i].favorite
                            )

                            courses.push(c);
                            stateMachine.addCourse(c);
                        }

                        stateMachine.unansweredFetchCoursesRequests--;
                        stateMachine.notifyCoursesUpdate();

                        callback(courses);
                    }))
                    .catch(reason => {
                        stateMachine.unansweredFetchCoursesRequests--;
                        console.log(reason);
                    })
            } else {
                stateMachine.unansweredFetchCoursesRequests--;
                console.log(response);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    fetchCoursesInFieldAnonimously(fieldId: number, callback: any) {
        stateMachine.unansweredFetchCoursesRequests++;
        fetch(`${SERVER_URL}content/courses_in_field_anonimously/?field=${fieldId}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.status == 200) {
                response.json()
                    .then((parsedResponse => {
                        let courses: Course[] = [];
                    
                        for (let i = 0; i < parsedResponse.results.length; i++) {
                            let c = new Course(
                                parsedResponse.results[i].id,
                                parsedResponse.results[i].code,
                                parsedResponse.results[i].title,
                                parsedResponse.results[i].description,
                                parsedResponse.results[i].image,
                                parsedResponse.results[i].video,
                                parsedResponse.results[i].pub_date,
                                parsedResponse.results[i].price,
                                parsedResponse.results[i].workload,
                                false,
                                { total: 0, completed: 0 },
                                false
                            )

                            courses.push(c);
                            stateMachine.addCourse(c);
                        }

                        stateMachine.unansweredFetchCoursesRequests--;
                        stateMachine.notifyCoursesUpdate();

                        callback(courses);
                    }))
                    .catch(reason => {
                        stateMachine.unansweredFetchCoursesRequests--;
                        console.log(reason);
                    })
            } else {
                stateMachine.unansweredFetchCoursesRequests--;
                console.log(response);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    fetchOwnedCourses(callback: any) {
        this.get('content/owned_courses/').then(res => {
            if (!res) return;
            
            stateMachine.ownedCourses = [];
            for (let i = 0; i < res.results?.length; i++) {
                stateMachine.addOwnedCourses(new Course(
                    res.results[i].id,
                    res.results[i].code,
                    res.results[i].title,
                    res.results[i].description,
                    res.results[i].image,
                    res.results[i].video,
                    res.results[i].pub_date,
                    res.results[i].price,
                    res.results[i].workload,
                    true,
                    res.results[i].progress,
                    res.results[i].favorite
                ));
            }

            callback();
        })
    }

    favoriteCourse(course: Course, callback: any) {
        this.post(`content/favorite_course/`, { course: course.id }).then(res => {
            if (res.course === course.id) {
                callback();
            }
        })
    }

    unFavoriteCourse(course: Course, callback: any) {
        this.patch(`content/un_favorite_course/${course.id}/`, {}).then(res => {
            if (res === "ok") {
                callback();
            }
        })
    }

    fetchFavoriteCourses(callback: any) {
        this.get(`content/favorites/`).then(res => {
            let courses: Course[] = [];

            for (let i=0; i<res.results.length; i++) {
                courses.push(new Course(
                    res.results[i].id,
                    res.results[i].code,
                    res.results[i].title,
                    res.results[i].description,
                    res.results[i].image,
                    res.results[i].video,
                    res.results[i].pub_date,
                    res.results[i].price,
                    res.results[i].workload,
                    false,
                    res.results[i].progress,
                    res.results[i].favorite
                ))
            }

            callback(courses);
        })
    }

    fetchSubjectsInCourse(courseId: number, callback: any) {
        this.get(`content/course_subjects/?courseId=${courseId}`).then(res => {
            stateMachine.subjects = [];
            for (let i = 0; i < res.count; i++) {
                let evaluation : EvaluationQuestion[] = [];

                for (let j=0; j<res.results[i].evaluation_questions.length; j++) {
                    let wrongOptions: EvaluationQuestionWrongAnswer[] = [];

                    for (let k=0; k<res.results[i].evaluation_questions[j].wrong_options.length; k++) {
                        wrongOptions.push({
                            id: res.results[i].evaluation_questions[j].wrong_options[k].id,
                            option: res.results[i].evaluation_questions[j].wrong_options[k].option
                        });
                    }

                    evaluation.push(new EvaluationQuestion(
                        res.results[i].evaluation_questions[j].id,
                        res.results[i].evaluation_questions[j].question,
                        res.results[i].evaluation_questions[j].emphasis,
                        res.results[i].evaluation_questions[j].correct,
                        wrongOptions,
                        res.results[i].evaluation_questions[j].answer
                    ))
                }

                let summaries: Summary[] = [];

                for (let j=0; j<res.results[i].summaries.length; j++) {
                    let topics: SummaryTopic[] = [];

                    for (let k=0; k<res.results[i].summaries[j].summary_topics.length; k++) {
                        topics.push({
                            id: res.results[i].summaries[j].summary_topics[k].id,
                            text: res.results[i].summaries[j].summary_topics[k].text
                        })
                    }

                    summaries.push({
                        id: res.results[i].summaries[j].id,
                        topics: topics
                    })
                }

                let evaluationAttempts : EvaluationAttempt[] = [];
                for (let j=0; j<res.results[i].evaluation_attempts.length; j++) {
                    evaluationAttempts.push(new EvaluationAttempt(
                        res.results[i].evaluation_attempts[j].id,
                        res.results[i].evaluation_attempts[j].startTimestamp,
                        res.results[i].evaluation_attempts[j].endTimestamp,
                        res.results[i].evaluation_attempts[j].grade
                    ))
                }

                stateMachine.addSubject(new Subject(
                    res.results[i].id,
                    res.results[i].title,
                    res.results[i].description,
                    res.results[i].image,
                    res.results[i].syllabus,
                    res.results[i].author,
                    res.results[i].pub_date,
                    res.results[i].progress,
                    summaries,
                    evaluation,
                    res.results[i].published,
                    evaluationAttempts
                ));
            }

            callback();
        })
    }

    fetchLessons(subjectId: number, callback: any) {
        this.get(`content/subject_lessons/?subjectId=${subjectId}`).then(res => {
            stateMachine.lessons = [];
            for (let i = 0; i < res.results.length; i++) {
                stateMachine.addLesson(new Lesson(
                    res.results[i].id,
                    res.results[i].title,
                    res.results[i].description,
                    res.results[i].pub_date,
                    res.results[i].progress
                ));
            }

            callback();
        })
    }

    fetchResources(lesson: Lesson, callback: any) {
        this.get(`content/lesson_resources/?lessonId=${lesson.id}`).then(res => {
            if (!res) return;
            
            for (let i = 0; i < res.results?.length; i++) {
                switch (res.results[i].resource_type) {
                    case "Paragraph":
                        lesson.addResource(new Paragraph(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].text
                        ), res.results[i].position, false);
                        break;
                    case "Infograph":
                        lesson.addResource(new Infograph(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].src
                        ), res.results[i].position, false);
                        break;
                    case "Video":
                        lesson.addResource(new Video(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].yt_id,
                            res.results[i].title,
                            res.results[i].description
                        ), res.results[i].position, false);
                        break;
                    case "Quiz":
                        lesson.addResource(new Quiz(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].points,
                            res.results[i].explanation,
                            res.results[i].instructions,
                            res.results[i].correctOption,
                            res.results[i].quizFalseOption,
                        ), res.results[i].position, true);
                        break;
                    case "Boolean":
                        lesson.addResource(new Boolean(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].points,
                            res.results[i].explanation,
                            res.results[i].statement,
                            res.results[i].correct
                        ), res.results[i].position, true);
                        break;
                    case "TextAssociation":
                        let leftLines: TextAssociationLine[] = [];
                        let rightLines: TextAssociationLine[] = [];
                        
                        res.results[i].leftLines.forEach((l: any) => {
                            leftLines.push(l)
                        })
    
                        res.results[i].rightLines.forEach((l: any) => {
                            rightLines.push(l)
                        })
    
                        lesson.addResource(new TextAssociation(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].points,
                            res.results[i].explanation,
                            res.results[i].title,
                            res.results[i].instructions,
                            leftLines,
                            rightLines
                        ), res.results[i].position, true);
                        break;
                    case "TextDragAndDrop":
                        let fragments: Fragment[] = []
                        for (let j=0; j<res.results[i].baseText.length; j++) {
                            fragments[res.results[i].baseText[j].position] = res.results[i].baseText[j];
                        }
    
                        let draggables: Draggable[] = []
                        for (let j=0; j<res.results[i].draggables.length; j++) {
                            draggables[res.results[i].draggables[j].position] = res.results[i].draggables[j];
                        }
    
                        lesson.addResource(new TextDragAndDrop(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].points,
                            res.results[i].explanation,
                            res.results[i].instructions,
                            draggables,
                            fragments
                        ), res.results[i].position, true);
                        break;
                    case "ExpandableList":
                        let expandables: ExpandableListItem[] = [];
                        for (let item of res.results[i].expandables) {
                            expandables[item.position] = new ExpandableListItem(
                                item.icon,
                                item.heading,
                                item.body
                            );
                        }

                        let list = new ExpandableList(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].title,
                            res.results[i].text,
                            res.results[i].ilustration,
                        )

                        list.setExpandables(expandables);
    
                        lesson.addResource(list, res.results[i].position, false);
                        break;
                    case "Timeline":
                        let timelineItems: TimelineItem[] = [];
                        for (let item of res.results[i].timeline_items) {
                            timelineItems[item.position] = new TimelineItem(
                                item.date,
                                item.heading,
                                item.body
                            );
                        }

                        let timeline = new Timeline(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].title,
                            res.results[i].ilustration,
                            res.results[i].text
                        )

                        timeline.setItems(timelineItems);

                        lesson.addResource(timeline, res.results[i].position, false);
                        break;
                    case "ComparisonInfograph":
                        let comparisonItems: ComparisonItem[] = [];
                        for (let item of res.results[i].comparison_infograph_item) {
                            comparisonItems[item.position] = new ComparisonItem(
                                item.leftTitle,
                                item.leftText,
                                item.rightTitle,
                                item.rightText,
                                item.icon
                            );
                        }

                        let comparison = new Comparison(
                            res.results[i].id,
                            res.results[i].pub_date,
                            res.results[i].consumed,
                            res.results[i].leftTitle,
                            res.results[i].rightTitle,
                            res.results[i].leftIllustration,
                            res.results[i].rightIllustration,
                        )

                        comparison.setItems(comparisonItems);

                        lesson.addResource(comparison, res.results[i].position, false);
                        break;
                    default:
                        console.error("Resource of unknown type");
                }
            }

            callback();
        });
    }

    getUserData(callback: any) {
        this.get("my-data/").then((res) => {
            callback(res);
        }, (reason) => {
            console.log(reason);
        })
    }

    saveUserData(student: any, callback: any) {
        this.post("update-my-data/", student).then((res) => {
            callback(res);
        }, (reason) => {
            console.log(reason);
        })
    }

    saveEvaluationQuestionResult(iteration: number, questionId: Number, userChoice: number, result: boolean, callback: (res: any) => void) {
        this.post("content/evaluation-user-answer/", {
            "iteration": iteration,
            "userChoice": userChoice,
            "result": result,
            "student": stateMachine.userId,
            "evaluationQuestion": questionId
        }).then((res) => {
            callback(res);
        }, (reason) => {
            console.log(reason);
        })
    }

    startEvaluation(subjectId: number, callback: (res: any) => void) {
        this.post("content/evaluation-attempts/", {
            "subject": subjectId
        }).then((res) => {
            callback(res);
        }, (reason) => {
            console.log(reason);
        })
    }

    endEvaluation(evaluationId: number, iteration: number, callback: (res: any) => void) {
        this.post("end-evaluation/", {
            "evaluation": evaluationId,
            "iteration": iteration
        }).then((res) => {
            callback(res);
        }, (reason) => {
            console.log(reason);
        })
    }

    hasUnfinishedEvaluation(callback: (hasUnfinishedEvaluation: boolean) => void) {
        this.get("content/incomplete-evaluation-attempt/").then((res) => {
            if (res.length == 1) callback(res)
        }, (reason) => {
            console.log(reason);
        })
    }
}

let http = new Http();
export default http;
