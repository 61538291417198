import React, { Component } from 'react';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';
import { Infograph } from '../../content_types';
import ZoomableImage from './zoomableImage';
import { Image, ActivityIndicator, Text } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';

type Props = {
    infograph: Infograph
};

type State = {
    loading: boolean
}

class InfographComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        Image.getSize(`${S3_IMAGES}${this.props.infograph.src}`, (width, height)=>{
            this.width = width;
            this.height = height;
            this.setState({ loading: false })
        }, (err) => {
            this.error = err;
            this.setState({ loading: false })
        });
    }

    height: number = 1;
    width: number = 1;
    error: string = "";

    render() {
        if (!this.props.infograph.consumed) this.props.infograph.consume();

        if (this.state.loading) return <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        else if (this.error != "") return <Text>Não foi possível carregar a imagem {this.props.infograph.src}</Text>
        else return (
            <ZoomableImage
                imageWidth={this.width}
                imageHeight={this.height}
                source={{uri: `${S3_IMAGES}${this.props.infograph.src}`}}
            />
        )
    }
}

export default InfographComponent;
