/*
    To make icons work on iOS when building the project:
    https://github.com/oblador/react-native-vector-icons/issues/1074#issuecomment-611683982
*/

import 'react-native-gesture-handler';
import React, { Component } from 'react';
import { Text, StatusBar, Platform, View } from 'react-native';
import stateMachine from './src/StateMachine';
import { NavigationContainer, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { RootStackParamList, Tutorial, Slide } from './src/types';
import BottomNavBar from './src/components/bottomNavBar';
import PALETTE from './src/CFISP_utils/palette';
import LogoutButton from './src/components/logoutButton';
import { GoToLoginScreenTextButton, FavoriteButton } from './src/components';
import {
    EvaluationScreen,
    SummaryScreen,
    PaymentContractScreen,
    PaymentModeScreen,
    PaymentInstalmentPlansScreen,
    PaymentFiscalDataScreen,
    PaymentConfirmationScreen,
    TutorialScreen,
    SplashScreen,
    LessonScreen,
    SubjectScreen,
    CoursesInFieldScreen,
    CourseScreen,
    LoginScreen,
    RegisterScreen
} from './src/screens';
import http from './src/http';

const Stack = createStackNavigator<RootStackParamList>();

const linking = {
    prefixes: ['https://cfisp.com.br'],
    config: {
        screens: {
            HomeScreen: '',
            SearchScreen: 'search',
            OwnedCoursesScreen: 'my-courses',
            ProfileScreen: 'profile'
        }
    },
};

type Props = any;
type State = {
    // loggedIn: boolean
    showSplashScreen: boolean,
    haveSeenWelcomeTutorial: boolean
}

export default class Main extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { showSplashScreen: true, haveSeenWelcomeTutorial: stateMachine.haveSeenTutorial[0] };

        stateMachine.setApp(this);

        http.fetchCoursesInFieldAnonimously(1, () => {})
        http.fetchCoursesInFieldAnonimously(2, () => {})
        http.fetchCoursesInFieldAnonimously(3, () => {})
    }

    getHeaderStyle(route: any, navigation: any) {
        stateMachine.setNavigation(navigation);

        const routeName = getFocusedRouteNameFromRoute(route);
        // const routeName = getFocusedRouteNameFromRoute(route) ?? 'Home';

        let headerTitle: string = '';
        let headerShown: boolean = false;
        let headerStyle: any;
        let headerTintColor: string = 'white';
        let headerTitleAlign: string = 'left';
        let headerLeft: any = () => <HeaderBackButton tintColor='white' onPress={() => navigation.goBack()} />
        let headerRight: any = null;

        switch (routeName) {
            case 'Tutorial':
                headerShown = false;
                break;
            case 'Home':
                if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.darkYellow_b)
                StatusBar.setBarStyle('dark-content');
                headerShown = false;
                break;
            
            /* ----- ECOMMERCE FEATURE ----- */
            // case 'Search':
            //     if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.darkYellow_b)
            //     StatusBar.setBarStyle('dark-content');
            //     headerShown = false;
            //     break;
            case 'OwnedCourses':
                if (Platform.OS == "android") StatusBar.setBackgroundColor('white')
                if (!stateMachine.authenticated) headerRight = () => <GoToLoginScreenTextButton />;
                StatusBar.setBarStyle('dark-content');
                headerTitle = 'Meus cursos';
                headerShown = true;
                headerStyle = { backgroundColor: PALETTE.almostWhite, height: 110 };
                headerTintColor = PALETTE.darkGray;
                headerLeft = () => <HeaderBackButton tintColor={PALETTE.darkGray} onPress={() => navigation.goBack()} />
                break;
            case 'Favorites':
                if (Platform.OS == "android") StatusBar.setBackgroundColor('white')
                StatusBar.setBarStyle('dark-content');
                headerTitle = 'Favoritos';
                headerShown = true;
                headerStyle = { backgroundColor: PALETTE.almostWhite, height: 110 }
                headerTintColor = PALETTE.darkGray
                headerLeft = () => <HeaderBackButton tintColor={PALETTE.darkGray} onPress={() => navigation.goBack()} />
                break;
            case 'Profile':
                if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.darkBlue);
                StatusBar.setBarStyle('light-content');
                headerShown = true;
                headerTitle = 'Perfil';
                headerTintColor = 'white';
                headerStyle = { backgroundColor: PALETTE.gayBlue };
                headerLeft = null;
                headerTitleAlign = 'left';
                if (stateMachine.authenticated) headerRight = () => <LogoutButton />;
                break;
            case 'Login':
                if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.darkBlue);
                StatusBar.setBarStyle('light-content');
        }

        return { headerTitle, headerTitleAlign, headerTintColor, headerShown, headerStyle, headerLeft, headerRight }
    }

    render() {
        let s0: Slide = { ilustration: 0 }
        let s1: Slide = { ilustration: 1 }
        let s2: Slide = { ilustration: 2 }
        let s3: Slide = { ilustration: 3 }
        let s4: Slide = { ilustration: 4 }
        let tutorial: Tutorial = { slides: [s0, s1, s2, s3, s4] };

        if (this.state.showSplashScreen) {
            return <SplashScreen parent={this}></SplashScreen>
        } else
        return (
            <NavigationContainer
                linking={linking}
                // fallback={HomeScreen}
            >
                <Stack.Navigator
                    initialRouteName={
                        Platform.OS == "web"
                            ? "Tabs"
                            : this.state.haveSeenWelcomeTutorial
                                ? "Tabs"
                                : "Tutorial"
                    }
                >
                    <Stack.Screen
                        name="Tutorial"
                        component={ TutorialScreen }
                        initialParams={ { tutorial: tutorial } }
                        options={({ route, navigation }) => this.getHeaderStyle(route, navigation)}
                    />
                    <Stack.Screen
                        name="Tabs"
                        component={ BottomNavBar }
                        options={({ route, navigation }) => this.getHeaderStyle(route, navigation)}
                    />
                    <Stack.Screen
                        name="Login"
                        component={ LoginScreen }
                        options={{ 
                            title: 'Entrar',
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerStyle: { backgroundColor: PALETTE.gayBlue },
                            headerTintColor: 'white',
                        }}
                    />
                    <Stack.Screen
                        name="Register"
                        component={ RegisterScreen }
                        options={{ 
                            title: 'Criar conta',
                            headerShown: true,
                            headerTitleAlign: 'center',
                            headerStyle: { backgroundColor: PALETTE.gayBlue },
                            headerTintColor: 'white',
                        }}
                    />
                    <Stack.Screen
                        name="Course"
                        component={ CourseScreen }
                        options={{
                            title: 'Curso',
                            headerShown: true,
                            headerTintColor: PALETTE.darkGray,
                            headerTitleAlign: 'center',
                            headerStyle: { backgroundColor: PALETTE.yellow },
                            headerRight: () => (
                                <FavoriteButton />
                            )
                        }}
                    />
                    <Stack.Screen
                        name="CoursesInField"
                        component={ CoursesInFieldScreen }
                    />
                    <Stack.Screen
                        name="PaymentContract"
                        component={ PaymentContractScreen }
                        options={{ title: 'Contrato' }}
                    />
                    <Stack.Screen
                        name="PaymentMode"
                        component={ PaymentModeScreen }
                        options={{ title: 'Modo de pagamento' }}
                    />
                    <Stack.Screen
                        name="PaymentInstalmentPlans"
                        component={ PaymentInstalmentPlansScreen }
                        options={{ title: 'Parcelamento' }}
                    />
                    <Stack.Screen
                        name="PaymentFiscalData"
                        component={ PaymentFiscalDataScreen }
                        options={{ title: 'Dados fiscais' }}
                    />
                    <Stack.Screen
                        name="PaymentConfirmation"
                        component={ PaymentConfirmationScreen }
                        options={{ title: 'Confirmação' }}
                    />
                    <Stack.Screen
                        name="Subject"
                        component={ SubjectScreen }
                        options={{
                            title: 'Disciplina',
                            headerShown: true,
                            headerStyle: { backgroundColor: PALETTE.veryLightGreen }
                        }}
                    />
                    <Stack.Screen
                        name="Lesson"
                        component={ LessonScreen }
                        options={{ title: 'Lição', headerShown: true }}
                    />
                    <Stack.Screen
                        name="Evaluation"
                        component={ EvaluationScreen }
                    />
                    <Stack.Screen
                        name="Summary"
                        component={ SummaryScreen }
                    />
                </Stack.Navigator>
            </NavigationContainer>
        );
    }
}
