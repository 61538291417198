import React, { Component } from 'react';
import { StyleSheet, ActivityIndicator, View, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import { User, MaritalStatus } from '../user';
import ProfileDataDisplayItem from './ProfileDataDisplayItem';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';

interface Props {
    user: User,
    intendedCourse: Course
}

export default class ProfileDataDisplay extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    getMaritalStatusString(maritalStatus: MaritalStatus) : string {
        switch (maritalStatus) {
            case MaritalStatus.SINGLE:
                return "solteiro(a)";
            case MaritalStatus.MARRIED:
                return "casado(a)";
            case MaritalStatus.DIVORCED:
                return "divorciado(a)";
            case MaritalStatus.WIDOW:
                return "viúvo(a)"
            case MaritalStatus.SEPARATED:
                return "separado(a)";
        }
    }

    render() {
        return  (
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_k,
                padding: 10
            }}>
                <ProfileDataDisplayItem label="Primeiro nome:" value={this.props.user.firstName} />
                <ProfileDataDisplayItem label="Último nome:" value={this.props.user.lastName} />
                <ProfileDataDisplayItem label="Nome completo:" value={this.props.user.fullName} />
                <ProfileDataDisplayItem label="CPF:" value={this.props.user.idCPF} />
                <ProfileDataDisplayItem label="RG:" value={this.props.user.idRG} />
                <ProfileDataDisplayItem label="Data de nascimento:" value={`${this.props.user.dateOfBirth}`} />
                <ProfileDataDisplayItem label="Cidade de nascimento:" value={this.props.user.cityOfBirth} />
                <ProfileDataDisplayItem label="Estado civil:" value={this.getMaritalStatusString(this.props.user.maritalStatus)} />
                <ProfileDataDisplayItem label="Fone:" value={this.props.user.phone} />
                <ProfileDataDisplayItem label="Endereço:" value={this.props.user.address} />
                <ProfileDataDisplayItem label="CEP:" value={this.props.user.postalCode} />
                <ProfileDataDisplayItem label="Graduação:" value={this.props.user.graduation} />
                <ProfileDataDisplayItem label="Ano de graduação:" value={this.props.user.graduationYear} />
                <ProfileDataDisplayItem label="Instituição de graduação:" value={this.props.user.graduationInstitution} />
                <ProfileDataDisplayItem label="Cidade de graduação:" value={this.props.user.graduationCity} />
                <ProfileDataDisplayItem label="Curso pretendido:" value={`${this.props.intendedCourse.code} | ${this.props.intendedCourse.title}`} />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    },
    fiscalDataDisplayItem: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fiscalDataDisplayLabelContainer: {
        flex: 1
    },
    displayLabelText: {
        textAlign: 'right',
        marginRight: 7
    },
    fiscalDataDisplayValueContainer: {
        flex: 3
    },
    displayValueText: {
        fontWeight: 'bold'
    },
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    },
});
