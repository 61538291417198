import { Platform, Alert } from 'react-native';

function multiplatformAlert(message: string) {
    if (Platform.OS == "web") alert(message);
    else Alert.alert(message);
}

function multiplatformPrompt(title: string, message: string, acceptText: string, acceptAction: any, cancelText?: string, cancelAction?: any) {
    if (Platform.OS == "web") {
        if (confirm(`${title} ${message}`)) {
            acceptAction();
        } else {
            if (cancelText && cancelAction) {
                cancelAction();
            }
        }
    } else {
        if (cancelText && cancelAction) {
            Alert.alert(
                title,
                message,
                [
                    {
                        text: cancelText,
                        onPress: () => { cancelAction(); },
                    },
                    {
                        text: acceptText,
                        onPress: () => { acceptAction(); }
                    }
                ],
                { cancelable: false }
            );
        } else {
            Alert.alert(
                title,
                message,
                [
                    {
                        text: acceptText,
                        onPress: () => { acceptAction(); }
                    }
                ],
                { cancelable: false }
            );
        }
    }
}

function formatPriceInBRL(price: Number): string {
    let formated = `R$ ${price}`;
    formated = formated.replace(".", ",");

    return formated;
}

function isValidCPF(cpf: string): boolean {
    cpf = cpf.replace(/[\s.-]*/igm, '')

    if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
        return false;
    }

    let sum = 0
    var remainder
    for (var i = 1; i <= 9; i++) 
        sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i)
    remainder = (sum * 10) % 11
    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(cpf.substring(9, 10)) ) return false
    sum = 0
    for (var i = 1; i <= 10; i++) 
        sum += parseInt(cpf.substring(i-1, i)) * (12 - i)
    remainder = (sum * 10) % 11
    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(cpf.substring(10, 11) ) ) return false;

    return true;
}

function deepCopy(input: any) {
    let copy: any;
    let value: any;
    let key: any;
  
    if (typeof input !== "object" || input === null) {
        return input;
    }

    copy = Array.isArray(input) ? [] : {};

    for (key in input) {
        value = input[key];
        copy[key] = deepCopy(value);
    }
  
    return copy;
}

export { multiplatformAlert, multiplatformPrompt, formatPriceInBRL, isValidCPF, deepCopy }
