import React, { Component } from 'react';
import { Dimensions, Image, ActivityIndicator, View } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

const win = Dimensions.get('window');

type Props = {
    imageURI: string
};

class ImageFullWidth extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around'
            }}>
                <img
                    src={this.props.imageURI}
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        maxHeight: "100%"
                    }}
                ></img>
            </View>
        )
    }
}

const ilustrationMargin = 1;

export default ImageFullWidth;
