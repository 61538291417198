export default class PaymentCard {
    constructor(
        card_name: string,
        description: string,
        client_id: Number,
        card_number: string,
        cvv: string,
        expires: Date,
        document_id: string,
        zipcode: string,
        country: string,
        state: string,
        city: string,
        neighborhood: string,
        address: string,
        habitation_number: number,
        habitation_complement: string,
    ) {
        this.card_name = card_name;
        this.description = description;
        this.client_id = client_id;
        this.card_number = card_number;
        this.cvv = cvv;
        this.expires = expires;
        this.document_id = document_id;
        this.zipcode = zipcode;
        this.country = country;
        this.state = state;
        this.city = city;
        this.neighborhood = neighborhood;
        this.address = address;
        this.habitation_number = habitation_number;
        this.habitation_complement = habitation_complement;
    }

    card_name: string;
    description: string;
    client_id: Number;
    card_number: string;
    cvv: string;
    expires: Date;
    document_id: string;
    zipcode: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string;
    address: string;
    habitation_number: number;
    habitation_complement: string;
    brand: string = "";
    last_numbers: string = "";
    card_token: string = "";

    setBrand(brand: string) {
        this.brand = brand;
    }

    getBrand() {
        return this.brand;
    }

    setLastNumber(last_number: string) {
        this.last_numbers = last_number;
    }

    getLastNumber() {
        return this.last_numbers;
    }

    setCardToken(token: string) {
        this.card_token = token;
    }

    getCardToken() {
        return this.card_token;
    }
}
