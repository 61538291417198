import * as React from "react"
import Svg, { Defs, ClipPath, Use, Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Defs id="prefix__defs849">
        <ClipPath id="prefix__SVGID_2_">
          <Use
            xlinkHref="#prefix__SVGID_1_"
            id="prefix__use838"
            overflow="visible"
          />
        </ClipPath>
        <Defs id="prefix__defs836">
          <Path id="prefix__SVGID_1_" d="M0 0h24v24H0z" />
        </Defs>
      </Defs>
      <Path
        d="M22 4l-2-2-8 8-8-8-2 2 8 8-8 8 2 2 8-8 8 8 2-2-8-8z"
        id="prefix__path"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#444"
      />
    </Svg>
  )
}

export default SvgComponent
