import React, { Component } from 'react';
import { StyleSheet, View, Text, Pressable, Platform } from 'react-native';
import { Boolean } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { BooleanPTBR } from '../../icons';
import { CenterButton } from '../../components';
import stateMachine from '../../StateMachine';
import { Correct, Wrong } from '../../icons';
import Markdown from "react-native-markdown-display";
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

const mainIconSize = 70;

type Props = {
    boolean: Boolean,
    parent: any
};

type State = {
    userChoice: boolean | undefined,
    showMustChooseWarning: boolean,
    sent: boolean
};

class BooleanComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            userChoice: undefined,
            showMustChooseWarning: false,
            sent: false
        }

        this.props.parent.setExplanationText(this.props.boolean.explanation);
    }

    select(choice: boolean) {
        this.setState({ ...this.state, userChoice: choice, showMustChooseWarning: false })
    }

    send() {
        if (this.state.userChoice == undefined) {
            this.setState({ ...this.state, showMustChooseWarning: true })
        } else {
            this.setState({ ...this.state, sent: true })
        }

        // this.props.parent.hideBottomSubjectNavBar();
        this.props.boolean.consume();
    }

    decideButtonStyle(button: boolean) {
        if (this.state.userChoice == button) {
            if (this.state.sent) {
                if (this.state.userChoice == this.props.boolean.correct) {
                    return styles.correct;
                } else {
                    return styles.wrong;
                }
            } else {
                return styles.selected;
            }
        } else {
            return styles.normal;
        }
    }

    showIcon(button: boolean) {
        if (this.state.userChoice == button) {
            if (this.state.sent) {
                if (this.state.userChoice == this.props.boolean.correct) {
                    return <Correct style={styles.resultIcon} />;
                } else {
                    return <Wrong style={styles.resultIcon} />;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    render() {
        return (
            <View style={styles.quizView}>
                <View style={styles.icon}>
                    <BooleanPTBR style={{ width: mainIconSize, height: mainIconSize }} />
                </View>
                <Text style={styles.title}>VERDADEIRO OU FALSO</Text>

                <Markdown rules={MarkdownCustomRules} style={{ body: styles.text }}>
                    { this.props.boolean.statement }
                </Markdown>

                <Text style={[styles.text, { fontWeight: 'bold', marginTop: 40 }]}>
                    Analise o trecho acima e julgue ser <Text style={{
                        color: PALETTE.gayBlue
                    }}>verdadeiro ou falso:</Text>
                </Text>
                
                <View style={styles.buttonContainer}>
                    <Pressable
                        style={[
                            styles.button,
                            this.decideButtonStyle(true)
                        ]}
                        onPress={() => {
                            if (!this.state.sent) this.select(true)
                        }}
                    >
                        { this.showIcon(true) }
                        <Text style={styles.buttonText}>
                            Verdadeiro
                        </Text>
                    </Pressable>
                    <View style={styles.space}></View>
                    <Pressable
                        style={[
                            styles.button,
                            this.decideButtonStyle(false)
                        ]}
                        onPress={() => {
                            if (!this.state.sent) this.select(false)
                        }}
                    >
                        { this.showIcon(false) }
                        <Text style={styles.buttonText}>
                            Falso
                        </Text>
                    </Pressable>
                </View>

                { this.state.sent ? <View style={{
                    marginTop: 30
                }}>
                    { this.props.boolean.explanation.length > 0 ?
                        <CenterButton
                            text="Ver gabarito"
                            action={() => {
                                this.props.parent.showExplanationPane();
                            }}
                            color={ PALETTE.gayBlue }
                            paddingVertical={8}
                            paddingHorizontal={60}
                            width="80%"
                        />
                    : null }
                    <CenterButton
                        text="Avançar"
                        action={() => {
                            this.props.parent.goForward();
                        }}
                        color={ PALETTE.gayGreen }
                        paddingVertical={8}
                        paddingHorizontal={60}
                        width="80%"
                        marginTop={16}
                    />
                    <Text style={[styles.text, { marginTop: 16, textAlign: 'center' }]}>
                        Voltar para <Text
                            style={{
                                color: PALETTE.gayBlue
                            }}
                            onPress={() => { stateMachine.goToThisSubjectList() }}
                        >lista de lições</Text>
                    </Text>
                </View> : <View style={{
                    marginTop: 30
                }}>
                    { this.state.showMustChooseWarning ? <Text style={[{ marginBottom: 5, color: PALETTE.red, textAlign: 'center' }]}>
                        Selecione uma das alternativas
                    </Text> : null }
                    <CenterButton
                        text="Enviar"
                        action={() => { this.send() }}
                        color={ this.state.userChoice == undefined ? PALETTE.lightGayBlue : PALETTE.gayBlue }
                        paddingVertical={8}
                        paddingHorizontal={60}
                    />
                </View> }
            </View>
        )
    }
}

const checkRadius = 45;
const padding = 22;

const styles = StyleSheet.create({
    quizView: {
        backgroundColor: PALETTE.almostWhite,
        margin: 24,
        marginBottom: 24+checkRadius,
        borderRadius: 20,
        padding: padding,
    },
    icon: {
        alignSelf: 'center',
        marginBottom: 10
    },
    title: {
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 10
    },
    text: {
        fontSize: 16,
        marginBottom: 10,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 20,
        marginTop: 30
    },
    buttonText: {
        margin: 16,
        fontSize: 14,
    },
    button: {
        flex: 7,
        marginTop: 12,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        alignItems: 'center',
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    space: {
        flex: 1
    },
    normal: {
        backgroundColor: PALETTE.background,
        borderColor: PALETTE.lightBlue,
    },
    selected: {
        backgroundColor: PALETTE.gray_m,
        borderColor: PALETTE.lightBlue
    },
    correct: {
        backgroundColor: PALETTE.lightGayGreen,
        borderColor: PALETTE.anotherGreen
    },
    wrong: {
        backgroundColor: PALETTE.lightRed,
        borderColor: PALETTE.red
    },
    check: {
        width: checkRadius*2,
        height: checkRadius*2,
        position: 'relative',
        bottom: -checkRadius - padding,
        alignSelf: 'center'
    },
    resultIcon: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: -5,
        top: -5
    }
})

export default BooleanComponent;
