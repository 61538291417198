import React, { Component } from 'react';
import { Image, StyleSheet, Text, View, ScrollView, Pressable, ActivityIndicator } from 'react-native';
import stateMachine from '../StateMachine';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import { SERVER_URL, S3_IMAGES } from "../CFISP_utils/environmentConstants";
import { version } from '../../package.json';
import PALETTE from '../CFISP_utils/palette';
import http from '../http';
import { Course } from '../content_types';
import { formatPriceInBRL } from '../CFISP_utils/functions';
import { RemoveFromFavoritesButton, CenterButton } from '../components';
import { HeartFull } from '../icons';

type FavoriteScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: FavoriteScreenNavigationProp,
}

export interface State {
    authenticated: boolean;
    courses: Course[];
    waiting: boolean;
}

class Favorites extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Favorites'
    };

    constructor(props: Props) {
        super(props)
        this.state = { authenticated: stateMachine.authenticated, courses: [], waiting: true }
        this._isMounted = false;
    }

    _isMounted: boolean = false;

    componentDidMount() {
        this._isMounted = true;
        stateMachine.setFavoriteScreen(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderItems() {
        return this.state.courses.map((course, index) => {
            return <View key={index} style={{
                flex: 1,
                flexDirection: 'row',
                backgroundColor: PALETTE.lightGrey,
                 height: 140,
                marginBottom: 15,
                padding: 18
            }}>
                <View style={{
                    flex: 2,
                }}>
                    <Image source={{ uri: `${S3_IMAGES}course-covers/${course.image}` }} style={{ width: 80, height: 80 }}></Image>
                </View>
                <View style={{
                    flex: 5,
                    flexDirection: 'column',
                }}>
                    <View style={{
                        flex: 2,
                    }}>
                        <Text style={{
                            color: PALETTE.forground
                        }}>{ course.title }
                        {/* { course.description.length > 0 ? ` - ${course.description}` : "" } */}
                        </Text>
                    </View>
                    <View style={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'space-around'
                    }}>
                        <Text style={{
                            color: PALETTE.gray_l,
                            fontSize: 12
                        }}>{ course.workload } horas</Text>
                    </View>
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}>
                        <View><Text style={{
                            color: PALETTE.forground,
                            fontSize: 14
                        }}>{ formatPriceInBRL(course.price) }</Text></View>
                        <RemoveFromFavoritesButton course={course} />
                    </View>
                </View>
            </View>
        })
    }

    render () {
        if (this.state.waiting) return <View style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-around"
        }}>
            <ActivityIndicator color={PALETTE.forground} size="large" />
        </View>

        if (this.state.courses.length === 0) return <View style={{
            flex: 1,
            flexDirection: 'column',
            paddingHorizontal: 20
        }}>
            <View style={{
                flex: 3,
                justifyContent: 'center',
                alignItems: 'center'
            }}><HeartFull width={200} height={200} color={PALETTE.gray_p} /></View>
            <View style={{
                flex: 1,
                justifyContent: 'space-evenly',
            }}>
                <Text style={{
                    textAlign: 'center',
                    color: PALETTE.darkBlue,
                    fontSize: 18
                }}>Salve sua lista de interesse!</Text>
                <Text style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: PALETTE.gray_c
                }}>{`Marque com <3 os cursos de interesse para salvá-los aqui`}</Text>
            </View>
            {/* <View style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-around'
            }}>
                <View style={{
                    height: 50
                }} >
                    <CenterButton action={() => {
                        this.props.navigation.navigate("Search")
                    }} color={PALETTE.blue} text="Buscar curso" fontSize={20} />
                </View>
            </View> */}
        </View>

        return <ScrollView style={{
            backgroundColor: PALETTE.background
        }}>
            { this.renderItems() }
        </ScrollView>
    }
}

export default Favorites;
