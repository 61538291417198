import React, { Component, Fragment } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../types';
import { StyleSheet, SafeAreaView, ScrollView, Pressable, View, Text, Dimensions, StatusBar, Image, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import { Top, ArrowLeft, ArrowRight } from '../../icons';

const win = Dimensions.get('window');
const barHeight = 80;

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

interface Props {
    parent: any,
    resourceIndex: number,
    resource: any,
    navigation: ProfileScreenNavigationProp,
    lessonIndex: number,
    isInteraction: boolean,
};

interface State {
    showGoToTop: boolean,
}

export default class ResourceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showGoToTop: false,
        }
    }

    scrollViewRef: ScrollView | null = null;

    render() {
        let statusBarHeight = 0;
        
        return (
            <SafeAreaView>
                <ScrollView
                    ref={(ref) => { this.scrollViewRef = ref }}
                    onContentSizeChange={(width, height) => {
                        if (height > win.height) {
                            this.setState({ showGoToTop: true })
                        } else {
                            this.setState({ showGoToTop: false })
                        }
                    }}
                >
                    <View
                        style={{minHeight: win.height - barHeight*2 - statusBarHeight}}
                    >
                        <View>{ this.props.resource }</View>
                    </View>

                    { this.state.showGoToTop ? <Pressable style={{
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        marginBottom: 60,
                        ...Platform.select({
                            web: {
                                cursor: "pointer"
                            }
                        })
                    }}
                        onPress={() => {
                            if (Platform.OS == "web") {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                });
                            } else {
                                this.scrollViewRef?.scrollTo({x: 0, y: 0, animated: true})
                            }
                        }}
                    >
                        <Top style={{ width: 26, height: 26 }} color={ this.props.isInteraction ? PALETTE.background : PALETTE.gray_j } />
                    </Pressable> : null }
                </ScrollView>
            </SafeAreaView>
        )
    }
}

const styles = StyleSheet.create({
    heading: {
        backgroundColor: PALETTE.lightGrey,
        height: barHeight,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    footer: {
        backgroundColor: PALETTE.lightGrey,
        width: '100%',
        height: barHeight,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    arrow: {
        margin: 20,
        width: 30,
        height: 30
    }
})
