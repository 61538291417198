import Resource from './resource';
import TimelineItem from './timelineItem';

class Timeline extends Resource {
    typeName: string = "Timeline";
    title: string;
    ilustration: string;
    text: string;
    items: TimelineItem[];
    nOfConsumed: number;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        title: string,
        ilustration: string,
        text: string
    ) {
        super(id, pub_date, consumed);
        this.title = title;
        this.ilustration = ilustration;
        this.text = text;
        this.items = [];
        this.nOfConsumed = 0;
    }

    setItems(items: TimelineItem[]) {
        this.items = items;
    }

    consumeItem(index: number) {
        if (this.items[index].consume()) {
            if (++this.nOfConsumed == this.items.length) super.consume();
        }
    }
}

export default Timeline;
