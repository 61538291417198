import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../types';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, Pressable, Platform } from 'react-native';
import CourseHeading from '../../components/courseHeading';
import { formatPriceInBRL } from '../../CFISP_utils/functions'
import PALETTE from '../../CFISP_utils/palette';
import { PaymentCard, PaymentClient } from '../../payment_types';
import { PaymentProductOverview, PaymentFiscalDataForm } from '../../components/paymentComponents';
import stateMachine from '../../StateMachine';
import { User } from '../../user';
import { Course } from '../../content_types';
import Markdown from 'react-native-markdown-display';
import { SERVER_URL } from '../../CFISP_utils/environmentConstants';
import { dummyContract } from '../../CFISP_utils/dummyContract';

type PaymentFiscalDataScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type PaymentFiscalDataScreenRouteProp = RouteProp<RootStackParamList, 'PaymentFiscalData'>;

interface Props {
    navigation: PaymentFiscalDataScreenNavigationProp,
    route: PaymentFiscalDataScreenRouteProp
}

interface State {
    waiting: boolean,
}

export default class PaymentFiscalDataScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Dados fiscais',
    };

    constructor(props: Props) {
        super(props)
        this.state = {
            waiting: false,
        }
    }

    next(fiscalData: PaymentClient) {
        this.props.navigation.navigate("PaymentConfirmation", {
            course: this.props.route.params.course,
            paymentMode: this.props.route.params.paymentMode,
            instalments: this.props.route.params.instalments,
            fiscalData
        })
    }

    render () {
        if (this.state.waiting) return (
            <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} />
        );

        return (
            <SafeAreaView>
                <ScrollView style={{ backgroundColor: PALETTE.background, padding: 13 }}>
                    <PaymentProductOverview title={this.props.route.params.course.title} price={this.props.route.params.course.price}/>
                    <View>
                        <PaymentFiscalDataForm parent={this} />
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}
