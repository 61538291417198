const PALETTE = {
    background: "#FFF",
    forground: "#000",
    blue: "#007aff",
    yellow: "#ffcc00",
    darkYellow: "#ffc002",
    darkYellow_b: '#FFC000',
    darkGray: "#222220",
    grey: "#8e8e8e",
    gray_b: "#DBDBDB",
    gray_c: "#9B9B9B",
    gray_d: "#868685",
    gray_e: "#444444",
    gray_f: "#efefef",
    gray_g: "#A8A8A8",
    gray_h: "#C7C7C7",
    gray_i: "#E7E8E8",
    gray_j: "#7C7C7C",
    gray_k: "#EDEDED",
    gray_l: "#6F6F6E",
    gray_m: "#DDDDDD",
    gray_n: "#3E3E3D",
    gray_o: "#B6BABA",
    gray_p: "#D7D7DB",
    lightGrey: "#e9e9eb",
    black: "#000000",
    almostBlack: "#060606",
    almostWhite: "#fcfcfc",
    almostWhite_b: "#fafafa",
    lightBlue: "#D1DEF1",
    darkBlue: "#363C55",
    gayBlue: "#556FE0",
    lightGayBlue: "#9FAADB",
    interestingBlue: "#097aff",
    lightGreen: "#55E0B5",
    lightGayGreen: "#bfeedd",
    gayGreen: "#7EE5CD",
    anotherGreen: "#32CC95",
    veryLightGreen: "#F3F6E6",
    red: "#E9472C",
    anotherRed: "#F35D4B",
    lightRed: "#F8D9D4"
}

export default PALETTE;
