import Resource from './resource';
import ExpandableListItem from './expandableListItem';

class ExpandableList extends Resource {
    typeName: string = "ExpandableList";
    title: string;
    text: string;
    illustration: string;
    expandables: ExpandableListItem[];
    nOfConsumed: number;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        title: string,
        text: string,
        illustration: string
    ) {
        super(id, pub_date, consumed);
        this.title = title;
        this.text = text;
        this.illustration = illustration;
        this.expandables = [];
        this.nOfConsumed = 0;
    }

    setExpandables(expandables: ExpandableListItem[]) {
        this.expandables = expandables;
    }

    consumeItem(index: number) {
        if (this.expandables[index].consume()) {
            if (++this.nOfConsumed == this.expandables.length) super.consume();
        }
    }
}

export default ExpandableList;
