import React, { Component } from 'react';
import { StyleSheet, ActivityIndicator, View, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import { User, MaritalStatus } from '../user';
import ProfileDataDisplayItem from './ProfileDataDisplayItem';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';

import { Picker } from '@react-native-picker/picker';

interface Props {
    maritalStatus: MaritalStatus,
    parent: any
}

interface State {
    maritalStatus: MaritalStatus,
}

export default class MaritalStatusPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { maritalStatus: this.props.maritalStatus }
    }

    setMaritalStatus(i: any) {
        this.setState({ ...this.state, maritalStatus: Number(i) })
        this.props.parent.updateMaritalStatus(Number(i));
    }

    render() {
        return  (
            <View>
                <Picker
                    selectedValue={this.state.maritalStatus}
                    style={{height: 50, width: 100}}
                    onValueChange={(itemValue, itemIndex) => {
                        this.setMaritalStatus(itemValue);
                    }}
                    >
                    <Picker.Item label="solteiro(a)" value={MaritalStatus.SINGLE} />
                    <Picker.Item label="casado(a)" value={MaritalStatus.MARRIED} />
                    <Picker.Item label="divorciado(a)" value={MaritalStatus.DIVORCED} />
                    <Picker.Item label="viúvo(a)" value={MaritalStatus.WIDOW} />
                    <Picker.Item label="separado(a)" value={MaritalStatus.SEPARATED} />
                </Picker>
            </View>
        )
    }
}
