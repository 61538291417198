import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path
        d="M11.2 6.5v4.8H6.4v1.6h4.8v4.8h1.6v-4.8h4.8v-1.6h-4.8V6.5h-1.6z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#444"
      />
    </Svg>
  )
}

export default SvgComponent
