import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, Pressable } from 'react-native';
import stateMachine from '../StateMachine';
import PALETTE from '../CFISP_utils/palette';
import ProgressBar from 'react-native-progress/Bar';
import Lesson from '../content_types/lesson';

type Props = {
    navigation: any,
    lesson: Lesson,
    key: number,
    index: number
};

type State = {
    progress: number
}

class LessonListItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { progress: props.lesson.progress }
        props.lesson.setListItem(this);
    }

    openLesson() {
        stateMachine.setLesson(this.props.lesson);
        this.props.navigation.navigate('Lesson');
    }

    render () {
        return (
            <Pressable
                style={styles.root}
                onPress={() => this.openLesson()}
            >
                <View style={styles.left}>
                    <Text style={styles.num}>{this.props.index+1}</Text>
                </View>
                <View style={styles.right}>
                    <Text style={styles.title}>{this.props.lesson.title}</Text>
                    <Text style={styles.description}>{this.props.lesson.description}</Text>
                    <View style={styles.progressBarContainer}>
                        <ProgressBar
                            progress={ this.state.progress }
                            width={null}
                            height={8}
                            unfilledColor={PALETTE.gray_b}
                            color={PALETTE.yellow}
                            borderWidth={0}
                        />
                    </View>
                </View>
            </Pressable>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: PALETTE.lightGrey,        
        borderRadius: 16,
        marginBottom: 16,
        marginHorizontal: 16,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    left: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    right: {
        flex: 3,
        flexDirection: 'column',
    },
    num: {
        borderRadius: 16,
        backgroundColor: PALETTE.gray_c,
        textAlign: 'center',
        textAlignVertical: 'center',
        width: 70,
        height: 70,
        fontSize: 16,
        padding: 4,
        fontWeight: '700',
        zIndex: 10,
    },
    title: {
        fontSize: 20,
        marginTop: 16,
    },
    description: {
        fontStyle: 'italic',
        color: 'white',
    },
    progressBarContainer: {
        paddingRight: 20,
        paddingBottom: 20,
    }
})

export default LessonListItem;
