import Resource from './resource';

class Interaction extends Resource {
    points: number;
    explanation: string;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string
    ) {
        super(id, pub_date, consumed);
        this.id = id;
        this.points = points;
        this.explanation = explanation;
    }
}

export default Interaction;
