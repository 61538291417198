import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path
        d="M11.8 12.8H5.9v-1.6h5.9-.6 5.9v1.6h-5.9"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#444"
      />
    </Svg>
  )
}

export default SvgComponent
