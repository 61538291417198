import React, { Component } from 'react';
import { Dimensions, ActivityIndicator, StatusBar, Platform, StyleSheet, Text, View, Image, ScrollView } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import LessonList from '../components/lessonList';
import Subject from '../content_types/subject';
import PALETTE from '../CFISP_utils/palette';
import SubjectTabNavigator from '../components/subjectTabNavigator';
import stateMachine from '../StateMachine';
import Markdown from 'react-native-markdown-display';
import { CenterButton, ImageFullWidth } from '../components';
import http from '../http';
import { EvaluationAttempt } from '../content_types'
import MarkdownCustomRules from '../CFISP_utils/markdownCustomRules';

const win = Dimensions.get('window');

type SubjectScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type SubjectScreenRouteProp = RouteProp<RootStackParamList, 'Subject'>;

export interface Props {
    navigation: SubjectScreenNavigationProp,
    route: SubjectScreenRouteProp
}

interface State {
    tab: number,
    isWaitingEvaluationStart: boolean,
    evaluationAttempts: EvaluationAttempt[]
}

class SubjectScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Disciplina',
    };

    rootRef: any;

    constructor(props: Props) {
        super(props)

        stateMachine.setSubjectScreen(this);

        this.state = { tab: 0, isWaitingEvaluationStart: false, evaluationAttempts: this.props.route.params.subject.evaluationAttempts }

        if (Platform.OS == "android") StatusBar.setBackgroundColor(PALETTE.almostWhite);
        StatusBar.setBarStyle('dark-content');
    }

    hasUserFinishedEvaluation(subject: Subject) {
        for (let i=0; i<subject.evaluation.length; i++) {
            if (subject.evaluation[i].answer != -1) {
                return false;
            }
        }

        return true;
    }

    hasUserAlreadyStartedEvaluation(subject: Subject) {
        for (let i=0; i<subject.evaluation.length; i++) {
            if (subject.evaluation[i].answer != -1) {
                return true;
            }
        }

        return false;
    }

    evaluationResults() {
        let nOfQuestions = this.props.route.params.subject.evaluation.length;

        return this.state.evaluationAttempts.map((attempt, index) => {
            let startTime = "";
            let endTime = "";
            
            if (attempt.startTimestamp) {
                startTime = new Date(attempt.startTimestamp).toLocaleString();
            }
            
            if (attempt.endTimestamp) {
                endTime = new Date(attempt.endTimestamp).toLocaleString();
            }

            return <View style={{
                backgroundColor: PALETTE.almostWhite_b,
                padding: 7,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 27,
                borderRadius: 5
            }} key={index}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 12
                }}>
                    <Text style={{ fontStyle: 'italic' }}>Tentativa {index+1}</Text>
                    { attempt.grade
                        ? <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                            <Text style={ attempt.grade >= 7
                                ? { fontWeight: 'bold', fontSize: 20, color: PALETTE.anotherGreen }
                                : { fontWeight: 'bold', fontSize: 20, color: PALETTE.red }
                            }>{attempt.grade}</Text><Text>/{nOfQuestions}</Text>
                        </View>
                        : <View><Text></Text></View>
                    }
                </View>
                <View>
                    <Text>Início: {startTime}</Text>
                </View>
                <View>
                    <Text>Fim: {endTime}</Text>
                </View>
            </View>
        })
    }

    computeHighestGrade() {
        let highest = 0;

        for (let i=0; i<this.state.evaluationAttempts.length; i++) {
            if (this.state.evaluationAttempts[i].grade > highest) {
                highest = this.state.evaluationAttempts[i].grade;
            }
        }

        return highest;
    }

    renderActionButton() {
        let evaluationAttempts = this.state.evaluationAttempts;

        if (evaluationAttempts.length == 0) {
            return (
                <CenterButton
                    text="Iniciar avaliação"
                    color={PALETTE.gayBlue}
                    action={() => {
                        this.setState({ ...this.state, isWaitingEvaluationStart: true })
                        http.startEvaluation(this.props.route.params.subject.id, (res) => {
                            let evaluationAttempt = new EvaluationAttempt(
                                res.id,
                                res.startTimestamp,
                                res.endTimestamp,
                                res.grade
                            );

                            stateMachine.addEvaluationAttemptToSubject(evaluationAttempt);
                            
                            this.setState({ ...this.state, evaluationAttempts: [evaluationAttempt], isWaitingEvaluationStart: false }, () => {
                                stateMachine.navigation.navigate("Evaluation", { subject: this.props.route.params.subject })
                            })
                        })
                    }}
                />
            )
        } else if (!evaluationAttempts[evaluationAttempts.length - 1].endTimestamp) {
            return (
                <CenterButton
                    text="Continuar avaliação"
                    color={PALETTE.gayBlue}
                    action={() => {
                        stateMachine.navigation.navigate("Evaluation", { subject: stateMachine.subject })
                    }}
                />
            )
        } else if (evaluationAttempts.length >= 2 || this.computeHighestGrade() >= 7) {
            return null
        } else {
            return (
                <CenterButton
                    text="Tentar novamente"
                    color={PALETTE.gayBlue}
                    action={() => {
                        this.setState({ ...this.state, isWaitingEvaluationStart: true })
                        http.startEvaluation(this.props.route.params.subject.id, (res) => {
                            let evaluationAttempt = new EvaluationAttempt(
                                res.id,
                                res.startTimestamp,
                                res.endTimestamp,
                                res.grade
                            )

                            stateMachine.addEvaluationAttemptToSubject(evaluationAttempt);

                            let previousAttempts = this.state.evaluationAttempts;

                            let addToState = true;
                            for (let i=0; i<previousAttempts.length; i++) {
                                if (previousAttempts[i].id === evaluationAttempt.id) {
                                    addToState = false;
                                }
                            }

                            if (addToState) previousAttempts.push(evaluationAttempt);

                            this.setState({ ...this.state, evaluationAttempts: previousAttempts, isWaitingEvaluationStart: false }, () => {
                                let subject = this.props.route.params.subject;

                                for (let i=0; i<subject.evaluation.length; i++) {
                                    subject.evaluation[i].answer = -1;
                                }

                                stateMachine.navigation.navigate("Evaluation", { subject: subject });
                            })
                        })
                    }}
                />
            )
        }
    }

    scrollToTop() {
        this.rootRef.scrollTo({x: 0, y: 200, animated: true})
    }

    render () {
        let subject: Subject = this.props.route.params.subject;

        let tab = <View style={styles.tab}>
            <LessonList
                navigation={this.props.navigation}
                subject={this.props.route.params.subject}
            />
        </View>

        if (this.state.tab == 1) tab = <View style={[styles.tab, {padding: 20}]}>
            <View style={{ marginBottom: 30 }}>
                <Text>Aqui você terá acesso a um resumo sobre o que foi abordado em cada uma das lições desta disciplina. Explore esse conteúdo e, caso julgue necessário, retorne às lições, para garantir que você domina os assuntos que serão cobrados na avaliação final.</Text>
            </View>
            { this.props.route.params.subject.summaries.length > 0 ? <CenterButton
                text="Iniciar revisão"
                color={PALETTE.gayBlue}
                action={() => {
                    stateMachine.navigation.navigate("Summary", { subject: this.props.route.params.subject })
                }}
            ></CenterButton> : <Markdown rules={ MarkdownCustomRules }>{`[Apostila](https://didactic-material-assets.s3-sa-east-1.amazonaws.com/papers/S${this.props.route.params.subject.id}.pdf)`}</Markdown> }
        </View>
        else if (this.state.tab == 2) tab = <View style={[styles.tab, {padding: 20}]}>
            <View style={{ marginBottom: 30 }}>
                <Text>Agora que domina o conteúdo das lições, você pode realizar a avaliação final desta disciplina para validar que você a concluiu. Ela é composta por 10 questões, que abordarão os conteúdos principais trabalhados ao longo das lições. Antes de começar, tenha em mente que você deve finalizar essa avaliação em no máximo 2 horas e que precisa acertar, no mínimo, 70% dela para que obtenha o certificado de conclusão desta disciplina.</Text>
            </View>
            { this.evaluationResults() }
            { this.state.isWaitingEvaluationStart
                ? <ActivityIndicator />
                : this.renderActionButton()
            }
            
        </View>
        else if (this.state.tab == 3) tab = <View style={[ styles.tab, { padding: 10 }]}>
            <Markdown rules={ MarkdownCustomRules }>{subject.syllabus}</Markdown>
        </View>

        return (
            <ScrollView ref={(ref) => { this.rootRef = ref; }} style={{ backgroundColor: PALETTE.background }}>
                {/* <SubjectHeading navigation={this.props.navigation} text={`Disciplina ${stateMachine.subjects.indexOf(subject) + 1}`}/> */}
                <ImageFullWidth imageURI={subject.image} />
                <Text style={styles.title}>{subject.title}</Text>
                <Text style={styles.author}>Por {subject.author}</Text>
                <SubjectTabNavigator tab={this.state.tab} parent={this} />
                { tab }
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    back: {
        color: PALETTE.gray_e,
        marginLeft: 20
    },
    titleContainer: {
        marginTop: 20,
        marginLeft: 10,
    },
    label: {
        fontStyle: 'italic',
        color: 'darkgrey',
    },
    title: {
        fontSize: 22,
        color: 'black',
        // fontFamily: 'Nunito-SemiBold',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingTop: 10,
        backgroundColor: PALETTE.lightGrey
    },
    author: {
        textAlign: 'center',
        color: PALETTE.gray_d,
        fontSize: 18,
        backgroundColor: PALETTE.lightGrey
    },
    tab: {
        backgroundColor: PALETTE.background,
    }
})

export default SubjectScreen;
