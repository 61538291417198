const dummyContract = `# Contrato
v1.0

## DO ESTUDANTE

Lorem ipsum dolor sit amet, **VAR_STUDENT_FULL_NAME**, CPF **VAR_STUDENT_CPF**, residente em **VAR_STUDENT_ADDRESS** consectetur adipiscing elit. Curabitur lacus leo, lobortis ac lectus ut, euismod sollicitudin augue. Nulla facilisi. Nulla interdum nisi at lectus ullamcorper ultrices. Integer lorem urna, lacinia vel imperdiet tincidunt, congue rutrum enim. Donec quis tincidunt nulla. Nullam vitae ex volutpat, volutpat ipsum at, lacinia erat. Vivamus lacinia efficitur elit vitae dictum.

## DO OBJETO

Nam **VAR_COURSE_NAME**, código VAR_COURSE_CODE vitae felis in vestibulum. Suspendisse potenti. Nam cursus, nisi id ultrices facilisis, lacus magna interdum elit, vitae sollicitudin neque augue vitae eros. Nam turpis risus, dapibus id semper ut, tempor id felis. Nam luctus imperdiet lectus molestie maximus. Sed nulla erat, vehicula a dui a, rhoncus rutrum urna. In hac habitasse platea dictumst. Donec sem augue, fringilla nec orci ac, consectetur euismod metus. Aenean sit amet lobortis felis. Ut dictum arcu blandit, tempus purus at, sagittis est. Donec id rhoncus arcu. Proin sit amet sodales sapien.

Integer placerat bibendum risus in luctus. Nunc finibus porta libero, eu hendrerit ante maximus eget. Etiam egestas magna turpis, eget ultrices eros dapibus sit amet. Aliquam non ipsum turpis. In hac habitasse platea dictumst. Sed pulvinar vehicula lacus a rhoncus. Aliquam quis urna a sem pretium egestas. Ut accumsan diam eu ullamcorper fringilla. Fusce tincidunt quam sed venenatis vulputate. Sed ut feugiat neque.

Fusce sed pellentesque erat, sed consectetur quam. Suspendisse cursus turpis et efficitur feugiat. Duis auctor vel turpis et feugiat. Curabitur a mi porta, dictum lorem vitae, tempus turpis. Aenean eget nisi sollicitudin, vulputate dolor et, tristique magna. Sed condimentum porttitor magna sit amet dictum. Nam nec urna at arcu feugiat finibus eu sit amet sapien. Nulla eleifend erat enim, eu malesuada magna vehicula aliquet.

Nam rutrum arcu massa, a sodales nulla semper vitae. Fusce nec facilisis eros, ut vehicula quam. Maecenas hendrerit, nisi vitae pretium fringilla, diam velit gravida arcu, at fringilla ante ante vel elit. Nam blandit ipsum eu dui eleifend lacinia. Donec sed ultrices urna, nec ultrices lacus. Donec euismod dolor risus, at euismod nisi consectetur sit amet. Sed neque justo, volutpat ut accumsan eu, dictum nec ante. In tempor egestas nisl, nec malesuada sem ultricies id. Vestibulum id sem sed quam sodales pharetra lacinia ut diam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus aliquam eu mi ac interdum.

Proin sed ultrices ipsum, tincidunt maximus erat. Duis tincidunt feugiat tempor. Nulla non porta lectus, luctus ultricies sapien. Maecenas egestas ornare mollis. Curabitur sollicitudin facilisis enim, non rutrum arcu sodales eget. Sed eu eros ac tortor ultricies interdum. Maecenas aliquet pharetra dictum. Aliquam nunc nulla, viverra a sem sit amet, tincidunt semper sapien. Suspendisse arcu elit, posuere vitae malesuada eget, rutrum sed quam. Nulla molestie mauris nec ex tempus, eu egestas ex placerat. Etiam tempor consequat ex, a scelerisque justo. Etiam at porta nulla.

Nunc mattis convallis tellus et faucibus. Vestibulum at metus a urna efficitur cursus. Nam vitae mi vitae ipsum malesuada ornare cursus ut neque. Nunc et turpis vehicula, pretium massa non, dapibus orci. Sed sollicitudin nisi quis neque sodales auctor. Nunc ac malesuada nulla. Nunc at placerat eros. Nunc eget placerat lectus. Aliquam fringilla libero nisl, a fringilla elit porttitor non. Nullam ligula arcu, tincidunt a turpis id, aliquet ullamcorper neque. Fusce vitae risus in orci semper congue et nec diam. Quisque id est ac eros pulvinar dapibus.

Sed egestas eleifend leo quis efficitur. Nam rutrum iaculis dolor, vel mattis mauris vehicula a. Cras id neque sit amet lacus semper maximus sit amet non arcu. Pellentesque vel lectus pretium, scelerisque augue et, elementum dui. Donec blandit sit amet arcu vel suscipit. In iaculis ipsum id risus suscipit eleifend. Nulla efficitur cursus tortor, at imperdiet tellus sodales quis. Mauris vitae ligula tortor. Maecenas lacus tellus, tristique ut luctus eu, iaculis et tortor. Quisque vitae lectus ipsum. Vestibulum et viverra augue. Nullam a laoreet arcu. Vivamus tempor odio quis ante scelerisque convallis ut sed tortor. Ut eleifend quam dui, sed consequat dui fermentum ut. Nunc viverra gravida ex vel ultrices.

Donec eu ante quis tellus maximus fermentum a vitae libero. Quisque aliquam cursus augue, vel fringilla magna suscipit a. Donec vitae dignissim ante. Aliquam tempus eu est a rhoncus. Sed id placerat libero. Etiam at ipsum a nisi accumsan faucibus ac in nulla. Curabitur at nulla pharetra, accumsan odio ut, egestas justo. Praesent vel dapibus sapien. Aliquam arcu diam, commodo eu felis nec, posuere vehicula velit. Donec ipsum tellus, porttitor et molestie fermentum, commodo sed enim. Maecenas viverra nibh sed nisi imperdiet faucibus in in dui. Sed volutpat lorem in sapien sodales volutpat et et nibh.

Aliquam gravida, lorem sit amet sodales aliquam, felis purus consequat turpis, a dapibus sapien ipsum feugiat mi. Nullam pulvinar ex pellentesque pretium congue. Sed a ex a ante suscipit pharetra vel rhoncus quam. Mauris sed odio mi. Donec id diam nec metus molestie condimentum. Donec quis maximus leo. Nullam egestas arcu sit amet mauris ultrices condimentum. Integer sit amet erat iaculis, pellentesque tellus et, tristique sapien. Suspendisse id neque consectetur, tempor massa sit amet, tempor libero.

Morbi egestas tincidunt metus, eget interdum risus gravida vitae. Cras sed lobortis ante. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas accumsan sem dolor, sit amet pulvinar orci viverra aliquam. Phasellus eget ante efficitur, hendrerit ante pellentesque, accumsan nunc. Morbi viverra gravida velit at commodo. Etiam nec turpis eu mauris tincidunt ultricies. Maecenas a dignissim turpis, nec condimentum enim. In eleifend justo at enim varius, non suscipit nisl hendrerit. Vivamus nec nisi id nibh sagittis sollicitudin mollis sed dolor. Fusce cursus at felis eget vulputate. Nulla efficitur sagittis scelerisque. Praesent vitae pretium dolor, nec vestibulum massa. Donec varius sollicitudin ullamcorper. In varius nec lectus eu sollicitudin. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Proin vestibulum et lorem ut volutpat. Praesent viverra imperdiet sollicitudin. Donec tincidunt, diam sit amet eleifend facilisis, urna mi rutrum mauris, eu euismod nulla mauris in nunc. Mauris risus diam, convallis finibus orci non, ullamcorper luctus arcu. Donec dapibus vestibulum eros, sed convallis tellus malesuada nec. Nam sodales efficitur augue, eget rhoncus sapien. Nullam eget dignissim lectus, eget consequat arcu. Donec neque risus, vehicula tempor orci a, finibus placerat eros. Proin pellentesque ante in lectus suscipit, quis posuere diam feugiat. Nunc vitae laoreet metus. Praesent sit amet eleifend massa. Aliquam magna magna, vulputate vitae tortor eu, interdum mollis neque.

Sed vestibulum dapibus rhoncus. Curabitur tristique dolor eleifend lorem facilisis, sed blandit diam sagittis. Morbi et elit tempus, lobortis dui in, sollicitudin ipsum. Donec mollis urna id nibh lacinia suscipit. Mauris suscipit non urna non facilisis. Donec facilisis, nisl a eleifend gravida, dolor quam imperdiet arcu, dapibus lacinia nisi tellus ac enim. Fusce et scelerisque magna. Donec purus enim, mattis id leo eget, interdum pretium massa. Duis placerat enim nec tincidunt facilisis. Pellentesque fermentum, nulla et accumsan fringilla, nibh ipsum vehicula orci, at tristique tellus sem sit amet urna. Pellentesque vel leo laoreet, finibus est sed, eleifend est. Nam sed justo non lacus posuere tincidunt non sit amet enim. Aliquam erat volutpat.

Donec in elementum ex. Nunc ac dui vel tortor ultricies accumsan. Praesent id turpis mauris. Suspendisse semper lacinia nisi in porttitor. Nullam feugiat massa magna, vitae blandit metus auctor ac. Duis tristique, enim eget iaculis laoreet, nibh ligula tincidunt dolor, at condimentum magna nulla at dolor. Aliquam erat volutpat. Praesent elit velit, condimentum eu ultrices nec, dignissim et quam. Donec finibus nunc et consequat sagittis.

Mauris tristique elit eget dolor semper, a volutpat sem aliquet. Proin porttitor, nulla vel varius viverra, est elit consequat massa, vel laoreet erat odio quis neque. Praesent vulputate urna eget varius eleifend. Pellentesque tempor eu felis eget porta. Aenean pharetra congue nisl quis auctor. Mauris luctus venenatis elit, non ultrices arcu malesuada eu. Sed ut felis luctus, malesuada mi at, tristique tortor. Ut laoreet, magna sed luctus viverra, dolor arcu imperdiet arcu, quis euismod lacus magna eget ipsum. Vestibulum sit amet auctor sapien. Phasellus elementum ante eget arcu tempus, nec ullamcorper mi efficitur. Donec volutpat dui sit amet mi ultrices, congue mollis libero viverra. Ut vitae quam in dolor venenatis ornare. Cras a lorem bibendum, tempor turpis non, congue enim. Ut sapien quam, congue non lobortis quis, hendrerit nec sem.

Donec diam elit, tristique eu euismod a, finibus et diam. Nullam facilisis neque eget malesuada ultricies. Aliquam tempus placerat nibh quis auctor. Mauris sollicitudin nunc vel magna placerat vehicula. Integer mattis placerat volutpat. Integer rhoncus, erat non malesuada tincidunt, nisl odio auctor lorem, nec iaculis sem arcu id arcu. Duis egestas efficitur tempus. Mauris et risus sed augue condimentum dictum. Cras lacinia nunc non dui rhoncus lobortis nec lacinia libero. Maecenas lacinia ex eget est suscipit, ac scelerisque felis dignissim. Pellentesque mollis tristique leo, at porta metus sollicitudin eget. Suspendisse egestas tincidunt turpis id vehicula.

Phasellus pellentesque nisi sit amet lectus malesuada ullamcorper. Ut finibus quam purus, vitae auctor metus suscipit sodales. Vestibulum a odio vulputate, pulvinar mi vel, luctus quam. Duis id erat ligula. Nunc leo dui, tempor lobortis semper in, accumsan vel sem. Quisque laoreet massa a libero aliquam, vitae molestie metus lacinia. In tincidunt quis mauris rutrum egestas. Integer erat elit, laoreet eu quam vitae, pretium gravida tortor. Maecenas varius quam nec imperdiet facilisis. Cras in condimentum velit. Aliquam auctor luctus lacus sagittis pharetra. Nunc lacus nunc, pellentesque a varius ut, malesuada vel elit. Vivamus bibendum, ante finibus scelerisque tristique, eros erat rhoncus lectus, eu volutpat risus ante in elit. Suspendisse molestie, ex rhoncus euismod imperdiet, est odio venenatis sapien, ac ultricies ante nisi eu lorem.

Etiam quis scelerisque est, eget pellentesque dui. Quisque lectus mauris, consequat quis maximus in, lobortis vel felis. Sed erat magna, vulputate sit amet finibus quis, tincidunt quis erat. Mauris nec sollicitudin nulla. Suspendisse in massa consequat, cursus magna ac, ullamcorper mauris. Vivamus pulvinar sapien lectus, nec pulvinar mauris congue eget. Integer bibendum lectus est. Mauris congue augue tristique imperdiet lacinia. Suspendisse in libero interdum, fringilla tortor vulputate, ultricies diam. Aliquam id sodales felis.

Morbi a ex a orci ultrices egestas. Vivamus tincidunt eu nulla quis condimentum. Donec dictum tincidunt eros, sed rutrum urna interdum sed. Quisque est nibh, faucibus at felis tincidunt, finibus tincidunt risus. Sed blandit sapien sed erat consectetur, eu placerat lorem blandit. Fusce lorem neque, efficitur vitae porta sit amet, aliquet eget leo. Quisque vehicula sed metus nec mattis. Sed sit amet porta enim, eu fermentum metus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales ligula vitae convallis vulputate. Maecenas sit amet urna turpis. Nam congue blandit consectetur. Phasellus cursus erat eu tempus molestie. Maecenas aliquam efficitur lectus, et volutpat nibh dignissim sed. Vestibulum sem velit, luctus ac arcu quis, sollicitudin eleifend mi. Proin sed fringilla justo, ut accumsan sapien.

Fusce ut lectus est. Nunc sodales ex quis iaculis ultricies. Pellentesque leo turpis, sodales ac ullamcorper at, vehicula ac diam. Proin vitae iaculis erat. In augue nulla, iaculis vel purus scelerisque, pretium pulvinar mauris. Nunc tortor urna, eleifend tristique posuere id, sodales nec urna. Quisque aliquet ornare elementum. Aenean dictum ante ante, vitae rhoncus sapien consectetur at. Pellentesque at dignissim libero. Nunc id quam gravida sem convallis tempor. Proin bibendum sagittis nisi et efficitur. Curabitur ultricies maximus rhoncus.`;

export { dummyContract }
