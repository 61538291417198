import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      id="prefix__Camada_1"
      x={0}
      y={0}
      viewBox="0 0 200 250"
      xmlSpace="preserve"
      {...props}
    >
      <Path
        fill="#E9E9EB"
        d="M.7 37.5c.7-1.9.6-3.9 1.1-5.8.1-.2.2-.5.2-.7.5.5.4 1.2.4 1.8V53c0 .6.1 1.3-.5 1.8-.6-2-.7-4.1-1-6.1-.1-.3.1-.7-.3-.9v-2.2c.2-.3.2-.5 0-.8v-5.1c.2-.5.2-1.1 0-1.6.1-.3.1-.5.1-.6zM29.8 2.8c.3 0 .5-.1.8-.1.4-.2.9-.1 1.3-.1.5-.1 1 .1 1.5-.1h1.9c.3.4.2.8.2 1.2-.1 1-.3 2.1 1.1 2.5.2.1.2.4 0 .5-1.9.5-3.9.2-5.8.2-.7-.1-1.1-.4-1.1-1.1-.3-1-.6-2.1.1-3z"
      />
      <Path
        fill="#E9E9EB"
        d="M36.5 6.5c-1.8-.5-2.1-1.3-1.3-4h.5c.3.2.6.1.9.1.3-.1.7.1 1-.1h1.6c.5.6 1.4.1 1.9.5-.7.3-.8.8-.8 1.6s.3 1.6-.2 2.4c-1.2.3-2.4.1-3.6-.5zM193 247.4c-1.8-2.3-3.5-4.7-5.3-7-.3-.4-.6-1.1-1.2-1.1 0-.4.2-.6.6-.7.8.1 1 .8 1.5 1.3 1.6 2 3.1 4.1 4.7 6.1.2.3.3.5.3.9-.1.2-.2.4-.3.5h-.3zM.7 38c.5.5.4 1.1 0 1.6V38zM33.3 2.5c-.4.4-.9.2-1.4.2-.1-.1-.1-.1-.1-.2l.1-.1c.5.1.9.1 1.4.1z"
      />
      <Path
        fill="#E9E9EB"
        d="M31.9 2.5v.2h-1.4v-.2h1.4zM37.6 2.5c-.3.4-.7.2-1.1.2-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1h1.1z"
      />
      <Path
        fill="#E9E9EB"
        d="M36.5 2.5v.2c-.3 0-.6.1-.8-.2h.8zM.7 44.8c.3.3.4.5 0 .8v-.8z"
      />
      <Path
        d="M187 238.9c-.2.2-.4.3-.6.5-.5.4-.7.1-1.1-.3-3.5-4.6-7-9.2-10.5-13.7-.5-.6-.5-.9.2-1.4.9-.6 1.8-1.3 2.7-2.1-.9-.7-1.8-1.1-2.7-1.5-2.4-1.2-3.3-2.7-3.1-5.4v-.3c.8-6.2-2.3-10.1-8.6-10.7-3-.3-5-1.7-5.9-4.5-1.3-4.1-3.8-7.3-6.8-10.2-3.2-3-6.3-6.2-8-10.4-.3-.7-.8-.7-1.4-.7h-8.6c-.9 0-1.2-.2-1.2-1.1.1-2.7 0-5.4 0-8.1 0-.8-.3-1.2-1.1-1.4-4.2-1.1-8.5-1.6-12.7-2.5-1.4-.3-2.8-.7-4.2-1.1-5-1.5-8.7-4.7-11.1-9.4-1.4-2.8-2.6-5.7-3.9-8.6-3.6-7.9-9.5-12.8-17.9-14.5-3.6-.8-7.1-.3-10.7.3-3.7.6-7.3 1.3-11.1.8-6.6-1-11.7-4.6-15.8-9.6-4.1-4.8-6.6-10.5-9-16.2-.2-.4-.4-.8-.5-1.2 0-.2 0-.4.2-.6s.5-.3.8-.2c.7.5.8 1.3 1.1 2 1.6 4 3.4 7.9 5.7 11.6 3.3 5.2 7.5 9.4 13.3 11.8 4 1.7 8.2 1.6 12.3.9 2.9-.5 5.9-1 8.8-1.1 3.9-.1 7.6.7 11.1 2.3 7 3.1 11.3 8.5 14 15.3 1 2.4 2 4.8 3.2 7.1 2.5 4.6 6.3 7.2 11.3 8.5 3 .8 6 1.3 9 1.8 1.9.3 3.8.7 5.7 1.2.7.2 1.2.3 1.3-.7 0-.7.5-.9 1.1-.9.6-.1 1.2.1 1.9-.2-.4-.6-.9-1-1.4-1.4-3.8-3-7.8-5.9-11.5-9.1-6.8-5.9-11.2-13-12.1-22-.5-5.1-.6-10.3-.3-15.4s0-10.2-1.1-15.2c-1.1-4.9-3.6-8.9-7-12.6-1.9-2.1-3.8-4.2-5.3-6.6-.3-.6-.8-1.1-.6-1.9.2-.3.5-.5.9-.3 1.4 3.1 3.7 5.5 6 7.9 5.7 5.9 8 13.1 8.3 21 .1 5 0 10 0 14.9 0 4.6.3 9.1 1.5 13.5 1.8 6.8 6.2 11.9 11.4 16.4 4.4 3.8 9.4 6.9 13.6 11 .4.4.8.3 1.3.3h6.1c.8 0 1 .2 1 1v11.5c0 .6 0 1-.8 1-.9 0-.5.5-.3.9 1.1 2.4 2.6 4.6 4.5 6.5 1.5 1.6 3.1 3.1 4.6 4.7 2.2 2.3 4.1 4.8 5 7.9.9 3.1 2.8 4.7 6.1 4.9 2.2.1 4.2 1.2 6 2.5 2.7 2 3.5 4.7 3.1 8-.1.8-.2 1.6-.2 2.4-.1 1.5.7 2.6 2 3.2 1 .5 2.1 1.1 3.1 1.7.5.3.8.2 1.2-.1 1.1-.9 2.2-1.7 3.3-2.5.5-.4.8-.4 1.2.1 3.5 4.7 7.1 9.4 10.7 14 .7.8-.3.9-.4 1.4-.9 1.3-2.3 1.9-3.5 2.9-.1.1-.3.2-.4.3-.5.3-.9.7-1.4 1.1-.4.2-.7.5-1 .7-.3-.2-.6-.3-.8-.1z"
        fill="#d7d7db"
      />
      <Path
        fill="#E9E9EB"
        d="M96.2 79.9l-.9.3c-.2 0-.4 0-.5-.2-.4-.6-.3-1.3-.3-2V45.4c0-.7-.1-1.5.4-2 .8.1 1.5.4 2.2.8.4.6.4 1.3.4 1.9v31.3c0 .7 0 1.4-.4 1.9-.3.4-.6.5-.9.6z"
      />
      <Path
        fill="#E9E9EB"
        d="M97.1 79.6V44.4c1 .3 1.8.8 2.6 1.4-.1 10.1 0 20.1-.1 30.2 0 .8.1 1.6-.4 2.3-.6.7-1.4 1-2.1 1.3z"
      />
      <Path
        fill="#E9E9EB"
        d="M99.2 78.4V46.9c0-.4-.2-.9.5-1.1.7.3 1.2.7 1.7 1.2.4.6.4 1.3.4 1.9V75c0 .5 0 1.1-.2 1.6-.8.8-1.6 1.4-2.4 1.8zM86.8 42.9c.4 0 .7 0 1.1-.1.6.7.4 1.5.4 2.3v33.4c0 .7-.3 1.7.8 2.1.5.1 1 0 1.5.2.2.1.3.2.3.4-1.5 1-3 .6-4.6-.1-.5-.5-.5-1.2-.5-1.9V44.8c0-.7-.1-1.6 1-1.9z"
      />
      <Path
        fill="#E9E9EB"
        d="M90.9 81c-.1 0-.2-.1-.4-.1-.5-.5-.4-1.2-.4-1.8V44.5c0-.6 0-1.3.4-1.8.7 0 1.5-.1 2.2.3-.1 1.1-.2 2.2-.2 3.4V78c0 .9 0 1.8.4 2.6-.4 1.2-1.2.8-2 .4zM101.5 76.7c-.3-.2-.2-.6-.2-.9V47c.9.6 1.7 1.4 2.4 2.3.2.8.2 1.7.2 2.5v20.7c0 .7 0 1.3-.2 2-.6 1-1.4 1.7-2.2 2.2z"
      />
      <Path
        fill="#E9E9EB"
        d="M93 80.7c-.6 0-.9-.3-.9-.9v-2.2-33.1c0-.5-.4-1.3.6-1.6.4 0 .8 0 1.1.2.2 3.5.1 7.1.1 10.6 0 2.5.1 4.9.1 7.4-.1 5.7 0 11.5-.1 17.1 0 .8 0 1.5.2 2.3-.3.5-.7.5-1.1.2zM89.2 81c-1.2.2-1.4-.4-1.4-1.4V42.8c.8-.2 1.7-.3 2.5 0-.7.9-.7 1.9-.7 2.9v33c0 .8.2 1.6-.4 2.3zM86.8 42.9c-.5.3-.5.9-.5 1.4v35.4c0 .5 0 .9.3 1.3-.7.5-1.3.2-1.8-.4-.3-.8-.2-1.6-.2-2.4V43.7c0-.8.6-1 1.3-1.2.1.5.5.4.9.4z"
      />
      <Path
        fill="#E9E9EB"
        d="M89.2 81V44.5c0-.9.1-1.6 1.2-1.7.1-.1.1-.1.2 0V81c-.5-.1-.9 0-1.4 0zM94.1 80.5c-.7 0-.5-.5-.5-.9 0-2.7.1-5.4 0-8.1-.1-2 .2-3.9-.1-5.9-.2-1.2.3-2.4.2-3.6-.1-2.6-.1-5.1-.1-7.7 0-3.6.2-7.2-.1-10.8 0-.1.2-.3.3-.4.3 0 .7 0 1 .3v35.5c0 .4-.1.8.3 1.1-.1.9-.5.7-1 .5zM106.9 69.3c-.4 1.3-1 2.4-1.9 3.5-.9-.2-.7-1-.8-1.6V63 52.7c0-.6-.2-1.4.6-1.8.9 1.1 1.6 2.3 2 3.7.8 4.9.8 9.8.1 14.7zM190.3 236.4c1.2-1 2.4-2 3.7-2.7 1.6 1.4 2.6 3.4 4 5 .5.6.9 1.3 1.5 1.9.9.9.8 1.5-.2 2.2-.8.5-1.5 1-2.2 1.5-.8.4-1-.4-1.3-.8-2-2.4-3.7-4.7-5.5-7.1zM85.7 42.6c0 .7-.9.3-1.1.8-.3.3-.7.4-1 .5-.5.1-.9.3-1.4.2-.2-.1-.4-.2-.5-.4-.7-1.4-1.5-2.8-1.7-4.4-.1-1.9-.1-3.8 0-5.6 0-.6-.2-1.3.6-1.6 2 3.3 3.8 6.8 5.1 10.5z"
      />
      <Path
        fill="#E9E9EB"
        d="M104.9 51c-.3.4-.2.8-.2 1.2v19.2c0 .5-.2 1 .3 1.4-.3.7-.8 1.3-1.4 1.9-.4-.3-.3-.8-.3-1.3V50.7c0-.4-.1-.9.2-1.3.7.4 1.1 1 1.4 1.6zM70.6 19c1.9 1.8 3.6 3.8 5.1 5.9.3 2 .3 3.9 0 5.9-.2.5-.6.5-.8.1-1.6-2.3-3.7-4.3-4.9-6.9-.1-1.2-.1-2.5 0-3.7.1-.5-.1-1.1.6-1.3z"
      />
      <Path
        fill="#E9E9EB"
        d="M70.6 19c-.5 1.8-.1 3.6-.2 5.4-.5.1-.8-.2-1-.5-1.1-1.5-2.5-2.7-3.8-4-.3-.4-.6-.8-.7-1.3-.1-1-.1-2 0-3 0-.5-.1-1.1.7-1.2 1.9 1.3 3.5 2.9 5 4.6zM51.8 5.7c2.2.9 4.3 1.9 6.2 3.2.1 1.4.2 2.8-.1 4.1-.3.5-.7.3-1 .1-1.6-1-3.3-1.9-5-2.7-.4-.2-.6-.5-.7-.9-.1-.9-.1-1.8-.1-2.7.1-.4 0-1 .7-1.1zM40.3 6.8c-.6-1.1-.2-2.3-.3-3.4 0-.6.8-.2 1.2-.4 1.4 0 2.7.2 4 .6.7 1.3.4 2.7.3 4-1.8.3-3.6.1-5.2-.8z"
      />
      <Path
        fill="#E9E9EB"
        d="M65.6 14.3c-.7.9-.2 1.9-.3 2.8-.1.6 0 1.3-.1 2-.1 0-.2.1-.3 0-1.4-1-2.9-1.9-3.8-3.5-.1-1-.1-2 0-3 0-.5-.1-1.1.7-1.2 1.4.8 2.7 1.8 3.8 2.9zM80.6 32.1c-.5 1-.2 2.1-.2 3.2-.1 1.5 0 3 0 4.4-.2 0-.4-.1-.5-.3-.8-1.5-1.8-2.9-2.2-4.7-.1-1.7-.1-3.4 0-5 0-.5-.1-1.2.7-1.4.7 1.2 1.5 2.4 2.2 3.8z"
      />
      <Path
        fill="#E9E9EB"
        d="M61.8 11.4c-.6 1.5-.1 3-.3 4.6-1.6-.4-2.8-1.4-3.8-2.7.2-1.4-.4-2.9.3-4.3 1.4.6 2.6 1.4 3.8 2.4zM51.8 5.7c-.7 1.3-.1 2.7-.3 4.1-1.7-.1-3.1-.7-4.3-1.9-.1-1.3-.5-2.6.3-3.8 1.5.3 3 .9 4.3 1.6zM78.2 28.3c-.6.9-.2 1.9-.3 2.9-.1 1.3 0 2.6 0 3.9-1.3-1-2.2-2.3-2.4-4v-5c0-.4-.1-.9.2-1.3.9 1.1 1.8 2.2 2.5 3.5zM106.9 69.3V54.7c2.2 4.8 2.2 9.7 0 14.6z"
      />
      <Path
        fill="#E9E9EB"
        d="M47.5 4.1v4c-.9.4-1.6.2-2.2-.6-.6-1.3-.1-2.7-.3-4 .9.1 1.7.3 2.5.6zM187.5 238.6c.3-.4.6-.7 1-.8 1.4.2 1.7 1.4 2.4 2.4-.4 1.2.3 2 1.2 2.6.8 1.1 2.1 1.9 2.2 3.5 0 .3-.2.5-.5.5-2.3-2.8-4.2-5.5-6.3-8.2zM190.3 236.4c1.1.1 1.3 1.2 1.8 1.8 1.5 1.8 2.8 3.6 4.2 5.5.2.3.3.5.7.5-.2.3-.4.5-.8.6-1-.3-1.3-1.3-1.9-2-.4-.4-.6-.9-1.1-1.2-.7-.5-1.1-1.2-1.5-1.9-.3-1.2-1.6-1.8-1.8-3.1.1 0 .2-.1.4-.2z"
      />
      <Path
        fill="#E9E9EB"
        d="M187.5 238.6c1.2.7 1.8 1.9 2.6 3 1.3 1.7 2.7 3.2 3.7 5.2l-.3.3c-1.9-2.5-3.8-5-5.8-7.5-.2-.2-.5-.4-.7-.6.2-.2.3-.3.5-.4zM193.5 241.3c.9 1.2 1.8 2.4 2.7 3.5-.2.2-.4.3-.5.5-.7.1-.9-.4-1.2-.8-.6-.7-1.1-1.5-1.8-2.1-.2-.7.5-.8.8-1.1z"
      />
      <Path
        fill="#E9E9EB"
        d="M194.3 246c-.6-1.2-1.5-2.3-2.4-3.3-.1-.3.1-.4.3-.4 1.2.4 2.7 2.3 2.7 3.4-.2.3-.4.3-.6.3z"
      />
      <Path
        fill="#E9E9EB"
        d="M194.8 245.8c-.7-1.2-1.3-2.6-2.7-3.2 0-.5.2-.4.5-.3 1.1.1 1.2 1.2 1.8 1.8.4.4.7.9 1.2 1.2-.2.1-.5.3-.8.5zM34.1 105.4c-.1.1-.1.2-.2.3-1.3.8-2.4 1.8-3.9 2.3-1 .2-.9-.6-.9-1.2-.1-1.3 0-2.7 0-4v-4.4-.5c.2-.7.8-1.2 1.1-1.8 2.3-3.7 2.2-7.4 0-11.1-.4-.6-.9-1.1-1.1-1.8v-.5-7.8c0-.6-.1-1.3.3-1.8.3-.3.7-.3 1-.1 1.4.5 2.5 1.3 3.6 2.2.3.3.5.7.4 1.2v27c.1.8.1 1.4-.3 2zM8.8 76.5c.3-.4.6-.7.8-1.1.5 0 .7.4.8.8.6 1.1 1.3 2.1 2 3.2.7 1.3 1.7 2.3 2.4 3.6v.4c-.4.7-1.1 1.2-1.4 1.9-1.7 2.6-1.9 5.4-1.3 8.4.1.4.2.9.2 1.3.1 3.5.1 7 .1 10.5 0 .7.1 1.4-.4 1.9-1.3-.6-2.3-1.4-3.2-2.4-.3-.7-.4-1.4-.4-2.1V78.8c0-1 0-1.7.4-2.3zM2.1 54.7V31c.5-2.6 1.3-5.1 2.4-7.5.5.5.4 1.2.4 1.9v9.4c0 .7-.1 1.3.4 1.9.3 1.2-.1 2.5-.1 3.7 0 2.3.2 4.6-.1 6.8-.3.8-.2 1.7-.2 2.5.1 3.9 0 7.9 0 11.8 0 .7.2 1.5-.6 1.9-1-2.9-1.8-5.8-2.2-8.7z"
      />
      <Path
        fill="#E9E9EB"
        d="M8.8 76.5v28.3c-.9-.8-1.8-1.6-2.4-2.7-.3-.8-.4-1.5-.4-2.4V81.5c0-.8.1-1.6.4-2.4.6-1 1.5-1.9 2.4-2.6z"
      />
      <Path
        fill="#E9E9EB"
        d="M6.4 79.2v22.9c-5.1-6-5.1-17.2 0-22.9zM26 109.3c-1.4.5-2.9.5-4.3.5-.4-.5-.4-1.1-.4-1.7v-4.6c0-.9-.1-1.8-.7-2.5.2-.4.6-.5 1-.5.7-.1 1.5.1 2.2-.1.4-.1.9-.2 1.3-.3.3 0 .7 0 .9.2.3.3.4.6.4 1 .1 2.3.1 4.7 0 7 0 .3-.2.7-.4 1zM9.1 74.1c-1.4-2.4-2.6-4.9-3.5-7.5-.7-3.6-.3-7.2-.3-10.8 0-.4 0-.9.7-.9.4.2.5.5.5.9.6 2.9 1.6 5.6 2.7 8.3.2.6.4 1.2.4 1.8-.1 2.1 0 4.3 0 6.4-.1.7 0 1.3-.5 1.8z"
      />
      <Path
        fill="#E9E9EB"
        d="M20.6 101c.8.2 1.2.7 1.1 1.6-.1 2.4 0 4.8 0 7.3-1.2 0-2.4.1-3.5-.3-.5-.1-.9-.5-.9-1-.1-2.5-.2-5 .1-7.5 0-.2.1-.3.2-.5l.3-.3c.5-.2 1 0 1.5.1.4.1.8.2 1.2.6-.1-.1 0-.1 0 0z"
      />
      <Path
        fill="#E9E9EB"
        d="M17.9 100.4c-.5 1.1-.2 2.2-.3 3.3-.1 1.5 0 3 0 4.4 0 .6 0 1.1.5 1.4-1.4-.2-2.8-.6-4.1-1.3-.2-.3-.4-.7-.4-1.1 0-2.7-.1-5.3 0-8 0-.3.1-.5.2-.8l.3-.3c.9-.2 1.3.6 2 .9l.9.6c.6.3.8.6.9.9zM26 109.3v-7.6c0-.5.2-1-.3-1.4.1-.6.6-.7 1-.9.5-.2.9-.5 1.3-.8.4-.2.6-.8 1.2-.6.5.4.3.9.3 1.4v7.5c0 .5-.2 1 .6 1.2-1.3.5-2.6.9-4.1 1.2zM9.1 74.1v-7c0-.5-.2-1 .2-1.4.6.4.9 1.1 1.2 1.7.7 1.3 1.2 2.8 2 4.1.3.5.4 1 .3 1.5-.1 1.6 0 3.2 0 4.9 0 .6.1 1.2-.4 1.6-1.1-1.2-1.8-2.7-2.7-4.1-.2-.4-.4-.8-.6-1.3z"
      />
      <Path
        fill="#E9E9EB"
        d="M14.6 98.2c-.5.4-.3.9-.3 1.4v8.7c-.8-.2-1.5-.6-2.1-1.1V94.5c.9.3.9 1.4 1.5 2 .3.5 1 .9.9 1.7zM6.1 55.2c-.9-.2-.5.6-.5.9-.1 3.5 0 7.1-.1 10.6-.6-1.1-.9-2.2-1.3-3.4.4-.4.2-.9.2-1.4V48.5c0-.5-.2-1 .3-1.4.5.1.6.5.6.9.1 2 .5 4.1.9 6.1.1.3.2.8-.1 1.1zM5 36.7c-.8-.2-.5-.9-.5-1.3V23.5c1-2.7 2.5-5.3 4.3-7.6.8.1.7.9.7 1.4.1 1.7 0 3.4 0 5.1 0 .5-.1.9-.3 1.3-1.9 3.8-3.1 7.9-3.7 12.1-.1.3-.1.6-.5.9zM15.6 9c2-1.6 4.1-2.9 6.5-3.9.7 1.3.4 2.6.3 4-.1.3-.3.6-.5.7-1.8 1.1-3.6 2.4-5.2 3.7-.3.3-.7.5-1.1.1-.6-1.4-.7-3 0-4.6zM29.8 2.8v2.9c0 .6.3.9.9.9 0 .1 0 .3-.2.3-1.8.5-3.6 1.2-5.5 1.2-.9-.9-1-2.5-.3-4 1.6-.7 3.3-1.1 5.1-1.3z"
      />
      <Path
        fill="#E9E9EB"
        d="M9.1 22.7v-5.5c0-.4.1-.9-.3-1.2.9-1.4 2-2.7 3.3-3.8.5.5.4 1.2.4 1.9.1 1-.1 2 .2 3-.1.9-2.8 5.2-3.6 5.6z"
      />
      <Path
        fill="#E9E9EB"
        d="M12.6 17.3c-.7-.1-.5-.6-.5-1v-4.1c1-1.2 2.2-2.2 3.5-3.1.1 1.6-.3 3.2.3 4.8.2.4 0 .6-.3.8-1.1.8-1.7 2.1-3 2.6zM24.7 4.1v2.8c0 .4 0 .9.6 1-.8 1.1-2 1.5-3.3 1.5V5.1c.8-.4 1.7-.8 2.7-1zM37.3 102c-.7 1.2-1.6 2.1-2.6 3-.2.1-.3.2-.5.4V77.2c0-.4 0-.7.3-1 1.2.8 2 1.9 2.8 3 .5 4.1.2 8.2.2 12.3 0 3.5.2 7-.2 10.5zM189.9 236.7c.6.8 1.2 1.5 1.9 2.3.2.3.2.5 0 .7-.2.5-.5.7-1.1.5-.8-.8-1.2-1.9-2.2-2.5.4-.4.9-.8 1.4-1zM84.9 80.7c-.5-.1-.9-.2-1.4-.4-.4-.6-.4-1.3-.4-1.9V45.5c0-.7-.1-1.4.4-1.9.3-.4.7-.2 1.1-.3.5.4.3 1 .3 1.5v35.9zM195.3 237c.5.6 1.2 1.2 1.3 1.8.1.7-1 1.4-1.6 1.3s-1.3-1-1.3-1.7c0-.6.8-1 1.6-1.4zM82.5 79.9c-.8-.2-1.5-.5-2.2-1-.4-.6-.4-1.3-.4-1.9V46.8c0-.7 0-1.4.4-1.9.4-.6 1.5-.3 1.7-1.3l.2.2c.8.6.7 1.4.7 2.2V77.8c0 .8.1 1.6-.4 2.1z"
      />
      <Path
        fill="#E9E9EB"
        d="M82.5 79.9V45.7c0-.6.1-1.3-.2-1.8.4-.1.9-.2 1.3-.3v36.7c-.4-.1-.8-.3-1.1-.4zM190.8 240.2c.4-.2.7-.4 1.1-.5.8.3.7 1.4 1.6 1.6 0 .6-.7.6-.8 1.1-.2 0-.4.1-.5.3l-.3.3c-.7-.9-2.2-1.3-1.1-2.8zM26.8 72.1c1.1.2 2.1.5 3 1.1-.5.4-.3.9-.3 1.4v7.3c0 .5.2 1-.3 1.4-.5.1-.8-.3-1.2-.4-.5-.5-1.1-.8-1.8-1.1-.3-.2-.5-.5-.6-.9-.2-2.4-.1-4.8-.1-7.2.1-1 .4-1.5 1.3-1.6z"
      />
      <Path
        fill="#DBDBDD"
        d="M28.2 82.6c.4 0 .6.4 1 .6v.3c.2.7.2 1.5.2 2.3v10.3c0 .6 0 1.2-.2 1.7v.3c-.4.3-.7.5-1.1.8-.4.3-.6 0-.7-.3-.2-.4-.2-.9-.2-1.3V84.6c0-.9-.2-1.9 1-2zM30.3 84.8c2.5 3.5 2.5 8 0 11.6-.8-.1-.7-.8-.7-1.3-.1-3-.1-6.1 0-9.1.1-.5 0-1.1.7-1.2z"
      />
      <Path
        fill="#DBDBDD"
        d="M30.3 84.8c-.1.1-.2.2-.2.3v10.8c0 .1.2.3.3.4-.3.5-.5 1.1-1.1 1.3-.3-.3-.3-.6-.3-1V84.2c0-.3 0-.6.2-.9.6.4.8 1 1.1 1.5zM16.4 99.3c-.6-.2-1.1-.7-1.6-1.2-.4-.6-.4-1.3-.4-1.9V85c0-.7.1-1.3.4-1.9v-.2c.4-1 .5-2.1.5-3.2 0-.7-.1-1.4.3-1.9.6-.3.8.3 1.1.6.3.4.6.9.9 1.3.2.4.7.7 1 1.1 0 .2-.2.4-.3.5-.4.2-.8.3-1.1.6-.5.4-.3 1-.3 1.5v14c-.1.7 0 1.4-.5 1.9z"
      />
      <Path
        fill="#E9E9EB"
        d="M15.8 77.8c-.5 1.1-.2 2.3-.2 3.5 0 .7-.2 1.3-.9 1.6-.9-1.1-1.8-2.2-2.4-3.5v-5.6c0-.4-.1-.9.3-1.2.2.2.3.4.5.6 1.2 1.4 2.3 2.8 2.7 4.6z"
      />
      <Path
        fill="#DBDBDD"
        d="M14.8 83.1v15h-.2c-.4-.5-.8-1.1-1.1-1.6-.3-.7-.4-1.3-.4-2.1v-7.8c0-.7.1-1.4.4-2.1.1-.5.7-1 1.3-1.4z"
      />
      <Path
        fill="#DBDBDD"
        d="M13.4 84.7v11.9c-.7-.6-.9-1.4-1.4-2.1-.9-3.1-.9-6.1.7-8.9.2-.4.4-.7.7-.9zM26 81c.2.2.4.3.6.5.4.4.4 1 .4 1.5v15.6c0 .3-.1.6-.2.9-.4.3-.8.5-1.1.8h-.8c-.8-.1-.7-.8-.7-1.3 0-5.6-.1-11.3 0-16.8 0-.7.1-1.4 1-1.5.3 0 .6.1.8.3zM23.9 100.7c-.7.5-1.4.2-2.2.2-.4-.5-.4-1.1-.4-1.6V82.2c0-1.8.5-2.2 2-1.7.6.6.4 1.4.4 2.2v14.2c0 1.2 0 2.5.2 3.8z"
      />
      <Path
        fill="#DBDBDD"
        d="M23.9 100.7c-.9-.2-.5-.9-.5-1.4V80.6l.2-.2c.8-.2 1.6-.3 2 .7-.5 0-.8.2-.8.8V100c0 .2.2.3.2.5-.4.3-.8.2-1.1.2zM23.3 80.4c-1.3-.3-1.6.3-1.6 1.6.1 6.3 0 12.6 0 18.8-.4 0-.7 0-1.1.1v-.1c-.5-.5-.4-1.2-.4-1.8V82.2c0-.8-.1-1.8.9-2.2.2-.1.3-.1.5-.2.6.1 1.4-.2 1.7.6zM18.2 81c.1-.1.2-.2.3-.2.4-.5.8-.7 1.3-.1.3.6.2 1.2.2 1.9v16.1c0 .7.1 1.5-.4 2-.5.1-.9-.2-1.3-.3-.5-.5-.4-1.2-.4-1.8V82.9c-.2-.8-.2-1.4.3-1.9z"
      />
      <Path
        fill="#DBDBDD"
        d="M19.5 100.7V80.6c.6-.6 1.2-.8 1.9-.3-.7.3-.8.7-.8 1.5v19.1c-.4.1-.7 0-1.1-.2zM18.2 81v19.3c-.1 0-.2 0-.3.1-.4 0-.5-.4-.8-.6-.4-.5-.4-1.2-.4-1.8V83.1c0-.6 0-1.3.4-1.8.4-.2.7-.4 1.1-.3z"
      />
      <Path
        fill="#DBDBDD"
        d="M17.2 81.2v18.5c-.3-.2-.5-.3-.8-.5V83c0-.7 0-1.4.8-1.8zM26.8 99.6c-.4-.3-.3-.7-.3-1.1V81.6c.7.2 1.2.5 1.7 1.1-.5.2-.6.6-.6 1.1V98c0 .4 0 .7.5.8-.3.4-.7.7-1.3.8z"
      />
      <Path
        fill="#E9E9EB"
        d="M76.3 47.8c.8-.8 1.6-1.5 2.6-2 .4-.1.5.2.6.5.1.4.1.8.1 1.2v28.9c0 .7.1 1.5-.7 1.9-1-.5-1.9-1.2-2.7-2.1-.4-.6-.4-1.3-.4-1.9V49.7c0-.7 0-1.4.5-1.9zM37.3 102c-.3-.3-.2-.7-.2-1V80.1c0-.3 0-.6.2-.9 5.1 7.1 5.1 16 0 22.8zM73.5 73.1c-1-1.1-1.7-2.4-2.1-3.9-.7-4.9-.7-9.7 0-14.5.4-1.4 1.2-2.7 2.1-3.8.5.5.5 1.1.5 1.7v18.7c0 .7.1 1.3-.5 1.8zM23.3 80.4c-.5-.4-1.1-.2-1.6-.3-.7-2.9-.7-5.9 0-8.8 1.8-.1 3.5.1 5.2.7-.7.5-.9 1-.9 1.8.1 2.4 0 4.8 0 7.2h-.5c-.7-.5-1.4-.6-2.2-.6z"
      />
      <Path
        fill="#E9E9EB"
        d="M76.3 47.8v28.4c-.6-.4-1-.8-1.3-1.4-.4-.6-.4-1.4-.4-2.1V51.3c0-.8.1-1.5.5-2.2.2-.5.6-1 1.2-1.3zM21.7 71.4v8.8c-.1 0-.2.1-.3.1-.7-.2-1.3.2-1.9.3-.4.1-.7.2-1.1.3-.6 0-.9-.4-1.1-.8-.3-2.4-.1-4.7-.1-7.1 0-.4.1-.7.4-1 1.3-.6 2.7-.7 4.1-.6zM79 78.3c.4-.5.3-1 .3-1.6V47.3c0-.5.2-1.1-.3-1.5.4-.4.9-.7 1.4-.8v34c-.6-.2-1-.5-1.4-.7z"
      />
      <Path
        fill="#E9E9EB"
        d="M74.9 49.1v25.7c-.6-.5-1.1-1.1-1.4-1.8V50.8c.3-.6.8-1.2 1.4-1.7zM17.7 71.9v6.9c0 .4.1.9-.3 1.3-.8-.6-1-1.5-1.7-2.2L13 73.4c1.7-.5 3.1-1.4 4.7-1.5zM71.5 54.6v14.5c-2.1-4.7-2.1-9.6 0-14.5z"
      />
    </Svg>
  )
}

export default SvgComponent
