import { Platform } from 'react-native';
import RNC from 'react-native-config'

let Config: any;

if (Platform.OS === 'web') {
    Config = { 
        SERVER_URL: process.env.SERVER_URL,
        S3_BUCKET_DOMAIN: process.env.S3_BUCKET_DOMAIN,
        YOUTUBE_API_KEY: process.env.YOUTUBE_API_KEY,
        S3_IMAGES: process.env.S3_IMAGES,
        S3_VIDEOS: process.env.S3_VIDEOS,
        S3_ICONS: process.env.S3_ICONS
    };
} else {
    Config = RNC;
}

const SERVER_URL : string = Config.SERVER_URL;
const S3_BUCKET_DOMAIN : string = Config.S3_BUCKET_DOMAIN;
const YOUTUBE_API_KEY: string = Config.YOUTUBE_API_KEY;
const S3_IMAGES: string = Config.S3_IMAGES;
const S3_VIDEOS: string = Config.S3_VIDEOS;
const S3_ICONS: string = Config.S3_ICONS;

export { SERVER_URL, S3_BUCKET_DOMAIN, S3_IMAGES, S3_VIDEOS, S3_ICONS, YOUTUBE_API_KEY };
