import React, { Component } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Image,
    Dimensions,
    TouchableOpacity,
    Pressable,
    Platform
} from 'react-native';
import { ComparisonItem } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { Plus } from '../../icons';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

const win = Dimensions.get('window');

enum Show {
    none,
    left,
    right,
}

type Props = {
    item: ComparisonItem,
    show: Show,
    parent: any,
    index: number
};

type State = {
    rightPaneWidth: number
    leftPaneWidth: number
}

const iconSize = 20

class ComparisonItemStaticComponent extends Component<Props, State> {
    iconRatio: number;
    iconWidth: number;
    iconHeight: number;

    constructor(props: Props) {
        super(props);

        this.iconRatio = 1;
        this.iconWidth = 1;
        this.iconHeight = 1;

        this.state = { leftPaneWidth: 0, rightPaneWidth: 0 }
    }

    setLeftPaneWidth: boolean = true;
    setRightPaneWidth: boolean = true;

    render() {
        
        let expanded = this.props.parent.state.expandedItem == this.props.index;
        
        return (
            <View
                style={styles.root}
                onLayout={ event => {
                    this.props.parent.addStaticItemHeight(event.nativeEvent.layout.height)
                } }
            >
                <View style={styles.static}>
                    { Platform.OS == "web"
                        ? <Pressable
                            style={{
                                display: "flex",
                                flexDirection: 'column-reverse',
                            }}
                            onPress={() => {
                                this.props.parent.setExpandedItem(this.props.index, Show.left)
                            }}
                        >
                            <View style={{
                                backgroundColor: PALETTE.darkYellow_b,
                                maxHeight: iconSize
                            }}>
                                <Plus width={iconSize} height={iconSize} />
                            </View>
                        </Pressable>
                        : <TouchableOpacity
                            style={{
                                display: "flex",
                                flexDirection: 'column-reverse',
                            }}
                            activeOpacity={0.7}
                            onPress={() => this.props.parent.setExpandedItem(this.props.index, Show.left)}
                        >
                            <View style={{
                                backgroundColor: PALETTE.darkYellow_b
                            }}>
                                <Plus width={iconSize} height={iconSize} />
                            </View>
                        </TouchableOpacity>
                    }
                    
                    <View style={styles.left}>
                        <Markdown rules={ MarkdownCustomRules } style={{ body: styles.leftText }}>
                            {this.props.item.leftTitle}
                        </Markdown>
                    </View>
                    <View style={styles.center}>
                        <Image
                            source={{uri: `${S3_IMAGES}${this.props.item.icon}`}}
                            style={[{
                                width: 50,
                                height: 50, 
                            }]}
                        />
                    </View>
                    <View style={styles.right}>
                        <Markdown rules={ MarkdownCustomRules } style={{ body: styles.text }}>
                            {this.props.item.rightTitle}
                        </Markdown>
                    </View>
                    {
                        Platform.OS == "web"
                            ? <Pressable
                                style={{
                                    display: "flex",
                                    flexDirection: 'column-reverse',
                                }}
                                onPress={() => {
                                    this.props.parent.setExpandedItem(this.props.index, Show.right)
                                }}
                            >
                                <View style={{
                                    backgroundColor: PALETTE.darkYellow_b
                                }}>
                                    <Plus width={iconSize} height={iconSize} />
                                </View>
                            </Pressable>
                            : <TouchableOpacity
                                style={{
                                    display: "flex",
                                    flexDirection: 'column-reverse',
                                }}
                                activeOpacity={0.7}
                                onPress={() => this.props.parent.setExpandedItem(this.props.index, Show.right)}
                            >
                                <View style={{
                                    backgroundColor: PALETTE.darkYellow_b
                                }}>
                                    <Plus width={iconSize} height={iconSize} />
                                </View>
                            </TouchableOpacity>
                    }
                </View>
                <View style={styles.separator} />
            </View>
        )
    }
}

const columnsRation = {
    left: 2,
    right: 5
}

const styles = StyleSheet.create({
    root: {
        // backgroundColor: 'orange',
        // flex: 1,
        // flexDirection: 'column',
        // opacity: 0.8,
        // overflow: 'visible',
        // zIndex: 0,
        // elevation: 0

    },

    static: {
        backgroundColor: PALETTE.lightBlue,
        flex: 1,
        flexDirection: 'row',
        padding: 30
    },
    left: {
        flex: 1,
    },
    text: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    leftText: {
        textAlign: 'right',
        fontSize: 15,
        fontWeight: 'bold'
    },
    center: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    right: {
        flex: 1,
    },
    heading: {
        flex: 1,
        flexDirection: 'row'
    },
    headingSide: {
        // flex: 1,
    },
    dateContainer: {
        flex: columnsRation.left,
        flexDirection: 'row',
        alignItems: 'center'
    },
    date: {
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 18
    },
    circle: {
        width: 12,
        height: 12,
        borderRadius: 100,
        backgroundColor: PALETTE.gayBlue,
        marginLeft: 8
    },
    hLine: {
        height: 2,
        backgroundColor: PALETTE.gayBlue,
        flex: 1
    },
    vLine: {
        width: 2,
        height: '100%',
        backgroundColor: PALETTE.gayBlue,
        position: 'absolute',
        right: 0
    },
    barContainer: {
        backgroundColor: PALETTE.yellow,
        height: 50,
        flex: columnsRation.right,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    headingText: {
        fontSize: 18,
        margin: 20,
        fontWeight: 'bold'
    },
    icon: {
        width: 60,
        height: 60,
        position: 'absolute',
        left: -20
    },
    actionIcon: {
        width: 20,
        height: 20
    },
    bodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    bodyMargin: {
        flex: columnsRation.left
    },
    bodyText: {
        flex: columnsRation.right
    },
    body: {
        fontSize: 18,
        margin: 16,
    },
    bodyExpanded: {
        margin: 12,
    },
    separator: {
        height: 2,
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 40,
        backgroundColor: PALETTE.lightGrey,
    }
});

export { ComparisonItemStaticComponent, Show }
