export class ExpandableListItem {
    icon: string;
    heading: string;
    body: string;
    consumed: boolean;

    constructor(
        icon: string,
        heading: string,
        body: string,
    ) {
        this.icon = icon;
        this.heading = heading;
        this.body = body;
        this.consumed = false;
    }

    consume() : boolean {
        if (!this.consumed) {
            this.consumed = true;
            return true;
        } else return false;
    }
}

export default ExpandableListItem;
