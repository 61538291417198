import React, { Component } from 'react';
import { StyleSheet, Text, View, Pressable, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import stateMachine from '../StateMachine';

export default class GoToLoginScreenTextButton extends Component {
    render() {
        return <Pressable
            style={{
                marginRight: 20,
                ...Platform.select({
                    web: {
                        cursor: "pointer"
                    }
                })
            }}
            onPress={() => {stateMachine.navigation.navigate("Login")}}
        >
            <Text
                style={{
                    fontSize: 20,
                    color: PALETTE.darkGray
                }}
            >Entrar</Text>

        </Pressable>
        
    }
}
