import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let color = "black";
    if (props.color) color = props.color;

    return (
        <Svg viewBox="0 0 30 30" {...props}>
            <Path
                d="M15 30c8.3 0 15-6.7 15-15S23.3 0 15 0 0 6.7 0 15s6.7 15 15 15zm0-18l6 6H9l6-6z"
                fill={color}
            />
        </Svg>
    )
}

export default SvgComponent
