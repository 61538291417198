import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import PALETTE from '../CFISP_utils/palette';
import { Correct, Wrong } from '../icons';
import Markdown from 'react-native-markdown-display';

export enum EvaluationOptionState {
    normal,
    selected,
    correct,
    wrong
}

interface Props {
    text: string,
    action: any,
    optionButtonState: EvaluationOptionState
}

export default class EvaluationButton extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        let s = styles.normal;

        switch(this.props.optionButtonState) {
            case EvaluationOptionState.selected:
                s = styles.selected
                break;
            case EvaluationOptionState.correct:
                s = styles.correct;
                break;
            case EvaluationOptionState.wrong:
                s = styles.wrong;
                break;
            default:
                s = styles.normal;
        }

        return <View style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20
        }}>
            <TouchableOpacity
                onPress={() => {this.props.action()}}
                activeOpacity={0.7}
                style={[styles.button, s]}
            >
                {
                    this.props.optionButtonState == EvaluationOptionState.correct
                        ? <Correct style={styles.icon} />
                        : null
                }

                {
                    this.props.optionButtonState == EvaluationOptionState.wrong
                        ? <Wrong style={styles.icon} />
                        : null
                }

                <Markdown style={{ body: {color: PALETTE.darkBlue} }}>
                    {this.props.text}
                </Markdown>
            </TouchableOpacity>
        </View>
    }
}

const styles = StyleSheet.create({
    button: {
        padding: 20,
        borderRadius: 20,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        borderWidth: 2,
        fontSize: 16
    },
    normal: {
        backgroundColor: PALETTE.background,
        borderColor: PALETTE.lightBlue,
    },
    selected: {
        backgroundColor: PALETTE.gray_m,
        borderColor: PALETTE.lightBlue
    },
    correct: {
        backgroundColor: PALETTE.lightGayGreen,
        borderColor: PALETTE.anotherGreen
    },
    wrong: {
        backgroundColor: PALETTE.lightRed,
        borderColor: PALETTE.red
    },
    icon: {
        width: 20,
        height: 20,
        position: 'absolute',
        right: 0,
        top: 0
    }
});
