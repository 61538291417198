import React, { Component } from 'react';
import { View, Text, StyleSheet, Platform, Pressable } from 'react-native';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import { formatPriceInBRL } from '../CFISP_utils/functions';

type Props = {
    navigation: any,
    course: Course
};

class CourseListItem extends Component<Props> {
    openCourse(courseIndex: number) {
        this.props.navigation.navigate('Course', {course: this.props.course});
    }

    render () {
        if (this.props.course.owns) {
            return (
                <Pressable onPress={ () => this.openCourse(this.props.course.id) } style={styles.root}>
                    <View style={styles.image}></View>
                    <View style={styles.text}>
                        <Text style={styles.title}>{this.props.course.title}</Text>
                        { this.props.course.description.length!=0 ? <Text style={styles.description}>{this.props.course.description}</Text> : null }
                        <View>
                            <Text style={styles.description}>{this.props.course.workload} horas</Text>
                            
                        </View>
                        { this.props.course.owns ? <Text style={styles.open}>Abrir</Text> : null }
                    </View>
                </Pressable>
            )
        } else {
            return (
                <Pressable onPress={ () => this.openCourse(this.props.course.id) } style={styles.root}>
                    <View style={styles.image}></View>
                    <View style={styles.text}>
                        <Text style={styles.title}>{this.props.course.title}</Text>
                        { this.props.course.description.length!=0 ? <Text style={styles.description}>{this.props.course.description}</Text> : null }
                        <View>
                            <Text style={styles.description}>{this.props.course.workload} horas</Text>
                            {this.props.course.owns ? null : <Text style={styles.price}>{ formatPriceInBRL(this.props.course.price) }</Text>}
                        </View>
                        { this.props.course.owns ? <Text style={styles.open}>Abrir</Text> : null }
                    </View>
                </Pressable>
            );
        }}
    
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.lightGrey,
        flex: 1,
        flexDirection: 'row',
        padding: 20,
        marginBottom: 20,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    image: {
        backgroundColor: PALETTE.grey,
        width: 100,
        height: 100,
        marginRight: 20
    },
    text: {
        flex: 3,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        color: PALETTE.almostBlack,
        fontSize: 18,
    },
    description: {
        color: PALETTE.grey,
        fontSize: 15
    },
    price: {
        color: PALETTE.almostBlack,
        fontSize: 16,
        fontWeight: 'bold'
    },
    open: {
        color: PALETTE.blue,
        textAlign: 'right',
        fontSize: 16,
        fontWeight: 'bold'
    }
})

export default CourseListItem;
