import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Path
        d="M12 1.6c-4.2 0-7.5 2.9-7.5 6.6v2.4c-2.1 1-3.5 3.2-3.5 5.7 0 3.2 2.3 5.8 5.3 6.1l2.065.067L9 22.5l.002-.014.398.014V10.1H6.3V8.2c0-2.7 2.6-5 5.7-5s5.7 2.3 5.7 5v1.9h-3.1v12.4H18c2.9-.3 5-2.9 5-6.2 0-2.5-1.4-4.8-3.5-5.7V8.2c0-3.6-3.3-6.6-7.5-6.6zM5.9 12.1v8.32l-.412-.082a3.915 3.915 0 01-.556-.293l-.336-.233A4.462 4.462 0 012.9 16.302c0-2 1.2-3.701 3-4.201zm12.2 0c1.8.5 3.1 2.2 3 4.2 0 2-1.3 3.7-3 4.2v-8.4z"
        id="prefix__path1136"
        fill="#444"
        fillOpacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
