import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, ActivityIndicator, SafeAreaView, TextInput, Pressable, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import MonthPicker from 'react-native-month-year-picker';
import TextInputMask from 'react-native-text-input-mask';
import { CenterButton } from '../../components'
import { isValidCPF, formatPriceInBRL } from '../../CFISP_utils/functions';
import paymentAPIAdapter from '../../http/payment'
import { PaymentCard } from '../../payment_types';
import stateMachine from '../../StateMachine';

interface Props {
    instalments: number,
    price: Number,
    selected: boolean
}

export default class PaymentInstalmentPlan extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <View>
                    <Text style={ this.props.selected ? [styles.activeText] : [styles.text]}>
                        {this.props.instalments} x de {formatPriceInBRL( Number(this.props.price) / Number(this.props.instalments) )}
                    </Text>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    }
});
