import React, { Component } from 'react';
import { StyleSheet, Text, View, Pressable, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

type Props = {
    parent: any,
    tab: number
};

class SubjectTabNavigator extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    select(tab: number) {
        let headerTitle = 'Disciplina';
        
        if (tab == 1) headerTitle = 'Revisão'
        else if (tab == 2) headerTitle = 'Avaliação'
        else if (tab == 3) headerTitle = 'Ementa'

        this.props.parent.props.navigation.setOptions({
            title: headerTitle,
        })

        this.props.parent.setState({ isLoading: false, tab: tab });
    }

    render() {
        return (
            <View style={styles.root}>
                <Pressable onPress={() => {this.select(0)}} style={ this.props.tab == 0 ? [styles.tab, styles.activeTab] : styles.tab }>
                    <View style={styles.innerContainer}>
                        <Text style={ this.props.tab == 0 ? [styles.text, styles.active] : [styles.text, styles.inactive] }>Lições</Text>
                        <View style={ this.props.tab == 0 ? styles.square : null }></View>
                    </View>
                </Pressable>
                <Pressable onPress={() => {this.select(1)}} style={ this.props.tab == 1 ? [styles.tab, styles.activeTab] : styles.tab }>
                    <View style={styles.innerContainer}>
                        <Text style={ this.props.tab == 1 ? [styles.text, styles.active] : [styles.text, styles.inactive] }>Revisão</Text>
                        <View style={ this.props.tab == 1 ? styles.square : null }></View>
                    </View>
                </Pressable>
                <Pressable onPress={() => {this.select(2)}} style={ this.props.tab == 2 ? [styles.tab, styles.activeTab] : styles.tab }>
                    <View style={styles.innerContainer}>
                        <Text style={ this.props.tab == 2 ? [styles.text, styles.active] : [styles.text, styles.inactive] }>Avaliação</Text>
                        <View style={ this.props.tab == 2 ? styles.square : null }></View>
                    </View>
                </Pressable>
                <Pressable onPress={() => {this.select(3)}} style={ this.props.tab == 3 ? [styles.tab, styles.activeTab] : styles.tab }>
                    <View style={styles.innerContainer}>
                        <Text style={ this.props.tab == 3 ? [styles.text, styles.active] : [styles.text, styles.inactive] }>Ementa</Text>
                        <View style={ this.props.tab == 3 ? styles.square : null }></View>
                    </View>
                </Pressable>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    root: {
        paddingTop: 20,
        flex: 1,
        flexDirection: 'row',
        backgroundColor: PALETTE.lightGrey
    },
    innerContainer: {
        flex: 1,
        flexDirection: 'column'
    },
    tab: {
        flex: 1,
        paddingTop: 10,
        paddingBottom: 15,
        paddingHorizontal: 10,
        marginHorizontal: 4,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    activeTab: {
        flex: 1,
        paddingVertical: 10,
        backgroundColor: PALETTE.background,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    text: {
        fontSize: 15,
    },
    inactive: {
        color: PALETTE.gray_g
    },
    active: {
        color: PALETTE.darkBlue
    },
    square: {
        width: 24,
        height: 6,
        backgroundColor: PALETTE.yellow,
        alignSelf: 'flex-start'
    }
})

export default SubjectTabNavigator;
