import React, { Component } from 'react';
import { Image, StyleSheet, Text, View, ScrollView, Pressable, ActivityIndicator } from 'react-native';
import stateMachine from '../StateMachine';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import { SERVER_URL, S3_IMAGES } from "../CFISP_utils/environmentConstants";
import { version } from '../../package.json';
import PALETTE from '../CFISP_utils/palette';
import http from '../http';
import { Course } from '../content_types';
import { formatPriceInBRL } from '../CFISP_utils/functions';

export interface Props {
    course: Course;
}

export interface State {
    waiting: boolean
}

class RemoveFromFavoritesButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { waiting: false }
    }

    render () {
        if (this.state.waiting) return <ActivityIndicator color={PALETTE.forground} />;

        return <Pressable onPress={() => {
            this.setState({ waiting: true })
            http.unFavoriteCourse(this.props.course, () => {
                this.setState({ waiting: false })
                stateMachine.removeFavoriteCourse(this.props.course);
            })
        }}><Text style={{
            color: PALETTE.anotherRed,
            fontSize: 14
        }}>Retirar</Text></Pressable>
    }
}

export default RemoveFromFavoritesButton;
