
import React, { Component } from 'react';
import { StyleSheet, View, Text, Platform, Pressable } from 'react-native';
import { TextAssociation } from '../../content_types';
import QuizComponent from './quizComponent';
import PALETTE from '../../CFISP_utils/palette';
import { Correct, Wrong } from '../../icons';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

enum ButtonState {
    normal,
    selected,
    correct,
    wrong
}

type ShortLine = {
    index: number;
    text: string;
    state: ButtonState;
    userChoice: number;
}

interface Props {
    shortLine: ShortLine;
    textAssociation: TextAssociation;
    parent: any,
    index: number
};

class TextAssociationShortLineComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    chooseStyle() {
        switch (this.props.shortLine.state) {
            case ButtonState.normal:
                return styles.normal;
            case ButtonState.selected:
                return styles.selected;
            case ButtonState.correct:
                return styles.correct;
            case ButtonState.wrong:
                return styles.wrong;
        }
    }

    showIcon(state: ButtonState) {
        switch (state) {
            case ButtonState.correct:
                return <Correct style={styles.resultIcon} />;
            case ButtonState.wrong:
                return <Wrong style={styles.resultIcon} />;
            default:
                return null;
        }
    }

    chooseNumberColor() {
        switch (this.props.shortLine.state) {
            case ButtonState.normal:
                return {};
            case ButtonState.selected:
                return { color: PALETTE.background };
            case ButtonState.correct:
                return { color: PALETTE.anotherGreen };
            case ButtonState.wrong:
                return { color: PALETTE.red };
        }
    }

    render() {
        return (
            <Pressable
                style={[styles.itemContainer, this.chooseStyle()]}
                onPress={() => {
                    if (this.props.shortLine.state === ButtonState.normal) {
                        this.props.parent.choose(this.props.index, this.props.shortLine.index)
                    }
                }}>
                {
                    this.props.shortLine.state != ButtonState.normal
                    ? <Text style={[{
                        position: "absolute",
                        right: 7,
                        top: 12,
                        fontSize: 18,
                        fontWeight: 'bold'
                    }, this.chooseNumberColor()]}>{ `${this.props.shortLine.userChoice + 1}` }</Text>
                    : null
                }
                <Markdown rules={MarkdownCustomRules} style={{body: {
                    fontSize: 18,
                    textAlign: "center",
                    margin: 13,
                }}}>
                    { this.props.shortLine.text }
                </Markdown>
                { this.showIcon(this.props.shortLine.state) }
            </Pressable>
        )
    }
}

const styles = StyleSheet.create({
    itemContainer: {
        marginTop: 12,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    button: {
        flex: 7,
        marginTop: 10,
        borderColor: PALETTE.lightBlue,
        borderWidth: 3,
        borderRadius: 6,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.background,
        borderColor: PALETTE.lightBlue,
    },
    selected: {
        backgroundColor: PALETTE.gray_m,
        borderColor: PALETTE.gray_m
    },
    correct: {
        backgroundColor: PALETTE.lightGayGreen,
        borderColor: PALETTE.anotherGreen
    },
    wrong: {
        backgroundColor: PALETTE.lightRed,
        borderColor: PALETTE.red
    },
    resultIcon: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: -5,
        top: -5
    }
})

export { TextAssociationShortLineComponent, ButtonState, ShortLine };
