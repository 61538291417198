import React, { Component } from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import PALETTE from '../../CFISP_utils/palette';
import { PaymentClient } from '../../payment_types';

interface Props {
    fiscalPerson: PaymentClient
}

export default class PaymentDisplayFiscalPerson extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return  (
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_k,
                padding: 10
            }}>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Nome:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <Text style={styles.fiscalDataDisplayValueText}>{this.props.fiscalPerson.name}</Text>
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            CPF:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <Text style={styles.fiscalDataDisplayValueText}>{this.props.fiscalPerson.document_id}</Text>
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Endereço:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <Text style={styles.fiscalDataDisplayValueText}>{this.props.fiscalPerson.address}</Text>
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Fone:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <Text style={styles.fiscalDataDisplayValueText}>{this.props.fiscalPerson.phone}</Text>
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Email:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <Text style={styles.fiscalDataDisplayValueText}>{this.props.fiscalPerson.email}</Text>
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    },
    fiscalDataDisplayItem: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fiscalDataDisplayLabelContainer: {
        flex: 1
    },
    fiscalDataDisplayLabelText: {
        textAlign: 'right',
        marginRight: 7
    },
    fiscalDataDisplayValueContainer: {
        flex: 3
    },
    fiscalDataDisplayValueText: {
        fontWeight: 'bold'
    },
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    },
});
