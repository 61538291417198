import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { Paragraph } from '../../content_types';
import Markdown from 'react-native-markdown-display';
import { Top } from '../../icons';
import PALETTE from '../../CFISP_utils/palette';

type Props = {
    paragraph: Paragraph
};

class ParagraphComponent extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        if (!this.props.paragraph.consumed) this.props.paragraph.consume();

        return (
            <Markdown style={{
                body: { margin: 30 },
                heading1: {color: 'purple'},
                code_block: {color: 'black', fontSize: 14}
            }} onLinkPress={url=>{return true}}>
                { this.props.paragraph.text }
            </Markdown>
        )
    }
}

const styles = StyleSheet.create({})

export default ParagraphComponent;
