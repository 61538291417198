export class ComparisonItem {
    leftTitle: string;
    leftText: string;
    rightTitle: string;
    rightText: string;
    icon: string;
    consumed: boolean;

    constructor(
        leftTitle: string,
        leftText: string,
        rightTitle: string,
        rightText: string,
        icon: string,
    ) {
        this.leftTitle = leftTitle;
        this.leftText = leftText;
        this.rightTitle = rightTitle;
        this.rightText = rightText;
        this.icon = icon;
        this.consumed = false;
    }
}

export default ComparisonItem;
