import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 200 200" {...props}>
      <Path
        d="M18.2 88.8l80.1 95.8c1.3 1.8 3.6 1.8 5 0l80.1-95.8-3.2 4c4.5-5.9 8.1-13 9.5-20.7.5-3.1.9-6.3.9-9.9 0-26.1-20.2-47.7-45.4-47.7-20.7 0-38.2 14.4-43.7 34.2h-1.8c-5.4-19.8-23-34.2-43.7-34.2-25.2 0-45.4 21.1-45.4 47.7 0 3.1.5 6.7.9 9.9 1.3 7.6 5 14.9 9.5 20.7"
        fill={ props.color }
      />
    </Svg>
  )
}

export default SvgComponent
