import React, { Component } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import { StyleSheet, Text, View, ScrollView, Pressable, Platform } from 'react-native';
import { SubjectList, VideoComponent, BuyMidScreenButtonComponent } from '../components';
import PALETTE from '../CFISP_utils/palette';
import { Top } from '../icons';
import stateMachine from '../StateMachine';

type CourseScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type CourseScreenRouteProp = RouteProp<RootStackParamList, 'Course'>;

interface Props {
    navigation: CourseScreenNavigationProp,
    route: CourseScreenRouteProp
}

interface State {
    isLoading: boolean
}

class CourseScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Curso',
    };

    constructor(props: Props) {
        super(props);
        stateMachine.setCourse(this.props.route.params.course);
    }

    scrollViewRef: ScrollView | null = null;

    render () {
        let course = this.props.route.params.course;

        return (
            <View>
                <ScrollView
                    ref={(ref) => { this.scrollViewRef = ref }}
                    style={styles.root}
                >
                    <VideoComponent video={course.video} thumbnail={course.image} />
                    <Text style={styles.title}>{course.title}</Text>
                    <Text style={styles.description}>{course.description}</Text>
                    <SubjectList
                        navigation={this.props.navigation}
                        course={this.props.route.params.course}
                    />
                    <Pressable
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            marginTop: 30,
                            marginBottom: 100,
                            ...Platform.select({
                                web: {
                                    cursor: "pointer"
                                }
                            })
                        }}
                        onPress={() => {
                            if (Platform.OS == "web") {
                                window.scroll({
                                    top: 100,
                                    left: 100,
                                    behavior: 'smooth'
                                });
                            } else {
                                this.scrollViewRef?.scrollTo({x: 0, y: 0, animated: true})
                            }
                        }}
                    >
                        <Top style={{ width: 30, height: 30 }} color={PALETTE.grey} />
                    </Pressable>
                </ScrollView>
                { stateMachine.ownsCourse(this.props.route.params.course)
                    ? null
                    : <BuyMidScreenButtonComponent navigation={this.props.navigation} course={this.props.route.params.course} />
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.background,
        
    },
    title: {
        fontSize: 18,
        color: PALETTE.forground,
        textAlign: 'center',
        backgroundColor: PALETTE.lightGrey,
        paddingTop: 20 
    },
    description: {
        color: PALETTE.gray_e,
        textAlign: 'center',
        backgroundColor: PALETTE.lightGrey,
        paddingVertical: 20
    }
})

export default CourseScreen;
