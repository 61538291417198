import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, Platform, Pressable } from 'react-native';
import stateMachine from '../StateMachine';
import PALETTE from '../CFISP_utils/palette';

class LogoutButton extends Component {
    render () {
        return (
            <Pressable
                style={styles.root}
                onPress={() => {stateMachine.logout()}}
            >
                <Image
                    source={require('../../assets/icons/logout.png')}
                    style={styles.logoutIcon}
                ></Image>
                <Text
                    style={styles.text}
                >
                    Sair
                </ Text>
            </Pressable>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        marginHorizontal: 20,
        textAlign: 'center',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        ...Platform.select({
            web: {
                cursor: 'pointer',
            }
        }),
    },
    image: {
        width: 20,
        height: 20,
        marginBottom: 4
    },
    text: {
        color: 'white',
        fontSize: 12,
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 3
    },
    logoutIcon: {
        width: 20,
        height: 20,
        alignSelf: 'center'
    }
})

export default LogoutButton;
