import React, { Component } from 'react';
import stateMachine from '../StateMachine';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import { Text, View, ScrollView, SafeAreaView } from 'react-native';
import { Summary, SummaryTopic } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import { CenterButton, SummaryTopicComponent } from '../components';

type SummaryScreenNavigationProp = StackNavigationProp<RootStackParamList>;
type SummaryScreenRouteProp = RouteProp<RootStackParamList, 'Summary'>;

export interface Props {
    navigation: SummaryScreenNavigationProp,
    route: SummaryScreenRouteProp,
}

export interface State {
    summaryIndex: number,
    finished: boolean
}

export default class SummaryScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Revisão',
    };

    scrollListRef: any;

    constructor(props: Props) {
        super(props);
        this.state = { summaryIndex: 0, finished: false };
    }

    componentDidMount() {
        this.props.navigation.setOptions({
            title: 'Revisão',
            headerStyle: { backgroundColor: PALETTE.veryLightGreen }
        })
    }

    renderTopics(topics: SummaryTopic[]) {
        return topics.map((item, index) => {
            return <SummaryTopicComponent
                key={index}
                topic={item}
                index={index}
                singleTopicSummary={ topics.length == 1 }
            />
        })
    }

    render() {
        let summary: Summary = this.props.route.params.subject.summaries[this.state.summaryIndex];
        let subjectTitle = this.props.route.params.subject.title;

        if (this.state.finished) return <SafeAreaView>
            <View style={{ height: '100%', backgroundColor: PALETTE.background, padding: 50 }}>
                <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <View style={{  }}>
                        <Text style={{ textAlign: 'center', fontSize: 22 }}>
                            Disciplina: { subjectTitle }
                        </Text>
                    </View>
                    <View style={{ marginBottom: 50 }}>
                        <View>
                            <Text style={{ textAlign: 'center', fontSize: 30, color: PALETTE.gayBlue }}>
                                PARABÉNS!
                            </Text>
                        </View>
                        <View>
                            <Text style={{ textAlign: 'center', fontSize: 25, color: PALETTE.forground, marginBottom: 24 }}>
                                Você chegou ao fim da revisão
                            </Text>
                        </View>
                        <View style={{ height: 50, marginBottom: 24 }}>
                            <CenterButton
                                text="Iniciar avaliação"
                                color={PALETTE.gayBlue}
                                action={() => {
                                    stateMachine.goToThisSubjectEvaluation()
                                }}
                            />
                        </View>
                        <View>
                            <Text style={{ fontSize: 16, textAlign: 'center' }}>Voltar para <Text
                                style={{ color: PALETTE.gayBlue }}
                                onPress={() => { stateMachine.goToThisSubjectList() }}
                            >lista de lições</Text></Text>
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>

        return <SafeAreaView>
            <ScrollView
                contentInsetAdjustmentBehavior="automatic"
                style={{ height: "100%", backgroundColor: PALETTE.background }}
                ref={(ref) => { this.scrollListRef = ref; }}
                onContentSizeChange={() => { this.scrollListRef.scrollTo({x: 0, y: 0, animated: true}) }}
            >
                <View style={{ padding: 30 }}>
                    <Text style={{ textAlign: 'center', fontSize: 22 }}>
                        Disciplina: { subjectTitle }
                    </Text>
                    <View style={{ flexDirection: 'row', marginBottom: 30 }}>
                        <View style={{ flexDirection: 'column' }}>
                            <Text style={{ color: PALETTE.gayBlue, fontSize: 17 }}>
                                Lição { this.state.summaryIndex + 1 }
                            </Text>
                            <View style={{ backgroundColor: PALETTE.gayBlue, height: 2 }} />
                        </View>
                        <View style={{ flex: 1 }} />
                    </View>
                    <Text style={{ color: PALETTE.gray_e, fontWeight: 'bold', fontSize: 16, marginBottom: 20 }}>
                        Nesta lição você viu que:
                    </Text>

                    { this.renderTopics(summary.topics) }

                    <View style={{ marginTop: 40 }}>
                        <CenterButton
                            text="Avançar"
                            color={PALETTE.gayBlue}
                            action={() => {
                                if (this.state.summaryIndex + 1 == this.props.route.params.subject.summaries.length) {
                                    this.setState({ summaryIndex: this.state.summaryIndex, finished: true })
                                } else {
                                    this.setState({ summaryIndex: this.state.summaryIndex + 1, finished: false })
                                }
                            }}
                        />
                    </View>

                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 40 }}>
                        <View>
                            <Text style={{ fontSize: 16 }}>Ver <Text
                                style={{ color: PALETTE.gayBlue }}
                                onPress={() => { stateMachine.goToThisSubjectList() }}
                            >lista de lições</Text></Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 16 }}>Ir para <Text
                                style={{ color: PALETTE.gayBlue }}
                                onPress={() => { stateMachine.goToThisSubjectEvaluation() }}
                            >avaliação</Text></Text>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    }
}
