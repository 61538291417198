import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, Platform, Pressable } from 'react-native';

import { Course } from '../content_types';
import PALETTE from '../CFISP_utils/palette';
import ProgressBar from 'react-native-progress/Bar';
import { S3_IMAGES } from '../CFISP_utils/environmentConstants';

type Props = {
    navigation: any,
    course: Course
}

class CourseCard extends Component<Props> {
    openCourse(courseIndex: number) {
        this.props.navigation.navigate('Course', {course: this.props.course});
    }

    render () {
        let course = this.props.course;

        if (course.owns) return (
            <Pressable
                onPress={() => this.openCourse(course.id)}
                style={styles.root}
            >
                { course.image == "" ?
                    <View style={styles.noImage}></View> :
                    <Image source={{uri: `${S3_IMAGES}course-covers/${course.image}`}} style={styles.image}></Image>
                }
                <View style={styles.info}>
                    <Text style={styles.title}>{course.title}</Text>
                    {/* <Text style={styles.description}>{course.description}</Text> */}
                    <View style={styles.bottom}>
                        <Text style={styles.small}>{course.progress.completed} de {course.progress.total} disciplinas</Text>
                        <View style={styles.progress}>
                            <ProgressBar
                                progress={ course.progress.total != 0 ? course.progress.completed / course.progress.total : 0 }
                                width={80}
                                height={15}
                                unfilledColor={PALETTE.gray_b}
                                color={PALETTE.yellow}
                                borderWidth={0}
                                borderRadius={100}
                            />
                        </View>
                    </View>
                </View>
            </Pressable>
        );
        else return (
            <Pressable
                onPress={() => this.openCourse(course.id)}
                style={styles.root}
            >
                { course.image == "" ?
                    <View style={styles.noImage}></View> :
                    <Image source={{uri: `${S3_IMAGES}course-covers/${course.image}`}} style={styles.image}></Image>
                }
                <View style={styles.info}>
                    <Text style={styles.title}>{course.title}</Text>
                    {/* <Text style={styles.description}>{course.description}</Text> */}
                    <Text style={styles.small}>{course.workload} horas</Text>
                </View>
            </Pressable>
        )
    }
}

const cardSize = {
    width: 320,
    height: 280
}

const styles = StyleSheet.create({
    root: {
        marginRight: 20,
        height: cardSize.height,
        width: cardSize.width,
        backgroundColor: PALETTE.gray_f,
        borderRadius: 20,
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },    
    image: {
        height: cardSize.height/2,
        width: '100%',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    noImage: {
        height: cardSize.height/2,
        width: '100%',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: PALETTE.blue
    },
    info: {
        flex: 1,
        justifyContent: 'space-between',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
    },
    bottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    small: {
        color: PALETTE.grey
    },
    progress: {
        alignSelf: 'center'
    },
    title: {
        fontSize: 18,
        fontWeight: '700',
        marginTop: 10,
        color: PALETTE.darkGray
    },
    description: {
        color: PALETTE.grey,
    }
})

export default CourseCard;
