import Resource from './resource';

class Lesson {
    id: number;
    title: string;
    description: string;
    pub_date: Date;
    progress: number;
    resources: Resource[];
    isInteraction: boolean[];

    // UI pointers
    listItem: any;

    constructor(
        id: number,
        title: string,
        description: string,
        pub_date: Date,
        progress: number
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.pub_date = pub_date;
        this.progress = progress;
        this.resources = [];
        this.listItem = null;
        this.isInteraction = [];
    }

    getIsInteraction() {
        return this.isInteraction;
    }

    addResource(resource: Resource, position: number, isInteraction: boolean) {
        this.resources[position] = resource;
        this.resources[position].setLesson(this);
        this.isInteraction.push(isInteraction);
    }

    consumeResource() {
        this.progress += 1 / this.resources.length;
        this.listItem?.setState({ progress: this.progress });
    }

    setListItem(listItem: any) {
        this.listItem = listItem;
    }
}

export default Lesson;
