import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Path
        id="prefix__path856"
        d="M17.1 1.049c-2.139 0-4.052 1.114-5.202 2.803-1.146-1.683-3.05-2.792-5.18-2.8C3.283.943.45 3.965.45 7.6c0 .384-.017.841.149 1.379.228.999.616 1.98 1.295 2.794l9 10.8a1.051 1.051 0 00.064.07c.224.223.593.459 1.043.459.45 0 .82-.236 1.043-.46a1.051 1.051 0 00.064-.07l9.202-11.049c.56-.672.793-1.498 1.013-2.486.125-.511.13-.943.13-1.338 0-3.642-2.848-6.65-6.352-6.65zM6.662 3.15a1.051 1.051 0 00.037 0c1.904 0 3.604 1.373 4.084 3.215a1.051 1.051 0 001.018.785H12a1.051 1.051 0 001.018-.785c.48-1.842 2.178-3.215 4.082-3.215 2.295 0 4.25 1.992 4.25 4.55 0 .4 0 .565-.07.845a1.051 1.051 0 00-.007.027c-.179.807-.554 1.572-.582 1.606L12 20.618l-8.492-10.19c-.41-.493-.707-1.173-.881-1.955a1.051 1.051 0 00-.03-.106 5.128 5.128 0 01-.046-.767c0-2.558 1.957-4.527 4.111-4.45z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={2.103}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
