import { post } from '../CFISP_utils/http';
import Lesson from './lesson';

class Resource {
    typeName: string;
    id: number;
    pub_date: Date;
    consumed: boolean;
    lesson: Lesson | undefined;

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean
    ) {
        this.id = id;
        this.pub_date = pub_date;
        this.consumed = consumed;
        this.lesson = undefined;
        this.typeName = "";
    }

    consume() {
        if (!this.consumed) {
            post(`content/consume_resource/`, { resource: this.id }).then(res => {
                if (res.resource && res.timestamp) {
                    this.consumed = true;
                    this.lesson?.consumeResource();
                }
            })
        }
    }

    setLesson(lesson: Lesson) {
        this.lesson = lesson;
    }
}

export default Resource;
