import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, Image, ScrollView } from 'react-native';
import stateMachine from '../StateMachine';
import OwnedCourseListItem from '../components/ownedCourseListItem';

type Props = {
    navigation: any
}

class OwnedCoursesList extends Component<Props> {
    openCourse(courseIndex: number) {
        this.props.navigation.navigate('Course', {course: stateMachine.getOwnedCourse(courseIndex)});
    }

    renderList() {
        return stateMachine.ownedCourses.map((item, index) => {
            return <OwnedCourseListItem key={index} navigation={this.props.navigation} course={item} />
        })
    }

    render () {
        return (
            <ScrollView style={styles.root}>
                { this.renderList() }
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        // flex: 1,
        // flexDirection: 'column'
    }
})

export default OwnedCoursesList;
