import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import LottieView from "lottie-react-native";
import PALETTE from '../CFISP_utils/palette';

export interface Props {
    parent: any
}

class SplashScreen extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render () {
        return <LottieView
            // source={require('../../assets/animations/26166-black-cat.json')}
            source={require('../../assets/animations/cfisp-loading-animation.json')}
            autoPlay={true}
            loop={false}
            onAnimationFinish={() => {
                this.props.parent.setState({ ...this.props.parent.state, showSplashScreen: false })
            }}
            style={styles.root}
        />;
    }
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: PALETTE.background
    },
});

export default SplashScreen;
