import React, { Component, Fragment } from 'react';
import {
    StyleSheet,
    StatusBar,
    Text, View, ScrollView,
    ActivityIndicator,
    SafeAreaView,
    Platform,
    Pressable
} from 'react-native';
import stateMachine from '../StateMachine';
import http from '../http';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';
import PALETTE from '../CFISP_utils/palette';
import { OwnedCoursesHorizontalList } from '../components';
import FeaturedCoursesInFieldComponent from '../components/featuredCoursesInFieldComponent';
import { MagnifyingGlass } from '../icons';
import { styles } from '../styles/home';

type ProfileScreenNavigationProp = StackNavigationProp<RootStackParamList>;

export interface Props {
    navigation: ProfileScreenNavigationProp,
}

interface State {
    isLoading: boolean,
    anonymous: boolean
}

class HomeScreen extends Component<Props, State> {
    public static navigationOptions = {
        title: 'Home',
    };

    constructor(props: Props) {
        super(props)
        this.state = { isLoading: true, anonymous: !stateMachine.authenticated }
        stateMachine.setHomeScreen(this);
    }

    _isMounted: boolean = false;

    componentDidMount() {
        this._isMounted = true;
        if (stateMachine.fields.length == 0) {
            http.fetchFields(() => {
                this.setState({ ...this.state, isLoading: false })
            });
        }

        if (!this.state.anonymous) {
            http.fetchOwnedCourses(() => this.setState({ ...this.state, isLoading: false }));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let fields = stateMachine.getFields();
        let greetings = "";

        if (stateMachine.user.firstName) greetings = `Olá, ${stateMachine.user.firstName}`

        /* ----- ECOMMERCE FEATURE ----- */
        // const fieldsList = fields.map((item: any) =>
        //     <FeaturedCoursesInFieldComponent key={item.id} field={item} navigation={this.props.navigation}/>
        // );

        return (
            <Fragment>
                <ScrollView>
                    <StatusBar barStyle="dark-content" backgroundColor={PALETTE.darkYellow} />
                    <View>
                        <View style={styles.header}>
                            {/* /* ----- ECOMMERCE FEATURE ----- */}
                            {/* <Pressable
                                onPress={() => this.props.navigation.navigate("Search")}
                            >
                                <MagnifyingGlass style={styles.icon} />
                            </Pressable> */}
                            { !this.state.anonymous
                                ? <Text style={styles.username}>{greetings}</Text>
                                : <View style={styles.loginContainer} onStartShouldSetResponder={()=>{this.props.navigation.navigate("Login"); return true;}}>
                                    <Text style={styles.loginText}>Entrar</Text>
                                </View>
                            }
                            <Text style={styles.text}>O que você quer estudar?</Text>
                        </View>
                        <View style={styles.courses}>

                            { !this.state.anonymous ?
                                <View>
                                    <View style={styles.headerCourses}>
                                        <Text style={styles.titleCourse}>MEUS CURSOS</Text>
                                        <Pressable onPress={() => this.props.navigation.navigate('OwnedCourses')}>
                                            <Text style={styles.link}>Ver Todos</Text>
                                        </Pressable>
                                    </View>
                                    <View>
                                        <View>
                                            {this.state.isLoading ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> : (
                                                <OwnedCoursesHorizontalList navigation={this.props.navigation}/>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                : null
                            }

                            {/* ----- ECOMMERCE FEATURE ----- */}
                            {/* {fieldsList} */}

                        </View>
                    </View>
                </ScrollView>
            </Fragment>
        );
    }
};

export default HomeScreen;
