import { Field, Course, Subject, Lesson, EvaluationAttempt } from './content_types';
import AsyncStorage from '@react-native-community/async-storage';
import http from './http';
import { welcomeTutorial, courseTutorial } from './CFISP_utils/tutorials';
import { User, MaritalStatus } from './user';
import { PaymentCard } from './payment_types';
import { call } from 'react-native-reanimated';

class StateMachine {
    authenticated: boolean;
    token: string;
    username: string | null;
    userId: number;
    fields: Field[];
    purchasableCourses: Course[];
    ownedCourses: Course[];
    subjects: Subject[];
    subject: Subject | undefined;
    lessons: Lesson[];
    lesson: Lesson | null;
    app: any | null;
    purchasableCourse: Course | null;
    navigation: any;
    homeScreen: any;
    ownedCoursesScreen: any;
    profileScreen: any;
    subjectScreen: any;
    favoriteScreen: any;
    haveSeenTutorial: boolean[];
    course: null | Course;
    favoriteCourses: Course[];
    user: User;
    paymentCard: PaymentCard | null = null;
    courses: Course[] = [];
    courseListUpdatedSubscribers: any[] = [];
    unansweredFetchCoursesRequests: number = 0;
    unfinishedEvaluation: number | null = null;

    constructor() {
        this.authenticated = false;
        this.token = "";
        this.username = "";
        this.userId = -1;
        this.fields = [];
        this.purchasableCourses = [];
        this.ownedCourses = [];
        this.subjects = [];
        this.lessons = [];
        this.lesson = null;
        this.app = null;
        this.purchasableCourse = null;
        this.haveSeenTutorial = [false, false];
        this.course = null;
        this.favoriteCourses = [];
        this.user = new User("", -1);
        
        this.isLoggedIn();
        this.retrieveHasSeenWelcomeTutorial();
    }

    clear() {
        this.authenticated = false;
        this.token = "";
        this.username = "";
        // this.fields = [];
        this.purchasableCourses = [];
        this.ownedCourses = [];
        this.subjects = [];
        this.lessons = [];
        this.lesson = null;
        this.app = null;
        this.purchasableCourse = null;

        // User data
        this.user.firstName = "";
        this.user.lastName = "";
        this.user.fullName = "";
        this.user.idCPF = "";
        this.user.idRG = "";
        this.user.dateOfBirth = null;
        this.user.cityOfBirth = "";
        this.user.maritalStatus = MaritalStatus.SINGLE;
        this.user.phone = "";
        this.user.address = "";
        this.user.postalCode = "";
        this.user.graduation = "";
        this.user.graduationCity = "";
        this.user.graduationYear = "";
        this.user.graduationInstitution = "";
        this.user.intendedCourseID = null;
    }

    async isLoggedIn(): Promise<boolean> {
        try {
            let token = await AsyncStorage.getItem('@token');
            let name = await AsyncStorage.getItem('@username');
            let userId = await AsyncStorage.getItem('@user_id');
    
            if (token !== null && name !== null && userId !== null) {
                this.authenticate(token, name);
                this.setUserId(Number(userId));
    
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async retrieveHasSeenWelcomeTutorial() {
        try {
            let hasSeenWelcomeTutorial = await AsyncStorage.getItem('@hasSeenWelcomeTutorial');
    
            if (hasSeenWelcomeTutorial !== null && hasSeenWelcomeTutorial == 'true') {
                this.haveSeenTutorial[0] = true

                if (this.app) {
                    this.app.setState({ ...this.app.state, haveSeenWelcomeTutorial: true })
                }
            }

        } catch (e) {
            console.error(e);
        }
    }

    storeToken = async (token: string, name: string) => {
        try {
            AsyncStorage.setItem('@token', token);
            AsyncStorage.setItem('@username', name);
        } catch (e) {
            console.error(e);
        }
    };

    authenticate(token: string, username: string) {
        this.token = token;
        this.username = username;
        this.authenticated = true;

        http.getUserData((u) => {
            this.user.id = u.user_id;
            this.user.email = u.email;
            this.user.address = u.address;
            this.user.cityOfBirth = u.city_of_birth;
            this.user.dateOfBirth = u.date_of_birth;
            this.user.firstName = u.first_name;
            this.user.fullName = u.full_name;
            this.user.graduation = u.graduation;
            this.user.graduationCity = u.graduation_city;
            this.user.graduationInstitution = u.graduation_institution;
            this.user.graduationYear = u.graduation_year;
            this.user.idCPF = u.id_CPF;
            this.user.idRG = u.id_RG;
            this.user.intendedCourseID = u.intended_course_id;
            this.user.lastName = u.last_name;
            this.user.maritalStatus = u.marital_status;
            this.user.phone = u.phone;
            this.user.postalCode = u.postal_code;

            if (this.homeScreen && this.homeScreen._isMounted) {
                this.homeScreen.setState({ ...this.homeScreen.state, isLoading: true, anonymous: !this.authenticated })
                http.fetchOwnedCourses(() => this.homeScreen.setState({
                    ...this.homeScreen.state, isLoading: false, anonymous: !this.authenticated
                }));
            }

            if (this.ownedCoursesScreen && this.ownedCoursesScreen._isMounted) {
                this.ownedCoursesScreen.setState({ ...this.ownedCoursesScreen.state })
            }
    
            if (this.profileScreen && this.profileScreen._isMounted) {
                this.profileScreen.setState({ ...this.profileScreen.state, authenticated: this.authenticated, user: this.user })
            }
    
            if (this.favoriteScreen && this.favoriteScreen._isMounted) {
                this.fetchFavoriteCourses();
            }
    
            http.hasUnfinishedEvaluation((res) => {
                this.unfinishedEvaluation = res[0].subject;
            })
        })
    }

    logout() {
        AsyncStorage.clear();
        this.authenticated = false;
        
        this.clear();

        if (this.homeScreen && this.homeScreen._isMounted) {
            this.homeScreen.setState({ ...this.homeScreen.state, anonymous: true })
        }

        if (this.ownedCoursesScreen && this.ownedCoursesScreen._isMounted) {
            this.ownedCoursesScreen.setState(this.ownedCoursesScreen.state);
        }

        if (this.profileScreen && this.profileScreen._isMounted) {
            this.profileScreen.setState({ ...this.profileScreen.state, authenticated: false })
        }

        this.navigation.navigate("Tabs");
    }

    isAuthenticated() {
        return this.authenticated;
    }

    goToLogin() {
        this.navigation.navigate('Login');
    }

    setApp(app: any) {
        this.app = app;
    }

    setNavigation(navigation: any) {
        this.navigation = navigation;
    }

    setHomeScreen(homeScreen: any) {
        this.homeScreen = homeScreen;
        if (homeScreen._isMounted){
            homeScreen.setState({ ...homeScreen.state, anonymous: !this.authenticated });
        }
    }

    setOwnedCoursesScreen(ownedCoursesScreen: any) {
        this.ownedCoursesScreen = ownedCoursesScreen;
    }

    setProfileScreen(profileScreen: any) {
        this.profileScreen = profileScreen;
        if (this.profileScreen._isMounted) {
            profileScreen.setState({ ...profileScreen.state, authenticated: this.authenticated })
        }
    }

    setSubjectScreen(subjectScreen: any) {
        this.subjectScreen = subjectScreen;
    }

    setFavoriteScreen(favoriteScreen: any) {
        this.favoriteScreen = favoriteScreen;
        this.fetchFavoriteCourses();
    }

    setFavoriteCourses(courses: Course[]) {
        this.favoriteCourses = courses;
    }

    fetchFavoriteCourses() {
        if (this.authenticated) {
            if (this.favoriteScreen && this.favoriteScreen._isMounted) {
                this.favoriteScreen.setState({ authenticated: this.authenticated, courses: [], waiting: true })

                http.fetchFavoriteCourses((courses: Course[]) => {
                    this.setFavoriteCourses(courses);
                        this.favoriteScreen.setState({ authenticated: this.authenticated, courses, waiting: false })
                    }
                )
            }
        }
    }

    removeFavoriteCourse(course: Course) : void {
        this.favoriteCourses.splice(this.favoriteCourses.indexOf(course), 1);
        if (this.favoriteScreen && this.favoriteScreen._isMounted) {
            this.favoriteScreen.setState({ authenticated: this.authenticated, courses: this.favoriteCourses })
        }
    }

    addField(field: Field) {
        this.fields.push(field);
    }

    setFields(fields: Field[]) {
        this.fields = fields;
    }

    getFields() {
        return this.fields;
    }

    addPurchasableCourses(course: Course) {
        this.purchasableCourses.push(course);
    }

    addOwnedCourses(course: Course) {
        this.ownedCourses.push(course);
    }

    getOwnedCourse(id: number) : Course {
        for (let i=0; i< this.ownedCourses.length; i++) {
            if (this.ownedCourses[i].id == id) {
                return this.ownedCourses[i];
            }
        }

        return this.ownedCourses[0];
    }

    ownsCourse(course: Course): boolean {
        let owns : boolean = false;

        for (let i=0; i<this.ownedCourses.length; i++) {
            if (this.ownedCourses[i].id === course.id) {
                owns = true;
                break;
            }
        }

        return owns;
    }

    addSubject(subject: Subject) {
        this.subjects.push(subject);
    }

    addLesson(lesson: Lesson) {
        this.lessons.push(lesson);
    }

    setLesson(lesson: Lesson) {
        this.lesson = lesson;
    }

    setPurchasableCourse(course: Course) {
        this.purchasableCourse = course;
    }

    nextLesson() : Lesson | undefined {
        if (!this.lesson) return;
        let index = this.lessons.indexOf(this.lesson) + 1;
        if (index == -1 || index == this.lessons.length) {
            this.goToThisSubjectList();
            return;
        }

        this.lesson = this.lessons[index];

        return this.lesson;
    }

    // Tutorials
    haveSeenWelcomTutorial(): boolean {
        return this.haveSeenTutorial[0];
    }

    setHaveSeenWelcomeTutorial() {
        try {
            AsyncStorage.setItem('@hasSeenWelcomeTutorial', 'true');
        } catch (e) {
            console.error(e);
        }

        this.haveSeenTutorial[0] = true;
        this.app.setState({ ...this.app.state, haveSeenWelcomeTutorial: true });
        this.navigation.navigate('Tabs');
    }

    setSubject(subject: Subject) {
        this.subject = subject;
    }

    goToNextSubject() {
        if (this.subjects.length == 0) return;
        if (!this.navigation) return;
        if (!this.subject) return;
        if (!this.subjectScreen) return;

        let index = this.subjects.indexOf(this.subject);

        if (index + 1 == this.subjects.length) {
            this.goToThisSubjectList();
            return;
        }

        this.subject = this.subjects[index + 1];
        
        this.subjectScreen.setState({ ...this.subjectScreen.state, tab: 0 });
        this.navigation.navigate("Subject", { subject: this.subject, fullAccess: true });
    }

    goToThisSubjectList() {
        if (!this.subjectScreen) return;
        if (!this.subject) {
            return;
        }

        this.subjectScreen.setState({ ...this.subjectScreen.state, tab: 0 });
        this.navigation.navigate("Subject", { subject: this.subject, fullAccess: true });
        this.subjectScreen.scrollToTop();
    }

    goToThisSubjectEvaluation() {
        if (!this.subjectScreen) return;

        this.subjectScreen.setState({ ...this.subjectScreen.state, tab: 2 });
        this.navigation.navigate("Subject", { subject: this.subject, fullAccess: true });
    }

    setCourse(course: Course) {
        this.course = course;
    }

    getCourse() {
        return this.course;
    }

    isSelectedCourseFavorite() : boolean {
        if (this.course == null) {
            return false;
        }

        return this.course.isFavorite;
    }

    setUserId(id: number) {
        this.userId = id;

        try {
            AsyncStorage.setItem('@user_id', `${id}`);
        } catch (e) {
            console.error(e);
        }
    }

    getUserId() {
        return this.userId;
    }

    getUser() : User {
        return this.user;
    }

    getCourses() : Course[] {
        return this.courses;
    }

    addCourse(course: Course) {
        this.courses[course.id-1] = course;
    }

    notifyCoursesUpdate() {
        for (let i=0; i<this.courses.length; i++) {
            if (!this.courses[i]) return;
        }

        for (let i=0; i<this.courseListUpdatedSubscribers.length; i++) {
            this.courseListUpdatedSubscribers[i].updateCourses();
        }
    }

    addCourseListUpdateSubscriber(s: any) {
        this.courseListUpdatedSubscribers.push(s);
    }

    removeCourseListUpdateSubscriber(s: any) {
        this.courseListUpdatedSubscribers.splice(this.courseListUpdatedSubscribers.indexOf(s), 1);
    }

    updateUserData(student: any) {
        this.user.address = student.address;
        this.user.cityOfBirth = student.cityOfBirth;
        this.user.dateOfBirth = student.dateOfBirth;
        this.user.firstName = student.firstName;
        this.user.fullName = student.fullName;
        this.user.graduation = student.graduation;
        this.user.graduationCity = student.graduationCity;
        this.user.graduationInstitution = student.graduationInstitution;
        this.user.graduationYear = student.graduationYear;
        this.user.idCPF = student.idCPF;
        this.user.idRG = student.idRG;
        this.user.intendedCourseID = student.intendedCourse;
        this.user.lastName = student.lastName;
        this.user.maritalStatus = student.maritalStatus;
        this.user.phone = student.phone;
        this.user.postalCode = student.postalCode;

        this.navigation.navigate("Profile", { user: this.user });
        this.profileScreen.updateCourses();
    }

    updateEvaluation(endTimestamp: string, grade: number, callback: () => void) {
        if (!this.subject) {
            callback();
            return;
        }

        this.subject.evaluationAttempts[this.subject.evaluationAttempts.length -1].endTimestamp = endTimestamp;
        this.subject.evaluationAttempts[this.subject.evaluationAttempts.length -1].grade = grade;

        callback();
    }

    addEvaluationAttemptToSubject(evaluationAttempt: EvaluationAttempt) {
        if (!this.subject) return;

        for (let i=0; i<this.subject.evaluationAttempts.length; i++) {
            if (this.subject.evaluationAttempts[i].id === evaluationAttempt.id) {
                return;
            }
        }

        this.subject.evaluationAttempts.push(evaluationAttempt);
    }

    answerEvaluationQuestion(id: number, answer: number) {
        if (!this.subject) {
            return;
        }

        for (let i=0; i<this.subject.evaluation.length; i++) {
            if (this.subject.evaluation[i].id == id) {
                this.subject.evaluation[i].answer = answer;
                break;
            }
        }
    }
}

let stateMachine = new StateMachine();
export default stateMachine;
