export class TimelineItem {
    date: string;
    heading: string;
    body: string;
    consumed: boolean;

    constructor(
        date: string,
        heading: string,
        body: string,
    ) {
        this.date = date;
        this.heading = heading;
        this.body = body;
        this.consumed = false;
    }

    consume() : boolean {
        if (!this.consumed) {
            this.consumed = true;
            return true;
        } else return false;
    }
}

export default TimelineItem;
