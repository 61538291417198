import Interaction from './interaction';

export type Draggable = { text: string, position: number }
export type Fragment = { text: string, position: number }

class TextDragAndDrop extends Interaction {
    typeName: string = "TextDragAndDrop";
    instructions: string;
    draggables: Draggable[];
    fragments: Fragment[];

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        points: number,
        explanation: string,
        instructions: string,
        draggables: Draggable[],
        fragments: Fragment[]
    ) {
        super(id, pub_date, consumed, points, explanation);
        this.instructions = instructions;
        this.draggables = draggables;
        this.fragments = fragments;
    }
}

export default TextDragAndDrop;
