import React, { Component } from 'react';
import { StyleSheet, View, Text, FlatList, ActivityIndicator, Dimensions } from 'react-native';
import http from '../http';
import { Subject, Lesson } from '../content_types';
import LessonListItem from '../components/lessonListItem';
import stateMachine from '../StateMachine';
import PALETTE from '../CFISP_utils/palette';

type Props = {
    navigation: any,
    subject: Subject
}

type State = {
    isLoading: boolean,
    lessons: Lesson[]
}

class LessonList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { isLoading: true, lessons: [] }
    }
    
    componentDidMount() {
        http.fetchLessons(this.props.subject.id, () => {
            this.setState({ isLoading: false, lessons: stateMachine.lessons })
        });
    }

    renderLessonList() {
        return this.state.lessons.map((item, index) => {
            return (
                <LessonListItem navigation={this.props.navigation} lesson={item} key={index} index={index} />
            )
        })
    }

    render() {
        return (
            <View style={styles.root}>
                <View style={styles.ownedListContainer}>
                    {this.state.isLoading ? <ActivityIndicator animating={true} size="large" color={PALETTE.yellow} /> : (
                        <View>
                            {this.renderLessonList()}
                        </View>
                    )}
                </View>
            </View>
        );
    }
};

// styles
const styles = StyleSheet.create({
    root: {
        // alignItems: 'center',
        // alignSelf: 'stretch'
    },
    ownedListContainer: {
        marginTop: 20
    },
    purchasableListContainer: {
        marginTop: 12
    },
    buttons: {
        flexDirection: 'row',
        minHeight: 70,
        alignItems: 'stretch',
        alignSelf: 'center',
        borderWidth: 5,
    },
    button: {
        flex: 1,
        paddingVertical: 0,
    },
    separator: {
        textAlign: 'center'
    },
    list: {
        height: Dimensions.get("window").height - 150
    }
});

export default LessonList;
