import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 .1h24v24H0z" />
      <Path
        d="M7.6 6.3l1.7-1.8 7.5 7.5-7.5 7.5-1.7-1.7 5.7-5.8-5.7-5.7z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#444"
      />
    </Svg>
  )
}

export default SvgComponent
