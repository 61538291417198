import Resource from './resource';

class Infograph extends Resource {
    src: string;
    typeName: string = "Infograph";

    constructor(
        id: number,
        pub_date: Date,
        consumed: boolean,
        src: string
    ) {
        super(id, pub_date, consumed);
        this.src = src
    }
}

export default Infograph;
