import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform } from 'react-native';
import PALETTE from '../CFISP_utils/palette';

interface Props {
    color: string,
    text: string,
    action: any,
    fontSize?: number,
    paddingVertical?: number,
    paddingHorizontal?: number,
    width?: string,
    marginTop?: number
}

export default class CenterButton extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return <View style={[{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center'
        }, this.props.marginTop ? {marginTop: this.props.marginTop} : {}]}>
            <TouchableOpacity
                onPress={() => {this.props.action()}}
                activeOpacity={0.7}
                style={[{
                    backgroundColor: this.props.color,
                    paddingHorizontal: this.props.paddingHorizontal ? this.props.paddingHorizontal : 40,
                    paddingVertical: this.props.paddingVertical ? this.props.paddingVertical : 13,
                    borderRadius: 100,
                    flexDirection: 'column',
                    justifyContent: 'center',
                }, this.props.width ? {width: this.props.width} : {}]}
            >
                <Text style={{
                    color: PALETTE.background,
                    fontSize: this.props.fontSize ? this.props.fontSize : 20,
                    textAlign: 'center'
                }}>{this.props.text}</Text>
            </TouchableOpacity>
        </View>
    }
}
