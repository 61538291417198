import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <Circle id="prefix__circle835" r={8} cy={8} cx={12} fill="#fc0" />
      <Path
        d="M12.4 1.05c-3.342 0-6.148 2.827-6.148 6.25 0 1.967.936 3.642 2.371 4.71-1.227.304-2.346.752-3.309 1.324-1.858 1.104-3.182 2.755-3.25 4.71a.949.949 0 00-.013.126l-.1 3.215v.015c0 .467.171.78.403 1.063.231.283.574.586 1.146.586h17.9c.572 0 1.028-.466 1.176-.793.149-.327.172-.6.172-.856l.1-3.17a.949.949 0 00.002-.03l-.002-.024a.949.949 0 00-.01-.11c-.058-1.956-1.355-3.617-3.197-4.728-.987-.595-2.147-1.053-3.42-1.358a5.812 5.812 0 002.328-4.68c0-3.4-2.725-6.25-6.149-6.25zm0 1.9c2.377 0 4.25 1.952 4.25 4.35 0 2.4-1.808 4.052-4.25 4.052S8.148 9.699 8.148 7.3c0-2.377 1.995-4.352 4.252-4.352zm.1 10.5c2.448 0 4.645.6 6.16 1.513 1.512.911 2.288 2.046 2.291 3.228l-.092 2.96H3.857l.092-2.92a.949.949 0 000-.014.949.949 0 000-.018c0-1.175.794-2.318 2.336-3.234 1.542-.916 3.767-1.516 6.215-1.516z"
        style={{
          lineHeight: "normal",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          textOrientation: "mixed",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          isolation: "auto",
          mixBlendMode: "normal",
          solidColor: "#000",
          solidOpacity: 1,
        }}
        id="prefix__path860"
        color="#000"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={400}
        fontStretch="normal"
        fontSize="medium"
        fontFamily="sans-serif"
        textDecoration="none"
        letterSpacing="normal"
        wordSpacing="normal"
        writingMode="lr-tb"
        direction="ltr"
        dominantBaseline="auto"
        baselineShift="baseline"
        textAnchor="start"
        clipRule="nonzero"
        display="inline"
        overflow="visible"
        visibility="visible"
        colorInterpolation="sRGB"
        colorInterpolationFilters="linearRGB"
        vectorEffect="none"
        fill="#444"
        fillOpacity={1}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1.897}
        strokeLinecap="round"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        strokeDasharray="none"
        strokeDashoffset={0}
        strokeOpacity={1}
        colorRendering="auto"
        imageRendering="auto"
        shapeRendering="auto"
        textRendering="auto"
        stopColor="#000"
        stopOpacity={1}
        opacity={1}
      />
    </Svg>
  )
}

export default SvgComponent
