import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      xmlSpace="preserve"
      viewBox="0 0 20 20"
      y={0}
      x={0}
      id="prefix__Camada_1"
      {...props}
    >
      <G transform="translate(0 -.3)" id="prefix__g877">
        <Circle
          r={10}
          id="prefix__ellipse835"
          cy={10.3}
          cx={10}
          fill="#e9472c"
        />
        <Path
          id="prefix__path860"
          style={{
            lineHeight: "normal",
            fontVariantLigatures: "normal",
            fontVariantPosition: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantAlternates: "normal",
            fontVariantEastAsian: "normal",
            fontFeatureSettings: "normal",
            fontVariationSettings: "normal",
            textIndent: 0,
            textAlign: "start",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            textOrientation: "mixed",
            whiteSpace: "normal",
            shapePadding: 0,
            shapeMargin: 0,
            inlineSize: 0,
            isolation: "auto",
            mixBlendMode: "normal",
            solidColor: "#000",
            solidOpacity: 1,
          }}
          d="M12.62 6.2a1 1 0 00-.083.001 1 1 0 00-.65.297L9.65 8.771 7.412 6.498a1 1 0 00-.76-.297 1 1 0 00-.654.285A1 1 0 005.986 7.9l2.243 2.28-2.143 2.216-.1.102-.006.008A1 1 0 007.412 13.9l.006-.007.002.002.115-.12 2.096-2.13 2.457 2.457.006.006a1 1 0 001.42-1.409l-.006-.006-2.455-2.494 2.26-2.299a1 1 0 00-.012-1.414 1 1 0 00-.682-.287z"
          color="#000"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight={400}
          fontStretch="normal"
          fontSize="medium"
          fontFamily="sans-serif"
          textDecoration="none"
          letterSpacing="normal"
          wordSpacing="normal"
          writingMode="lr-tb"
          direction="ltr"
          dominantBaseline="auto"
          baselineShift="baseline"
          textAnchor="start"
          clipRule="nonzero"
          display="inline"
          overflow="visible"
          visibility="visible"
          colorInterpolation="sRGB"
          colorInterpolationFilters="linearRGB"
          vectorEffect="none"
          fill="#fff"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeDasharray="none"
          strokeDashoffset={0}
          strokeOpacity={1}
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          textRendering="auto"
          stopColor="#000"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
