import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput, Platform } from 'react-native';
import CheckBox from '@react-native-community/checkbox';
import PALETTE from '../CFISP_utils/palette';
import { CenterButton } from '../components'
import { deepCopy } from '../CFISP_utils/functions';
import stateMachine from '../StateMachine';
import { Course } from '../content_types';
import { User, MaritalStatus } from '../user';
import { DatePicker, MaritalStatusPicker, IntendedCoursePicker } from './';

interface Props {
    user: User,
    course: Course,
    courses: Course[],
    parent: any
}

interface State {
    firstName: string
    lastName: string
    fullName: string
    idCPF: string
    idRG: string
    dateOfBirth: string
    cityOfBirth: string
    maritalStatus: MaritalStatus
    phone: string
    address: string
    postalCode: string
    graduation: string
    graduationYear: string
    graduationInstitution: string
    graduationCity: string
    intendedCourse: number
}

export default class ProfileDataEditor extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let intendedCourse = 1;
        if (this.props.user.intendedCourseID) intendedCourse = this.props.user.intendedCourseID;

        this.state = {
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            fullName: this.props.user.fullName,
            idCPF: this.props.user.idCPF,
            idRG: this.props.user.idRG,
            dateOfBirth: `${this.props.user.dateOfBirth}`,
            cityOfBirth: this.props.user.cityOfBirth,
            maritalStatus: this.props.user.maritalStatus,
            phone: this.props.user.phone,
            address: this.props.user.address,
            postalCode: this.props.user.postalCode,
            graduation: this.props.user.graduation,
            graduationYear: this.props.user.graduationYear,
            graduationInstitution: this.props.user.graduationInstitution,
            graduationCity: this.props.user.graduationCity,
            intendedCourse
        }
    }

    updateDateOfBirth(dateOfBirth: any) {
        this.setState({ ...this.state, dateOfBirth })
    }
    
    updateMaritalStatus(maritalStatus: MaritalStatus) {
        this.setState({ ...this.state, maritalStatus })
    }
    
    updateIntendedCourse(intendedCourse: Course) {
        this.setState({ ...this.state, intendedCourse: intendedCourse.id })
    }

    render() {
        return <View style={{
            marginTop: 30
        }}>
            <View style={{
                marginVertical: 10,
                backgroundColor: PALETTE.gray_k,
                padding: 10
            }}>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Primeiro nome:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={firstName => {
                                this.setState({ ...this.state, firstName });
                            }}
                            value={ this.state.firstName }
                            placeholder={""}
                            textContentType="name"
                            autoCapitalize="words"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Último nome:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={lastName => {
                                this.setState({ ...this.state, lastName });
                            }}
                            value={ this.state.lastName }
                            placeholder={"Último nome"}
                            textContentType="name"
                            autoCapitalize="words"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Nome completo:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={fullName => {
                                this.setState({ ...this.state, fullName });
                            }}
                            value={ this.state.fullName }
                            placeholder={"Nome completo"}
                            textContentType="name"
                            autoCapitalize="words"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            CPF:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={idCPF => {
                                this.setState({ ...this.state, idCPF });
                            }}
                            value={ this.state.idCPF }
                            placeholder={"xxx.xxx.xxx-xx"}
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            RG:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={idRG => {
                                this.setState({ ...this.state, idRG });
                            }}
                            value={ this.state.idRG }
                            placeholder={""}
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Data de nascimento:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        { this.state.dateOfBirth
                            ? <DatePicker initialDate={`${this.state.dateOfBirth}`} parent={this} />
                            : <DatePicker initialDate="2000-01-01" parent={this} />
                        }
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Cidade de nascimento:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={cityOfBirth => {
                                this.setState({ ...this.state, cityOfBirth });
                            }}
                            value={ this.state.cityOfBirth }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Estado civil:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <MaritalStatusPicker maritalStatus={this.state.maritalStatus} parent={this} />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Fone:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={phone => {
                                this.setState({ ...this.state, phone });
                            }}
                            value={ this.state.phone }
                            textContentType="telephoneNumber"
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Endereço:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={address => {
                                this.setState({ ...this.state, address });
                            }}
                            value={ this.state.address }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            CEP:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={postalCode => {
                                this.setState({ ...this.state, postalCode });
                            }}
                            value={ this.state.postalCode }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Graduação:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={graduation => {
                                this.setState({ ...this.state, graduation });
                            }}
                            value={ this.state.graduation }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Ano de graduação:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={graduationYear => {
                                this.setState({ ...this.state, graduationYear });
                            }}
                            value={ this.state.graduationYear }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Instituição de graduação:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={graduationInstitution => {
                                this.setState({ ...this.state, graduationInstitution });
                            }}
                            value={ this.state.graduationInstitution }
                        />
                    </View>
                </View>
                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Cidade de graduação:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <TextInput
                            style={styles.input}
                            onChangeText={graduationCity => {
                                this.setState({ ...this.state, graduationCity });
                            }}
                            value={ this.state.graduationCity }
                        />
                    </View>
                </View>

                <View style={styles.fiscalDataDisplayItem}>
                    <View style={styles.fiscalDataDisplayLabelContainer}>
                        <Text style={styles.fiscalDataDisplayLabelText}>
                            Curso pretendido:
                        </Text>
                    </View>
                    <View style={styles.fiscalDataDisplayValueContainer}>
                        <IntendedCoursePicker courses={this.props.courses} course={this.props.course} parent={this} />
                    </View>
                </View>
                
                <CenterButton text="Salvar" action={() => {
                    this.props.parent.saveUserData(this.state)
                }} color={PALETTE.blue} />
            </View>
        </View>
    }
}

const styles = StyleSheet.create({
    item: {
        padding: 10,
        marginVertical: 10,
        borderRadius: 6,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            web: {
                cursor: "pointer"
            }
        })
    },
    normal: {
        backgroundColor: PALETTE.gray_b,
    },
    active: {
        backgroundColor: PALETTE.anotherGreen,
    },
    text: {
        textAlign: "right",
        color: PALETTE.forground,
    },
    activeText: {
        textAlign: "right",
        color: PALETTE.background,
        fontWeight: 'bold'
    },
    fiscalDataDisplayItem: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fiscalDataDisplayLabelContainer: {
        flex: 1
    },
    fiscalDataDisplayLabelText: {
        textAlign: 'right',
        marginRight: 7
    },
    fiscalDataDisplayValueContainer: {
        flex: 3
    },
    fiscalDataDisplayValueText: {
        fontWeight: 'bold'
    },
    input: {
        height: 50,
        borderColor: PALETTE.gray_g,
        borderWidth: 1,
        backgroundColor: PALETTE.background,
        marginBottom: 24,
        borderRadius: 50,
        paddingHorizontal: 24,
        fontSize: 20
    }
});
