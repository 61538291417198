import React, { Component } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Image,
    Dimensions,
    Pressable,
    TouchableOpacity,
    Platform
} from 'react-native';
import { ComparisonItem } from '../../content_types';
import PALETTE from '../../CFISP_utils/palette';
import { Minus } from '../../icons';
import { S3_IMAGES } from '../../CFISP_utils/environmentConstants';
import Markdown from 'react-native-markdown-display';
import { MarkdownCustomRules } from '../../CFISP_utils/markdownCustomRules';

const win = Dimensions.get('window');

enum Show {
    none,
    left,
    right,
}

type Props = {
    item: ComparisonItem,
    show: Show,
    parent: any,
    index: number,
    top: number
};

type State = {
    rightPaneWidth: number
    leftPaneWidth: number
}

const iconSize = 20

class ComparisonItemPaneComponent extends Component<Props, State> {
    iconRatio: number;
    iconWidth: number;
    iconHeight: number;

    constructor(props: Props) {
        super(props);

        this.iconRatio = 1;
        this.iconWidth = 1;
        this.iconHeight = 1;

        this.state = { leftPaneWidth: 0, rightPaneWidth: 0 }
    }

    setLeftPaneWidth: boolean = true;
    setRightPaneWidth: boolean = true;

    render() {
        return (
            <View>
                <View
                    style={ this.props.show == Show.left
                        ? [
                            styles.pane,
                            styles.leftPane,
                            { left: 0 },
                            { top: -this.props.top }
                        ] : [
                            styles.pane,
                            styles.leftPane,
                            { left: -(this.state.rightPaneWidth + 34) },
                            { top: -this.props.top }
                        ]
                    }
                    onLayout={(event) => {
                        if (this.setLeftPaneWidth) {
                            this.setLeftPaneWidth = false;
                            this.setState({ ...this.state, leftPaneWidth: event.nativeEvent.layout.width })
                        }
                    }}
                >
                    <View style={[styles.paneHeader, styles.leftPanelHeader]}>
                        { Platform.OS == "web"
                            ? <Pressable
                                onPress={() => this.props.parent.setExpandedItem(this.props.index, Show.none)}
                                style={{
                                    backgroundColor: PALETTE.darkYellow_b,
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    marginRight: 10,
                                    maxHeight: iconSize
                                }}
                            >
                                <Minus width={iconSize} height={iconSize} />
                            </Pressable>
                            : <TouchableOpacity
                                onPress={() => this.props.parent.setExpandedItem(this.props.index, Show.none)}
                                activeOpacity={0.7}
                                style={{
                                    backgroundColor: PALETTE.darkYellow_b,
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    marginRight: 10,
                                    maxHeight: iconSize
                                }}
                            >
                                <Minus width={iconSize} height={iconSize} />
                            </TouchableOpacity>
                        }
                        
                        <View style={styles.panelTitleContainer}>
                            <Markdown rules={ MarkdownCustomRules } style={{ body: styles.paneTitleText }}>
                                { this.props.item.leftTitle }
                            </Markdown>
                        </View>
                    </View>
                    <Markdown rules={ MarkdownCustomRules } style={{ body: styles.paneText }}>
                        { this.props.item.leftText }
                    </Markdown>
                    <Image
                        source={{uri: `${S3_IMAGES}${this.props.item.icon}`}}
                        style={[{
                            width: 34,
                            height: 34,
                            position: "absolute",
                            top: 30,
                            right: -17
                        }]}
                    />
                </View>

                <View
                    style={ this.props.show == Show.right
                        ? [
                            styles.pane,
                            styles.rightPane,
                            { right: 0 },
                            { top: -this.props.top }
                        ] : [
                            styles.pane,
                            styles.rightPane,
                            { right: -(this.state.rightPaneWidth + 34) },
                            { top: -this.props.top }
                        ]
                    }
                    onLayout={(event) => {
                        if (this.setRightPaneWidth) {
                            this.setRightPaneWidth = false;
                            this.setState({ ...this.state, rightPaneWidth: event.nativeEvent.layout.width })
                        }
                    }}
                >
                    <View style={[styles.paneHeader, styles.rightPanelHeader]}>
                        <View style={styles.panelTitleContainer}>
                            <Markdown rules={ MarkdownCustomRules } style={{ body: styles.paneTitleText }}>
                                { this.props.item.rightTitle }
                            </Markdown>
                        </View>
                        { Platform.OS == "web"
                            ? <Pressable
                                onPress={(event) => {
                                    this.props.parent.setExpandedItem(this.props.index, Show.none);
                                }}
                                style={{
                                    backgroundColor: PALETTE.darkYellow_b,
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    marginRight: 10,
                                    maxHeight: iconSize
                                }}
                            >
                                <Minus width={iconSize} height={iconSize} />
                            </Pressable>
                            : <TouchableOpacity
                                onPress={(event) => {
                                    this.props.parent.setExpandedItem(this.props.index, Show.none);
                                }}
                                activeOpacity={0.7}
                                style={{
                                    backgroundColor: PALETTE.darkYellow_b,
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    marginRight: 10,
                                    maxHeight: iconSize
                                }}
                            >
                                <Minus width={iconSize} height={iconSize} />
                            </TouchableOpacity>
                        }
                    </View>

                    <View style={styles.paneTextContainer}>
                        <Markdown rules={ MarkdownCustomRules } style={{ body: styles.paneText }}>
                            { this.props.item.rightText }
                        </Markdown>
                    </View>

                    <Image
                        source={{uri: `${S3_IMAGES}${this.props.item.icon}`}}
                        style={[{
                            width: 34,
                            height: 34,
                            position: "absolute",
                            top: 30,
                            left: -17
                        }]}
                    />
                </View>
            </View>
        )
    }
}

const columnsRation = {
    left: 2,
    right: 5
}

const styles = StyleSheet.create({
    pane: {
        backgroundColor: PALETTE.lightGrey,
        width: "85%",
        position: 'absolute',
        paddingVertical: 20,
    },
    paneHeader: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftPanelHeader: {
        marginLeft: 10,
        marginRight: 30
    },
    rightPanelHeader: {
        marginLeft: 30,
        marginRight: 10
    },
    panelTitleContainer: {
        flex: 5,
    },
    minusIcon: {
        flex: 1,
        flexDirection: 'row-reverse',
        padding: 5,
    },

    paneTitleText: {
        fontWeight: 'bold',
        fontSize: 16
    },
    
    leftPane: {
        borderBottomRightRadius: 30,
        borderTopRightRadius: 30,
    },
    rightPane: {
        borderBottomLeftRadius: 30,
        borderTopLeftRadius: 30,
    },
    leftPaneHiden: {
    },
    leftPaneShown: {
        left: 0,
    },
    rightPaneHiden: {
    },
    rightPaneShown: {
        right: 0,
    },
    
    paneText: {
        fontSize: 14,
        marginTop: 10,
        marginHorizontal: 30
    },
    paneTextContainer: {},

    static: {
        backgroundColor: PALETTE.lightBlue,
        flex: 1,
        flexDirection: 'row',
        padding: 30
    },
    left: {
        flex: 1,
    },
    center: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    right: {
        flex: 1,
    },
    heading: {
        flex: 1,
        flexDirection: 'row'
    },
    headingSide: {},
    dateContainer: {
        flex: columnsRation.left,
        flexDirection: 'row',
        alignItems: 'center'
    },
    date: {
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 18
    },
    circle: {
        width: 12,
        height: 12,
        borderRadius: 100,
        backgroundColor: PALETTE.gayBlue,
        marginLeft: 8
    },
    hLine: {
        height: 2,
        backgroundColor: PALETTE.gayBlue,
        flex: 1
    },
    vLine: {
        width: 2,
        height: '100%',
        backgroundColor: PALETTE.gayBlue,
        position: 'absolute',
        right: 0
    },
    barContainer: {
        backgroundColor: PALETTE.yellow,
        height: 50,
        flex: columnsRation.right,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    headingText: {
        fontSize: 18,
        margin: 20,
        fontWeight: 'bold'
    },
    icon: {
        width: 60,
        height: 60,
        position: 'absolute',
        left: -20
    },
    actionIcon: {
        width: 20,
        height: 20
    },
    bodyContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    bodyMargin: {
        flex: columnsRation.left
    },
    bodyText: {
        flex: columnsRation.right
    },
    body: {
        fontSize: 18,
        margin: 16,
    },
    bodyExpanded: {
        margin: 12,
    }
});

export { ComparisonItemPaneComponent, Show }
